import TimeOutModal from '../components/TimeoutModal';
import React, { useEffect } from 'react'
import { intervalCallback } from '../helpers/sessionManagement';
import { clientCheckIn } from '../helpers/calloutHelpers';
import Page from '../pages/Page';
import { StoreState } from '../store/reducers/rootReducer';
import * as actions from '../store/actions';
import { connect } from 'react-redux';

type SessionParams = {
  sessionId: string,
  sessionTimeout: any, //  these may no longer be needed
  setSessionTimeout: Function, // these may no longer be needed
  sessionLength: any,
  lastActive: number | undefined,
  showTimeoutModal: Function,
  pollingRate: number,
}

const SessionManagement: React.FC<SessionParams> = (props) => {
    const {
      lastActive, showTimeoutModal, pollingRate, sessionLength, sessionId
    } = props

    useEffect(() => {
    if (!sessionId) {
      return;
    }
  
    if (lastActive) {
      const sessionTimeout = intervalCallback(sessionLength, lastActive, showTimeoutModal, pollingRate)
    
      return () => {
        clearInterval(sessionTimeout)
      }
    }

    // if (lastActive && sessionTimeout) {
    //   clearInterval(sessionTimeout)
    //   setSessionTimeout(intervalCallback(sessionLength, lastActive, showTimeoutModal, pollingRate))
    // }

    
  }, [lastActive, showTimeoutModal, pollingRate, sessionLength, sessionId])

  useEffect(() => {
    if (sessionId) {
      const timeBetweenCheckIns = 60 * 1000
      const interval = setInterval(() => {
        clientCheckIn(sessionId)
      }, timeBetweenCheckIns)

      return () => clearInterval(interval)
    }
  }, [sessionId])

  return <>
    <TimeOutModal/>
    <Page/>
  </>
}

const mapStateToProps = (state: StoreState) => {
  return {
    sessionId: state.session.sessionId,
    lastActive: state.session.lastActive,
    sessionTimeout: state.session.sessionTimeout,
    sessionLength: state.session.sessionLength,
    pollingRate: state.session.pollingRate,
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setSessionTimeout: (payload: any) => dispatch(actions.setSessionTimeout(payload)),
    showTimeoutModal: (showModal: boolean) => dispatch(actions.showTimeoutModal(showModal)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionManagement);
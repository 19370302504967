import React, { useState, useEffect } from 'react';
import { IonGrid, IonCol, IonRow, IonButton } from '@ionic/react';
import { getESignUrl, saveApplication, notifyDocusignNavigationOccurred } from '../../../helpers/calloutHelpers';
import { SessionAppBase } from '../../../helpers/sessionInterfaces';
import ReactGa from 'react-ga';
import { connect } from 'react-redux';
import IncompleteApplication from './IncompleteApplication';
import * as actions from '../../../store/actions';
import PensignDocument from './PensignDocument';

interface session extends SessionAppBase {
    menuSections: MenuSection[],
    setShowSpinner: (showSpinner: boolean) => void,
    hasIncompleteSteps: boolean,
}

const ReviewAndSign: React.FC<session> = ({ sessionId, setShowSpinner, formRef, handleNavigation, setErrorMessage, setShowErrorToast, hasIncompleteSteps }) => {
    const [docuSignUrl, setDocuSignUrl] = useState('');
    const [idCheckFailed, setIdCheckFailed] = useState(false);

    useEffect(() => {
        const resolveEsignState = async () => {
            if(hasIncompleteSteps){
                return
            }

            try{
                await saveApplication(sessionId)
            }catch(error){
                setErrorMessage('Unable to save application');
                setShowErrorToast(true);
                setShowSpinner(false);
                return
            }
            
            try{
                const urlResponse = await getESignUrl(sessionId)
                setDocuSignUrl(urlResponse);
                setShowSpinner(false);
            }catch(err){
                if ((err as Error).message?.includes('The ESignUrl')) {
                    /**
                     * set download to initiate
                        */
                    setIdCheckFailed(true);
                } else {
                    setIdCheckFailed(true);
                    setErrorMessage('Unable to retrieve ESign Information.');
                    setShowErrorToast(true);
                }
                setShowSpinner(false);
            }
        }
        setShowSpinner(true);
        resolveEsignState()
    }, [hasIncompleteSteps, sessionId, setShowSpinner, setErrorMessage, setShowErrorToast]) 

    const navigate = () => {
        // The ReviewAndSign component doesn't have submittable fields but it still needs a form tag.
        // Page.tsx uses the form and formRef to inform the component that navigation buttons have been pressed.
        handleNavigation({ status: 'Success' })
    }

    return (
        <IonGrid className='ion-padding'>
            <IncompleteApplication hasIncompleteSteps={hasIncompleteSteps}/>
            {idCheckFailed === false && hasIncompleteSteps === false &&
                <>
                    <IonRow className='well'>
                        <IonCol>
                            <span>
                                <b>E-Signature</b>
                                <br/>
                                <br/>
                                To begin the e-signature process, click the “Proceed to E-Signature” button below. You will be redirected to DocuSign, our electronic signature system partner. Before signing, you will be asked a series of questions to verify your identity.

                                <br/>
                                <br/>
                                When you have completed the signing process through DocuSign, keep your browser open as you will be redirected back to this site to download forms that must be pen signed.
                            </span>
                        </IonCol>
                    </IonRow>
                    {docuSignUrl !== '' &&
                        <IonRow>
                            <IonCol>
                                <a className="btn btn-primary" href={docuSignUrl}>
                                    <IonButton onClick={() => {
                                        notifyDocusignNavigationOccurred(sessionId)
                                        ReactGa.event({
                                            category: 'Docusign',
                                            action: 'Clicked Esign Url'
                                        })

                                    }}>Proceed to E-Signature</IonButton>
                                </a>
                            </IonCol>
                        </IonRow>
                    }
                </>
            }
            {idCheckFailed &&
                <>
                    <IonRow className='well'>
                        <IonCol>
                            <span>
                                <b>Whoops!  Equity Trust was unable to verify your identity.</b>
                                <br/>
                                <br/>

                                As a financial institution, Equity Trust is required to verify your identity.  During the application process, you were unable to answer some (or all) of the identity verification questions.

                                Because you were unable to answer the identity verification questions, you will need to print your application by clicking the download button below.
                                <br/>
                                <br/>
                                Be sure to physically sign your application where needed and return to Equity Trust with a copy of a valid government issued photo ID as well.  The application, ID, and IRA statement (if transferring funds from another custodian) can all be uploaded securely at
                                <br/>
                                <a href="https://www.midlandtrust.com/secure-upload/">https://www.midlandtrust.com/secure-upload/</a>
                                <br/>
                                <br/>
                                You may also fax your application to 239-466-5496 or mail it to:
                                <br/>PO Box 07520
                                <br/>  Fort Myers, FL 33919.
                                <br/><br/>
                                Thank you for your interest in opening an account with Equity Trust.  Once received, Equity Trust's new account team will review your application and a knowledgeable dedicated representative will reach out to you.  We look forward to a lasting relationship. Feel free to contact Equity Trust at 239-333-1032 if you need anything at all.
                                <br/>
                            </span>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        { idCheckFailed && <PensignDocument autoDownload={true}/> }
                    </IonRow>
                </>
            }
            <form ref={formRef} onSubmit={navigate}/>
        </IonGrid>

    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        menuSections: state.menu.menuSections
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAndSign);
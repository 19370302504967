export interface AppPage {
    header?: string;
    url: string;
    title: string;
    isValid: boolean; 
  }

  var appPagesMap: {[key :string]: AppPage} = {
    'Welcome':{
      header: 'Getting Started',
      title: 'Welcome',
      url: '/Welcome',
      isValid: false,
    },
    'OwnerInformation':{
      header: 'Open Account',
      title: 'Owner Information',
      url: '/OwnerInformation',
      isValid: false
    },
    'OwnerQuestionnaire':{
      header: 'Open Account',
      title: 'Owner Questionnaire',
      url: '/OwnerQuestionnaire',
      isValid: false
    },
    'PlanInformation':{
      header: 'Open Account',
      title: 'Plan Information',
      url: '/PlanInformation',
      isValid: false
    },
    'EmployerSponsorInformation': {
      header: 'Open Account', 
      title: 'Employer Sponsor Information',
      url: '/EmployerSponsorInformation', 
      isValid: false
    },
    'Beneficiaries':{
      header: 'Open Account',
      title: 'Beneficiaries',
      url: '/Beneficiaries',
      isValid: false
    },
    'InvestmentDetails':{
      header: 'Open Account',
      title: 'Investment Details',
      url: '/InvestmentDetails',
      isValid: false
    },
    'FeeArrangement':{
      header: 'Open Account',
      title: 'Fee Arrangement',
      url: '/FeeArrangement',
      isValid: false
    },
    'FundingOptions':{
      header: 'Open Account',
      title: 'Funding Options',
      url: '/FundingOptions',
      isValid: false
    },
    'AccountNotifications':{
      header: 'Open Account',
      title: 'Account Notifications',
      url: '/AccountNotifications',
      isValid: false
    },
    'PaymentInformation':{
      header: 'Finishing Up',
      title: 'Payment Information',
      url: '/PaymentInformation',
      isValid: false
    },
    'ReviewAndSign':{
      header: 'Finishing Up',
      title: 'Review and Sign',
      url: '/ReviewAndSign',
      isValid: false
    },
    'Review':{
      header: 'Finishing Up',
      title: 'Review',
      url: '/ReviewAndSign',
      isValid: false
    }
};

let appPages: AppPage[] = Object.values(appPagesMap);
  
  export interface PageValidationParameters{
    is_welcome_page_valid: boolean,
    is_disclosure_page_valid: boolean,
    is_owner_info_page_valid: boolean,
    is_owner_questionnaire_page_valid: boolean,
    is_plan_information_page_valid: boolean,
    is_employer_information_page_valid: boolean,
    is_beneficiaries_page_valid: boolean,
    is_investment_details_page_valid: boolean,
    is_fee_arrangement_page_valid: boolean,
    is_funding_options_page_valid: boolean,
    is_account_notifications_page_valid: boolean,
    is_payment_information_page_valid: boolean,
    is_review_and_sign_page_valid: boolean,
  }

  export interface MenuParameters extends PageValidationParameters{
    planInfo: boolean,
    // showPaymentForm: boolean,
    is401kApplication: boolean,
    isDYO401kApplication: boolean
  }
    
export interface MenuSection {
    header: string;
    pages: AppPage[]
  }

function generateAppPages(menuParams:MenuParameters, welcomePageFields: welcomePageParameters, viewMode?: ViewMode){
    let appSections:MenuSection[] = [];
    
    appSections.push(generateWelcomeSection(menuParams));

    appSections.push(generateOpenAccountSection(menuParams));

    appSections.push(generateFinishingUpSection(menuParams, welcomePageFields.payment_form, viewMode));

    return appSections;

  }

  function generateWelcomeSection(menuParams: MenuParameters){
    let welcomePages = appPages.filter(page => page.header === 'Getting Started');
    let updatedWelcomePages = welcomePages.map(page => {
      let newPage = {...page};
     if (page.url === '/Welcome') {
       newPage.isValid = menuParams.is_welcome_page_valid;
     }
     return newPage; 
    })
    return {
        header: 'Getting Started',
        pages: updatedWelcomePages
    }
  }

  function generateOpenAccountSection(menuParams : MenuParameters){
    let openAccountPages = [...appPages.filter(page => page.header === 'Open Account')];
    
    if(!menuParams.planInfo){
      openAccountPages = [...openAccountPages.filter(page => page.title !== 'Plan Information')];
    }

    if (!menuParams.is401kApplication) {
      openAccountPages=[...openAccountPages.filter(page => page.header === 'Open Account' && page.title !== 'Employer Sponsor Information')];
    }

    if (menuParams.is401kApplication) {
      openAccountPages=[...openAccountPages.filter(page => page.title !== 'Fee Arrangement')]
    }

    if (menuParams.isDYO401kApplication) {
      openAccountPages=[...openAccountPages.filter(page => page.title !== 'Funding Options')]
    }

    let updatedOpenAccountPages = openAccountPages.map(page => {
      let newPage = {...page};
      if (page.url === '/OwnerInformation') {
        newPage.isValid = menuParams.is_owner_info_page_valid; 
      }
      if (page.url === '/OwnerQuestionnaire') {
        newPage.isValid = menuParams.is_owner_questionnaire_page_valid; 
      }
      if (page.url === '/EmployerSponsorInformation') {
        newPage.isValid = menuParams.is_employer_information_page_valid;
      }
      if (page.url === '/PlanInformation') {
        newPage.isValid = menuParams.is_plan_information_page_valid; 
      }
      if (page.url === '/Beneficiaries') {
        newPage.isValid = menuParams.is_beneficiaries_page_valid;
      }
      if (page.url === '/InvestmentDetails'){
        newPage.isValid = menuParams.is_investment_details_page_valid; 
      }
      if (page.url === '/FeeArrangement') {
        newPage.isValid = menuParams.is_fee_arrangement_page_valid;
      }
      if (page.url === '/FundingOptions'){
        newPage.isValid = menuParams.is_funding_options_page_valid; 
      }
      if (page.url === '/AccountNotifications'){
        newPage.isValid = menuParams.is_account_notifications_page_valid; 
      }
      return newPage; 
    })

    return {
        header: 'Open Account',
        pages: updatedOpenAccountPages
    }
  }

  function generateFinishingUpSection(menuParams: MenuParameters, showPaymentForm: boolean, viewMode: ViewMode|undefined){
    let pages: AppPage[] = [];

    if(!showPaymentForm){
      const paymentForm = appPagesMap['PaymentInformation']
      paymentForm.isValid = menuParams.is_payment_information_page_valid || false;
      pages.push(appPagesMap['PaymentInformation'])
    }

    if(viewMode === 'reg'){
      const reviewAndSignPage = appPagesMap['ReviewAndSign']
      reviewAndSignPage.isValid = menuParams.is_review_and_sign_page_valid || false;
      pages.push(reviewAndSignPage)
    }

    if(viewMode === 'isp'){
      const reviewPage = appPagesMap['Review']
      reviewPage.isValid = menuParams.is_review_and_sign_page_valid || false;
      pages.push(reviewPage)
    }

    return {
      header: 'Finishing Up', 
      pages 
    }
  }

  export default generateAppPages
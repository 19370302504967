import React, { useState, useEffect, useContext } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { IonGrid, IonRow, IonCol, IonButton, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { getAllCustodians, uploadFile } from '../helpers/calloutHelpers';
import { connect } from 'react-redux';
import TransferForm from './TransferForm';
import * as actions from '../store/actions';
import { FundingOptionsContext } from './steps/FundingOptions'

type custodian = postgresSchema.custodian

interface Props {
    sessionId : string,
    welcomePage : welcomePageParameters,
    formData?: {transfers?: Array<SubForms.transfer>},
    submitRef: any, //TODO: USE CORRECT TYPE HERE, NOT ANY
    setShowSpinner: Function,
    setShowErrorToast: Function,
    setErrorMessage: Function,
    setShowSuccessToast: Function,
    setSuccessMessage: Function,
    updateFundingAmount: Function,
    requiredFunding: number,
    showRequiredFundingOption: boolean
}

const Transfers : React.FC<Props> = ({sessionId, formData, submitRef, welcomePage, setShowSpinner, setErrorMessage, setShowErrorToast, setSuccessMessage, setShowSuccessToast, updateFundingAmount, requiredFunding, showRequiredFundingOption}) => {
    const { transferTypes, setTransferTypes } = useContext(FundingOptionsContext)

    const blankTransfer: SubForms.transfer = {
        account_number:'',
        account_type:'',
        asset_name_1:'',
        asset_name_2:'',
        asset_name_3:'',
        cash_amount:null,
        all_available_cash_estimated_amount:null,
        contact_name: '',
        contact_phone_number:'',
        delivery_method:'',
        full_or_partial_cash_transfer:'',
        institution_id:'',
        institution_name:'',
        mailing_city:'',
        mailing_state:'',
        mailing_street:'',
        mailing_zip:'',
        transfer_type:''
    }
    
    const getDefaultValues = ()=>{
        if(formData?.transfers?.length){
            return {'transfers': formData.transfers}
        }else{
            return {}
        }
    }

    const methods = useForm<{'transfers': Array<SubForms.transfer>}>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });

    submitRef.current = methods

    const { fields, append, remove } = useFieldArray<SubForms.transfer>({
        control: methods.control,
        name: "transfers"
      });

    const [custodians, setCustodians] = useState<{custodiansObjects:Array<custodian>, custodianNames:Array<string>}>()
    
    useEffect(()=>{
        setShowSpinner(true);
        getAllCustodians(sessionId).then(response => response.json()).then(data => {
            let custodiansObjects = [...data.data];
            let custodianNames = custodiansObjects.map(custodian => custodian.name).sort();
            setCustodians({custodiansObjects, custodianNames})
            setShowSpinner(false);
        }).catch(err => {
            setShowSpinner(false);
            throw err; 
        })
    },[sessionId])

    useEffect(() => {
        calcFundingAmount()
    }, [fields.length])

    const calcFundingAmount = () => {
        let totalTransferFundingAmount = 0
        let transfers = methods.getValues().transfers    
        if (transfers && transfers?.length > 0) {
            for (const transfer of transfers) {
                if (transfer && transfer.all_available_cash_estimated_amount) {
                    totalTransferFundingAmount += +transfer.all_available_cash_estimated_amount  
                }
                else if (transfer && transfer.cash_amount) {
                    totalTransferFundingAmount += +transfer.cash_amount  
                }
            }
        }
        updateFundingAmount(totalTransferFundingAmount)
    }

    const addTransfer = () => {
        if(fields.length < 2){
            append(blankTransfer)               
        }
    }

    const handleFileUpload = (e : any) => {
        const base64 = 'base64,';
        let files = e.target.files;
        if (isUploadedDocsSizeBelowMaxSize(files)) {
            let file = files[0];
            let reader = new FileReader(); 
            reader.addEventListener('load', function() {
                let fileName = file.name;
                let fileContents = typeof reader.result === 'string' ? reader.result : '';
                let dataStart = fileContents?.indexOf(base64) + base64.length;
                let blob = fileContents?.substring(dataStart);
                setShowSpinner(true);
                uploadFile(sessionId, fileName, blob).then((res) => {
                    if (res.status === 200) {
                        setSuccessMessage('File Uploaded Successfully!');
                        setShowSuccessToast(true);

                    } else {
                        setErrorMessage('Error Uploading File');
                        setShowErrorToast(true);
                    }

                }).catch(err => {
                    setErrorMessage('Error Uploading File');
                    setShowErrorToast(true);
                }).finally(() => setShowSpinner(false))
            })
            reader.readAsDataURL(file);
        } else {
            setErrorMessage('File size is too large. Please limit your document to a total of 3 MB.');
            setShowErrorToast(true);
        }
    }

    const isUploadedDocsSizeBelowMaxSize = (uploadedFilesList:Array<any>) => {
        const maxAcceptedSize = 3145355;
        let fileSizeArr = []; 
        for (let i = 0; i < uploadedFilesList.length; i++) {
            fileSizeArr.push(uploadedFilesList[i].size);
        }
        let docsSizeInBytes = fileSizeArr.reduce((size1, size2) => size1 + size2);
        return docsSizeInBytes <= maxAcceptedSize;
    }

    const displayTransferForm = () => {
        return fields.map((value, index)=>{
            return (
                <TransferForm key={value.id} item={value} transferNumber={index} remove={()=>{remove(index); setTransferTypes(transferTypes.filter((transferType, i) => i !== index))}} handleFileUpload={handleFileUpload} applicationAccountType={welcomePage.account_type} custodians={custodians} calcFundingAmount={calcFundingAmount} requiredFunding={requiredFunding} showRequiredFundingOption={showRequiredFundingOption}/>
                )
            })
    }

    return (
        <IonGrid>
            {fields.length > 0 && (
                <IonRow className='well'>
                    <IonCol>
                    <b>IRA-to-IRA Transfers.</b> Please Read Carefully:
                    <ul data-type="circle">
                        <li>If your custodian requires a handwritten signature, a PDF version of our transfer form will populate upon the completion of your application to sign and return.</li>
                        <li>All transfers are manually processed. If you need time to liquidate your current IRA, or wish for us to delay the submission, please let us know.</li>
                        <li>Most transfers are completed in 4-7 business days. </li>
                    </ul>
                    </IonCol>
                </IonRow>
            )}
            <FormProvider {...methods}>
                <div>
                    {displayTransferForm()}            
                </div>
            </FormProvider>
            <IonRow>
                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                    {fields.length < 2 && (
                        <IonButton onClick={addTransfer}>
                            <IonIcon icon={addOutline} slot='start'></IonIcon>
                            Add Transfer from another IRA
                        </IonButton>
                    )}
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        welcomePage: state.welcomePage
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Transfers);
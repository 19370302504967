export namespace TOTAL_FEES {
    export const FEE_IRA = 50;
    export const FEE_401K = 600;
    export const FEE_401K_DYO = 750;
}

export const MINIMUM_CASH_BALANCE = 250

export function getTotalFeeByAccountType(accountType?: string | null) {
    let totalFee = TOTAL_FEES.FEE_IRA;
    
    if (accountType === 'Individual 401k - DYO') {
        totalFee = TOTAL_FEES.FEE_401K_DYO;
    }
    else if (accountType?.includes('401k')) {
        totalFee = TOTAL_FEES.FEE_401K;
    }
    
    return totalFee;
}

export const convertStringDateToDate = (dateString?: string|null) => {
    if (dateString)
    {
        return new Date(dateString.replace(/-/g, '\/'))
    }
    return null
}

export const EMAIL_REGEX = '^\\w+([\\.\\-\\+]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,15})+$'
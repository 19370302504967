import React, { useState, useEffect, useContext } from 'react';
import { useFormContext, Controller, useWatch, ArrayField } from 'react-hook-form';
import { IonItem, IonRow, IonCol, IonButton, IonContent, IonModal, IonItemDivider, IonText, IonLabel, IonInput, IonSelectOption, IonSelect, IonRadioGroup, IonRadio, IonList, isPlatform } from '@ionic/react';
import TextField from "@material-ui/core/TextField";
import { states, isMobile, errorsExistForFieldOnForm } from '../helpers/utils';
import { MINIMUM_CASH_BALANCE } from '../helpers/sharedUtils'
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputMask from 'react-input-mask';
import './TransferForm.css';
import { FundingOptionsContext } from './steps/FundingOptions'

type custodian = postgresSchema.custodian 

type TransferFormProps = {item:Partial<ArrayField<SubForms.transfer, "id">>, applicationAccountType:string, transferNumber:number, custodians?: {custodiansObjects:Array<custodian>, custodianNames:Array<string>}, remove:Function, handleFileUpload(event:any):void, calcFundingAmount:Function, requiredFunding:number, showRequiredFundingOption:boolean}

const TransferForm : React.FC<TransferFormProps> = ({item, applicationAccountType, transferNumber, custodians, remove, handleFileUpload, calcFundingAmount, requiredFunding, showRequiredFundingOption}) => {
    const { errors, control, setValue, register } = useFormContext<{ transfers: Array<SubForms.transfer> }>()
    const [showLiquidationReminderBox, setShowLiquidationReminderBox] = useState(false);
    const [allowInKindTransfers, setAllowInKindTransfers] = useState<boolean>(false);
    const { transferTypes, setTransferTypes, totalFundingBelowRequiredFunding } = useContext(FundingOptionsContext);
    
    const transfer_type = useWatch({
        name: `transfers[${transferNumber}].transfer_type`,
        control: control
    })
    const full_or_partial_cash_transfer = useWatch({
        name: `transfers[${transferNumber}].full_or_partial_cash_transfer`,
        control: control
    })
    const institution_name: any = useWatch({
        name: `transfers[${transferNumber}].institution_name`,
        control: control, 
        defaultValue: ''
    })
    const allAvailableCashEstimatedAmount: number|undefined = useWatch({
        name: `transfers[${transferNumber}].all_available_cash_estimated_amount`,
        control
    })
    const cashAmount: number|undefined = useWatch({
        name: `transfers[${transferNumber}].cash_amount`,
        control
    })

    const changeTransferTypes = (isInKindTransfer: boolean) => {
        let transferTypesChanged = transferTypes.filter((transferType, index) => index !== transferNumber);
        if (transferNumber === 0) {
            transferTypesChanged.unshift({isInKindTransfer: isInKindTransfer});
        } else if (transferNumber === 1) {
            transferTypesChanged.push({isInKindTransfer: isInKindTransfer});
        };
        setTransferTypes(transferTypesChanged);
    }

    useEffect(() => {
        if (transfer_type === 'In-Kind Transfer'){
            changeTransferTypes(true);
        } else {
            changeTransferTypes(false);
        };
    }, [transfer_type])

    useEffect(() => {
        if(transfer_type === 'In-Kind Transfer' || full_or_partial_cash_transfer === 'All Available Cash') {
            setValue(`transfers[${transferNumber}].cash_amount`, 0)
        }
        if(transfer_type === 'In-Kind Transfer' || full_or_partial_cash_transfer === 'Partial Cash Transfer') {
            setValue(`transfers[${transferNumber}].all_available_cash_estimated_amount`, 0)
        }
        if(full_or_partial_cash_transfer === 'Required Funding Amount') {
            setValue(`transfers[${transferNumber}].all_available_cash_estimated_amount`, 0)
            setValue(`transfers[${transferNumber}].cash_amount`, requiredFunding)
            setValue(`transfers[${transferNumber}].full_or_partial_cash_transfer`, 'Partial Cash Transfer')
        }
    }, [transfer_type, full_or_partial_cash_transfer])

    useEffect(() => {
        if (institution_name?.includes('Fidelity')) {
            setShowLiquidationReminderBox(true);
        }

        if (transfer_type && transfer_type !== 'In-Kind Transfer') {
            setValue(`transfers[${transferNumber}].transfer_type`, 'Cash Transfer')
        }
        else if (transfer_type === 'In-Kind Transfer') {
            setAllowInKindTransfers(true)
        }

        if (custodians) {
            let matchedOptions: custodian[] = [];
            let custodianOptionsArr  : custodian[]= [...custodians.custodiansObjects];
            matchedOptions = custodianOptionsArr.filter(custodian => custodian.name === institution_name);

            if (matchedOptions.length > 0) {
                setAllowInKindTransfers(matchedOptions[0]?.allow_in_kind_transfers)
            }
            else {
                setValue(`transfers[${transferNumber}].transfer_type`, 'Cash Transfer')
                setValue(`transfers[${transferNumber}].mailing_street`, '')
                setValue(`transfers[${transferNumber}].mailing_city`, '')
                setValue(`transfers[${transferNumber}].mailing_state`, '')
                setValue(`transfers[${transferNumber}].mailing_zip`, '')
                setValue(`transfers[${transferNumber}].contact_phone_number`, '')
            }
        }
    }, [custodians, institution_name])

    useEffect(()=>{
        calcFundingAmount();
    },[allAvailableCashEstimatedAmount, cashAmount])

    const showError = (fieldName: string) => {
        let transferErr = (errors === undefined || errors.transfers === undefined || errors.transfers[transferNumber] === undefined) ? {} : errors.transfers[transferNumber]
        let errorsArr = (Object.keys(transferErr as any));
        let className = '';

        if (errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };

    const showAmountError = (fieldName: string) => {
        if (totalFundingBelowRequiredFunding) {
            return 'danger'
        }

        return showError(fieldName)
    };

    const handleCustodianSelect = (custoidanName:string, method: "create-option"|"select-option"|"remove-option"|"blur"|"clear") => {
        let matchedOptions: custodian[] = [];
        
        if (method === 'select-option' && custodians){
            let custodianOptionsArr  : custodian[]= [...custodians.custodiansObjects];
            matchedOptions = custodianOptionsArr.filter(custodian => custodian.name === custoidanName);
        }

        if (matchedOptions.length > 0) {
            let matchedOption = matchedOptions[0];
            
            setValue(`transfers[${transferNumber}].institution_id`, matchedOption.salesforce_id ? matchedOption.salesforce_id : '')
            setAllowInKindTransfers(matchedOptions[0]?.allow_in_kind_transfers)
            setValue(`transfers[${transferNumber}].transfer_type`, 'Cash Transfer')
        }
        else {
            setValue(`transfers[${transferNumber}].institution_id`, '')
            setAllowInKindTransfers(false)
        }
    }

return (
    <div>
        <Controller
            name={`transfers[${transferNumber}].institution_id`} defaultValue={item.institution_id}
            control={control}
            render={props=><input type='hidden' {...props}/>} />
        <IonItemDivider>
            <p>
            <strong>
                <IonText color='primary'>
                    Transfer {transferNumber + 1}
                </IonText>
            </strong>
            </p>
            <IonButton slot='end' onClick={e=> {
                remove()
            }}>Remove Transfer </IonButton>
        </IonItemDivider>
        <IonRow>
            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel>
                    Institution Name *
                </IonLabel>
                <IonItem className={showError(`institution_name`)}>
                    <Controller name={`transfers[${transferNumber}].institution_name`} defaultValue={item.institution_name} control={control} render={ ({value, name, onChange})=>
                        <Autocomplete componentName={name} id={name} 
                            onSubmit={(event)=>{
                            }}
                            onChange={(event, newValue, reason)=>{
                                onChange(newValue)
                                handleCustodianSelect(newValue, reason)
                        }} value={value}
                        freeSolo={true} autoSelect={true} options={custodians?.custodianNames||[]} getOptionLabel={option => option} renderOption={option =>(
                        <span>{option}</span>
                        )} renderInput={params => {
                            let newInputProps = {...params.InputProps, disableUnderline: true};
                            let newParams = {...params, InputProps: newInputProps};
                            return (
                            <TextField placeholder='Institution Name' {...newParams}/>
                        )}} />} rules={{required:true, maxLength: 100}}
                        />
                </IonItem>
            </IonCol>
            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel>
                    Account Number *
                </IonLabel>
                <IonItem className={showError(`account_number`)}>
                    <Controller name={`transfers[${transferNumber}].account_number`} defaultValue={item.account_number} control={control} render={ ({value, name, onBlur, onChange}) =>
                        <IonInput name={name} value={value} placeholder='Account Number' onIonBlur={onBlur} onIonChange={onChange} maxlength={100}/>
                    }
                    rules={{ required: true }}
                    />
                </IonItem>
            </IonCol>
        </IonRow>
        {!institution_name || !custodians?.custodianNames.includes(institution_name) && 
            <>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>
                            Contact Name
                        </IonLabel>
                        <IonItem className={showError(`contact_name`)}>
                            <Controller name={`transfers[${transferNumber}].contact_name`} defaultValue={item.contact_name} control={control} render={ ({value, name, onBlur, onChange}) =>
                                <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} placeholder='Name' maxlength={100}/>
                            }/>
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>
                        Contact Phone Number
                        </IonLabel>
                        <IonItem className={showError(`contact_phone_number`)}>
                            <Controller name={`transfers[${transferNumber}].contact_phone_number`} defaultValue={item.contact_phone_number} control={control} as={
                                <InputMask className='input-mask' type='tel' mask="(999)999-9999" placeholder='Phone'/>
                            } rules={{validate:(val)=> (val === undefined || val === null || val === '' || val?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/) !== null)}} />
                        </IonItem>
                        {errorsExistForFieldOnForm(errors?.transfers, transferNumber, 'contact_phone_number') && <IonText color='danger'>The phone number entered does not appear valid</IonText>}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Street</IonLabel>
                        <IonItem className={showError(`mailing_street`)}>
                            <Controller name={`transfers[${transferNumber}].mailing_street`} control={control} defaultValue={item.mailing_street} render={ ({value, name, onBlur, onChange}) =>
                                <IonInput name={name} value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='Street' maxlength={255}/>
                            }/>
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel> City</IonLabel>
                        <IonItem className={showError(`mailing_city`)}>
                            <Controller name={`transfers[${transferNumber}].mailing_city`} control={control} defaultValue={item.mailing_city} render={ ({value, name, onBlur, onChange}) =>
                                <IonInput name={name} value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='City' maxlength={100}/>
                            }/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel> State </IonLabel>
                        <IonItem className={showError(`mailing_state`)}>
                            {isMobile(isPlatform) ? 
                                <Controller name={`transfers[${transferNumber}].mailing_state`} control={control} defaultValue={item.mailing_state} render={ ({value, name, onBlur, onChange}) =>
                                    <IonSelect name={name} interface='action-sheet' value={value} onIonBlur={onBlur} onIonChange={onChange} placeholder='State' interfaceOptions={{cssClass: 'long-select', animated: false, mode: 'ios'}}>
                                    {states.map((state, index) => (<IonSelectOption key={index} value={state}>{state}</IonSelectOption>))}
                                    </IonSelect>
                                }/> : 
                                <Controller name={`transfers[${transferNumber}].mailing_state`} control={control} defaultValue={item.mailing_state} render={({value, name})=>
                                    <Autocomplete  id={name} 
                                        onChange={(event,value)=>{
                                        setValue(name, value)
                                    }} value={value}  autoSelect={true} freeSolo={true} options={states} placeholder='State' getOptionLabel={option => option} renderOption={option =>(
                                        <span> {option}</span>
                                        )} renderInput={params => {
                                            let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'State'};
                                            let innerInputProps = {...params.inputProps, maxLength: "2"}
                                            let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps};
                                            return (
                                            <TextField type="text" {...newParams}/>
                                        )}} />} rules={{
                                            validate: (val) => (val === undefined || val === null || val === '' || (states.findIndex((state) => state === val?.toUpperCase()) !== -1))
                                        }} />
                            }                            
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>
                            Zip
                        </IonLabel>
                        <IonItem className={showError(`mailing_zip`)}>
                            <Controller name={`transfers[${transferNumber}].mailing_zip`} defaultValue={item.mailing_zip} control={control} as={
                                <InputMask maskChar='' mask='99999' placeholder='Zip' className='input-mask'/>
                            } rules={{validate:(val) => (val === null || val === '' || val?.match(/^[0-9]{5}(?:-[0-9]{4})?/) !== null)}}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </>
        }
        <IonRow>
            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel>Account Type *</IonLabel>
                <IonItem className={showError(`account_type`)}>
                    <Controller name={`transfers[${transferNumber}].account_type`} defaultValue={item.account_type} control={control} render={ ({value, name, onBlur, onChange}) =>
                        <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonBlur={onBlur} onIonChange={onChange}>
                            {applicationAccountType.includes('Roth') ? (
                                <IonSelectOption value='Roth IRA'>Roth IRA</IonSelectOption>
                            ) : (
                                <>
                                    <IonSelectOption value='Traditional IRA'>Traditional IRA</IonSelectOption>
                                    <IonSelectOption value='SEP IRA'>SEP IRA</IonSelectOption>
                                    <IonSelectOption value='Simple IRA'> Simple IRA</IonSelectOption>
                                </>
                            )}
                        </IonSelect>
                    } rules={{required: true}}/>
                </IonItem>
            </IonCol>
            { !allowInKindTransfers && <Controller control={control} name={`transfers[${transferNumber}].transfer_type`} render={props=><input type='hidden' {...props}/>}/>}
            { allowInKindTransfers && <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel>
                    Transfer Type *
                </IonLabel>
                <IonItem className={showError(`transfer_type`)}>
                    <Controller name={`transfers[${transferNumber}].transfer_type`} defaultValue={item.transfer_type} control={control} render={({ value, name, onBlur, onChange }) =>
                        <IonSelect name={name} interface='action-sheet' interfaceOptions={{ animated: false, mode: 'ios' }} value={value} onIonBlur={onBlur} onIonChange={onChange}>
                            <IonSelectOption value='Cash Transfer'>Cash (Most Common)</IonSelectOption>
                            <IonSelectOption value='In-Kind Transfer'> In-Kind (Private Holding)</IonSelectOption>
                        </IonSelect>
                    } rules={{ required: true }} />
                </IonItem>
            </IonCol> }
            { (allowInKindTransfers && transfer_type !== 'Cash Transfer') && <IonCol size="7" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel>
                    Complete or Partial Transfer *
                </IonLabel>
                <IonItem className={showError(`full_or_partial_cash_transfer`)}>
                    <Controller name={`transfers[${transferNumber}].full_or_partial_cash_transfer`} defaultValue={item.full_or_partial_cash_transfer} control={control} render={ ({value, name, onBlur, onChange})=> 
                        <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonBlur={onBlur} onIonChange={onChange}>
                            <IonSelectOption value='All Available Cash'>Complete</IonSelectOption>
                            <IonSelectOption value='Partial Cash Transfer'>Partial</IonSelectOption>
                        </IonSelect>
                    } rules={{required: true}}/>
                </IonItem>
            </IonCol> }
            {(allowInKindTransfers && transfer_type !== 'Cash Transfer') && <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel>
                    Holding Name *
                </IonLabel>
                <IonItem className={showError(`asset_name_1`)}>
                    <Controller name={`transfers[${transferNumber}].asset_name_1`} defaultValue={item.asset_name_1} control={control} disabled={!allowInKindTransfers || transfer_type === 'Cash Transfer'} render={({value, name, onBlur, onChange}) =>
                        <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={255}/>
                    } rules={{required: transfer_type === 'In-Kind Transfer'}}/>
                </IonItem>
            </IonCol>}
        </IonRow>
        <IonRow>
            {(allowInKindTransfers && transfer_type !== 'Cash Transfer') && <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12" >
                <IonLabel>
                    Holding 2 Name (if applicable)
                </IonLabel>
                <IonItem className={showError(`asset_name_2`)}>
                    <Controller name={`transfers[${transferNumber}].asset_name_2`} defaultValue={item.asset_name_2} control={control} render={({value, name, onBlur, onChange})=>
                        <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={255}/>
                    }/>
                </IonItem>
            </IonCol> }
            {(allowInKindTransfers && transfer_type !== 'Cash Transfer') && <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel>
                    Holding 3 Name (if applicable)
                </IonLabel>
                <IonItem className={showError(`asset_name_3`)}>
                    <Controller name={`transfers[${transferNumber}].asset_name_3`} defaultValue={item.asset_name_3} control={control} render={ ({value, name, onBlur, onChange})=>
                        <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={255}/>
                }/>
                </IonItem>
            </IonCol> }
        </IonRow>
        { transfer_type !== 'In-Kind Transfer' && <IonRow>
            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel> Transfer Amount * </IonLabel>
                <IonItem className={showError(`full_or_partial_cash_transfer`)}>
                    <Controller name={`transfers[${transferNumber}].full_or_partial_cash_transfer`} defaultValue={item.full_or_partial_cash_transfer} control={control} render={ ({value, name, onBlur, onChange}) =>
                        <IonSelect name={name}  value={value} onIonBlur={onBlur} onIonChange={onChange} interface="action-sheet" interfaceOptions={{animated: false, mode: 'ios'}} disabled={transfer_type === 'In-Kind Transfer'}>
                            <IonSelectOption value='All Available Cash'>All Available Cash</IonSelectOption>
                            {showRequiredFundingOption && 
                                <IonSelectOption value='Required Funding Amount'>Required Funding Amount</IonSelectOption>
                            }
                            <IonSelectOption value='Partial Cash Transfer'>Partial Cash Transfer</IonSelectOption>
                        </IonSelect>
                    } rules={{required: true}}/>
                </IonItem>
            </IonCol>
            { !(full_or_partial_cash_transfer !== 'Partial Cash Transfer' && full_or_partial_cash_transfer !== 'Required Funding Amount') && <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel><strong>Cash Amount *</strong></IonLabel>
                <IonItem className={showAmountError(`cash_amount`)}>
                    <Controller name={`transfers[${transferNumber}].cash_amount`} defaultValue={item.cash_amount} control={control} render={({ value, name, onBlur, onChange }) =>
                        <IonInput name={name} type='number' value={value} onIonBlur={onBlur} onIonChange={onChange}/>
                    } rules={{
                        required: true,
                        pattern: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
                    }} />
                </IonItem>
            </IonCol> }
            { !(full_or_partial_cash_transfer !== 'All Available Cash') && <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel><strong>Estimated Cash Amount *</strong></IonLabel>
                <IonItem className={showAmountError(`all_available_cash_estimated_amount`)}>
                    <Controller name={`transfers[${transferNumber}].all_available_cash_estimated_amount`} defaultValue={item.all_available_cash_estimated_amount} control={control} render={({ value, name, onBlur, onChange }) =>
                        <IonInput name={name} type='number' value={value} onIonBlur={onBlur} onIonChange={(event) => { onChange(event); }} disabled={full_or_partial_cash_transfer !== 'All Available Cash'}/>
                    } rules={{
                        required: true,
                        pattern: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
                    }} />
                </IonItem>
            </IonCol> }
        </IonRow> }
        <IonRow>
            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel>
                To expedite this transfer request, Equity Trust will send your signed request via fax or scan if acceptable by your current IRA custodian. If your current IRA custodian requires original documents, how do you want this transfer to be delivered?
                </IonLabel>
                <div className="ion-text-wrap">
                    <IonList className={showError(`delivery_method`)}>
                        <Controller name={`transfers[${transferNumber}].delivery_method`} defaultValue={item.delivery_method} control={control} render={ ({value, name, onBlur, onChange})=>
                            <IonRadioGroup name={name} value={value} onIonChange={onChange} onBlur={onBlur}>
                                <IonItem>
                                    <IonLabel>Mail (No charge)</IonLabel>
                                    <IonRadio tabIndex={0} mode="md" value='Certified Mail'>
                                    </IonRadio>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Overnight ($30 Fee Applies)</IonLabel>
                                    <IonRadio tabIndex={0} mode="md" value='FedEx Overnight'></IonRadio>
                                </IonItem>
                            </IonRadioGroup>
                        } rules={{required: true}} />
                    </IonList>
                </div>
            </IonCol>
            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                <IonLabel>
                Upload Current Institution's Statement
                </IonLabel>
                <IonItem >
                    <div tabIndex={0}>
                        <input type='file' name={`file`} onChange={handleFileUpload} accept='.pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, xlsx'/>
                    </div>
                </IonItem>
            </IonCol>
        </IonRow>
        {transfer_type === 'In-Kind Transfer' && (
            <IonRow>
                <IonCol>
                    <b>
                        <IonText color='primary'>
                            Equity Trust is unable to transfer in publically traded securities.
                        </IonText>
                    </b>
                </IonCol>
            </IonRow>
        )}
        <IonModal cssClass={'comments-modal'} isOpen={showLiquidationReminderBox} backdropDismiss={false} onDidDismiss={() => setShowLiquidationReminderBox(false)} mode="ios">
            <IonContent>
                <IonRow>
                    <IonCol class="pr-2 pl-2" size="12">
                        <IonRow>
                            <IonCol class="p-1">
                                <p><b>LIQUIDATION REMINDER:</b> Transfers will be rejected by Fidelity if funds are not in a liquid position. Please contact Fidelity immediately to see if adequate funds are available to complete the transaction. Please also keep in mind that:</p>
                                <ul>
                                    <li>You will need to ensure that you have sufficient funds available to cover transaction fees at both Fidelity and Equity Trust Company, including Equity Trust's minimum cash balance requirement of ${MINIMUM_CASH_BALANCE};</li>
                                    <li>You may need to lift any account restrictions on your Fidelity account to ensure that the transfer can be completed; and</li>
                                    <li>If you are intending to make a full/complete transfer, your entire account must be liquidated and in a cash position.</li>
                                </ul>
                                <p>These things must be completed prior to Fidelity receiving your transfer form.</p>
                                <IonButton onClick={() => setShowLiquidationReminderBox(false)} color="secondary" class="ion-float-right" size="large">Continue</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonModal>
    </div>
)}

export default TransferForm
import React from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { IonText, IonGrid, IonRow, IonCol, IonItemDivider, IonInput, IonSelect, IonSelectOption, IonLabel, IonItem, isPlatform } from '@ionic/react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputMask from 'react-input-mask'; 
import {UpdateSessionActivityApp} from '../../helpers/sessionInterfaces';
import { states, isMobile } from '../../helpers/utils';
import { savePlanInfoPage } from '../../helpers/calloutHelpers';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

interface PlanInfoPage extends UpdateSessionActivityApp {
    formData? : {
        data? : Forms.planInformationForm
    },
    setShowSpinner: Function,
}

const PlanInformation: React.FC<PlanInfoPage> = ({sessionId, updateMenuItem, formRef, setShowSpinner, updateLastActivity, formData, handleNavigation, setErrorMessage, setShowErrorToast}) => {
    let defaultValues: Forms.planInformationForm = {
        business_name: '',
        business_phone: '',
        trustee_name: '', 
        business_street: '',
        business_city: '',
        business_state: '',
        business_zip: '',
        entity_type: '',
        sep_defaults: '',
        ...formData?.data
    }
    
    const {control, handleSubmit, errors, formState, setValue} = useForm({
        mode: "onChange",
        defaultValues
    }); 

    const { isDirty, submitCount } = formState; 

    const validateFields = (data: any) => {
        setShowSpinner(true); 
        savePlanInfoPage(sessionId, data, updateLastActivity)?.then(() => {
            updateMenuItem('is_plan_information_page_valid', true, sessionId);
        }).finally(() => {
            setShowSpinner(false);
            handleNavigation({status: 'Success'});
        })
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = '';
        if ((submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    }

    const sep_defaults = useWatch({
        name: 'sep_defaults', 
        control
    })

    const onInvalidForm = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        }
        else {
            handleNavigation({status: 'Error'});
        }
    }

    return (
            <form ref={formRef} onSubmit={handleSubmit(validateFields, onInvalidForm)} className='ion-padding'>
                <IonGrid>
                    <IonRow className='well'>
                        <IonCol>
                        In order to establish a SEP IRA, please provide information for your business or employer that is sponsoring the plan. If you are self-employed, this will be your business information. Typically, the business owner is the SEP Plan Trustee.
                        <br/>
                        <br/>
                        Equity Trust will serve as the IRA Trustee of your SEP Plan, meaning we will provide administration, tax reporting, statements, etc.
                        </IonCol>
                    </IonRow>
                    <IonItemDivider>
                        <strong>
                            <IonText color='primary'>
                                Business Information
                            </IonText>
                        </strong>
                    </IonItemDivider>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Business Name *
                            </IonLabel>
                            <IonItem className={showError('business_name')}>
                                <Controller name='business_name' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} placeholder='Name'/>
                                } rules={{required: true, maxLength: 100}}/>
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Business Phone *
                            </IonLabel>
                            <IonItem className={showError('business_phone')}>
                                <Controller name='business_phone' control={control} render={({name, value, onChange}) =>
                                    <InputMask value={value} onChange={onChange} className='input-mask' type='tel' mask="(999)999-9999" placeholder='Phone' />
                                } rules={{
                                    required: true, 
                                    pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                    validate: (val) => (val?.charAt(1) !== '0' && val?.charAt(1) !== '1')
                                }}/>
                            </IonItem>
                            {errors.business_phone && <IonText color='danger'>The phone number entered does not appear valid</IonText>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Trustee Name *
                            </IonLabel>
                            <IonItem className={showError('trustee_name')}>
                                <Controller name='trustee_name' control={control} render={ ({name, value, onBlur, onChange})=>
                                    <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} placeholder='Name'/>
                                } rules={{required: true, maxLength: 100}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Business Street *
                            </IonLabel>
                            <IonItem className={showError('business_street')}>
                                <Controller name='business_street' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonInput name={name} placeholder='Street' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                } rules={{required:true}}/>
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Business City *
                            </IonLabel>
                            <IonItem className={showError('business_city')}>
                                <Controller name='business_city' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonInput name={name} placeholder='City' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                } rules={{required: true, maxLength: 50}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Business State *
                            </IonLabel>
                            <IonItem className={showError('business_state')}>
                                {isMobile(isPlatform) ? 
                                <Controller name='business_state' control={control} render={ ({name, value, onBlur, onChange})=>
                                  <IonSelect name={name} placeholder='State' interface='action-sheet' interfaceOptions={{cssClass: 'long-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                      {states.map((state, index) => <IonSelectOption value={state} key={index}>{state}</IonSelectOption>)}
                                  </IonSelect> 
                                } rules={{required: true}}/> :
                                <Controller name='business_state' control={control} render={({value, name}) =>
                                    <Autocomplete value={value} id={name} 
                                        onChange={(event, value) => {
                                        setValue('business_state', value);
                                    }} freeSolo={true} autoSelect={true} options={states}  getOptionLabel={option => option} renderOption={option =>(
                                    <span> {option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'State'};
                                        let innerInputProps = {...params.inputProps, maxLength: "2"};
                                        let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps};
                                        return (
                                        <TextField type='text' {...newParams}/>
                                    )}}/>
                                } rules={{
                                        required: true, 
                                        validate: (val) => 
                                        (states.findIndex((state) => state === val.toUpperCase()) !== -1)   
                                    }} />
                                }
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Business Zip *
                            </IonLabel>
                            <IonItem className={showError('business_zip')}>
                                <Controller name='business_zip' control={control} render={({name, value, onChange}) =>
                                   <InputMask value={value} maskChar='' mask='99999' placeholder='Zip' className='input-mask' onChange={onChange}/>
                                } rules={{required: true,
                                    pattern:  /^[0-9]{5}(?:-[0-9]{4})?/}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Entity Type *
                            </IonLabel>
                            <IonItem className={showError('entity_type')}>
                                <Controller name='entity_type' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonSelect name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} interface='action-sheet' interfaceOptions={{cssClass: 'long-select', animated: false, mode: 'ios'}}>
                                        <IonSelectOption value="C Corp">
                                            C Corp
                                        </IonSelectOption>
                                        <IonSelectOption value="S Corp">
                                            S Corp
                                        </IonSelectOption>
                                        <IonSelectOption value="LLC/LP">
                                            LLC/LP
                                        </IonSelectOption>
                                        <IonSelectOption value='Sole Proprietor'>
                                            Sole Proprietor
                                        </IonSelectOption>
                                    </IonSelect>
                                } rules={{required: true}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <br/>
                    <IonItemDivider>
                        <strong>
                            <IonText color='primary'>
                                Plan Defaults
                            </IonText>
                        </strong>
                    </IonItemDivider>
                    <IonRow className='well'>
                        <IonCol>
                        To simplify the account opening process, we are providing the most common selections for the SEP Plan Adoption Agreement. You will also have the option of declining these defaults and selecting your own options. The SEP Plan defaults are: <br/>
                            <ul className='circle-list'>
                                <li>Plan Year shall mean the Calendar Year</li>
                                <li>All Employees of the Employer shall be eligible to participate under the Plan with no exceptions.</li>
                                <li>Each eligible Employee will be eligible to become a Participant after having attained age 21 (not to exceed age 21) and having worked for the Employer 3 (not to exceed 3) years out of the immediately preceding 5 Plan Years</li>
                                <li>The Employer shall contribute on behalf of each Participant for each Plan year a Discretionary Employer Contribution. In each Plan Year, the Employer agrees to provide contributions on behalf of each Participant in the same proportion as such Participants Compensation bears to all Participants Compensation for such year.</li>
                                <li>The IRA accounts of each Participant shall be maintained and established with Equity Trust, the IRA Trustee/Custodian</li>
                            </ul>   
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Do you accept these SEP Plan Defaults? *
                            </IonLabel>
                            <IonItem className={showError('sep_defaults')}>
                                <Controller name='sep_defaults' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonSelect name={name}  interface='action-sheet' value={value} onIonBlur={onBlur} onIonChange={onChange} interfaceOptions={{animated: false, mode: 'ios'}}>
                                        <IonSelectOption value='Yes'>
                                            Yes
                                        </IonSelectOption>
                                        <IonSelectOption value='No'>
                                            No
                                        </IonSelectOption>
                                    </IonSelect>
                                } rules={{required: true}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    {sep_defaults !== undefined && sep_defaults !== 'Yes' && (
                        <IonRow className='well'>
                            <IonCol>
                            After you finish this application, someone from Equity Trust will call you to walk through setting up your SEP IRA
                            </IonCol>
                        </IonRow>
                    )}
                </IonGrid>
            </form>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        updateLastActivity: (lastActive: number) => dispatch(actions.updateLastActivity(lastActive)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PlanInformation); 
import React, { useEffect } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { IonGrid, IonRow, IonCol, IonButton, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import RolloverForm from './RolloverForm'
import { connect } from 'react-redux';
import * as actions from '../store/actions';

interface Props {
    formData?: {rollovers?: Array<SubForms.rollover>},
    submitRef: any, //TODO: USE CORRECT TYPE HERE, NOT ANY
    account_type: string,
    updateFundingAmount: Function
}

const Rollovers: React.FC<Props> = ({ formData, submitRef, account_type, updateFundingAmount }) => {
    
    const blankRollover: SubForms.rollover = {
        account_number:'',
        account_type: '',
        cash_amount: null,
        institution_name: '',
        mailing_city: '',
        mailing_state: '',
        mailing_street: '',
        mailing_zip: '',
        name: '',
        phone: '',
        rollover_type: ''
    }    

    const getDefaultValues = ()=>{
        if(formData?.rollovers?.length){
            return {'rollovers': formData.rollovers}
        }else{
            return {}
        }
    }

    const methods = useForm<{'rollovers':Array<SubForms.rollover>}>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });

    submitRef.current = methods;

    const { fields, append, remove } = useFieldArray<SubForms.rollover>({
        control: methods.control,
        name: "rollovers"
    });

    useEffect(()=>{
        calcFundingAmount();
    },[fields.length])

    const calcFundingAmount = () => {
        let totalRolloverFundingAmount = 0
        let rollovers = methods.getValues().rollovers    
        if (rollovers && rollovers?.length > 0) {
            for (const rollover of rollovers) {
                if (rollover && rollover.cash_amount) {
                    totalRolloverFundingAmount += +rollover.cash_amount  
                }
            }
        }
        updateFundingAmount(totalRolloverFundingAmount)
    }

    const addRollover = () => {
        append(blankRollover)
    }

    const displayRolloverForm = () => {
        return fields.map((value, index)=>{
            return (
                <RolloverForm key={value.id} item={value} rolloverNumber={index} remove={()=>{remove(index)}} applicationAccountType={account_type} calcFundingAmount={calcFundingAmount}/>
                )
        })
    }

    return (
        <IonGrid>
            {fields.length > 0 && (
                <IonRow className='well'>
                    <IonCol>
                        <b>
                        Please tell us about your pending employer plan rollover(s). 
                        </b> &nbsp;
                        The plan administrator of your employer plan account will not allow Equity Trust to initiate the rollover. You, as the plan participant, will be required to contact the plan administrator of your current employer plan to request this rollover. Typically, it’s as easy as calling the phone number on your most recent statement. At the end of this application, we will provide you with "Rollover Instructions" for you to provide to your plan administrator to help expedite the request.

                        As an option you are welcome to provide Equity Trust with your account information to deduct your contribution below, but if mailing a check to our office, please send to the address below and mark the year in which you wish the contribution to be applied to.
                    </IonCol>
                </IonRow>
            )}
            <FormProvider {...methods}>
                <div>
                    {displayRolloverForm()}
                </div>
            </FormProvider>
            <IonRow>
                <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                {fields.length < 2 && (
                    <IonButton onClick={addRollover}>
                        <IonIcon icon={addOutline} slot='start'></IonIcon>
                        Add Rollover from a Non-IRA Retirement Account
                    </IonButton>
                )}
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        account_type: state.welcomePage.account_type
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Rollovers);

import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToolbar, IonFooter, IonText, isPlatform } from '@ionic/react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Menu.css';
import generateAppPages,{ AppPage } from '../helpers/menuGenerator';
import {  checkmarkCircle, alertCircleOutline } from 'ionicons/icons';
import { isMobile } from '../helpers/utils';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import { StoreState } from '../store/reducers/rootReducer';

interface AppSection {
  header: string;
  pages: AppPage[]
}

export interface session{ 
  menuSections: AppSection[],
  setMenuSections: Function,
  menuParams: MenuParameters,
  stepToPath: (path: string) => void,
  welcomePageFields: welcomePageParameters,
  viewMode?: ViewMode 
}

const Menu: React.FC<session> = ({menuSections, setMenuSections, menuParams, stepToPath, welcomePageFields, viewMode}) => {
  const location = useLocation();

  useEffect(()=>{
    let menuSections:MenuSection[] = generateAppPages(menuParams, welcomePageFields, viewMode);
    setMenuSections(menuSections);    
  },[menuParams, welcomePageFields, setMenuSections, viewMode]);

  const setMenuClick = (e: any) => {
    let path = e.target.getAttribute("data-link");
    stepToPath(path);
  }

  const getDisclosurePdfUrl = (accountType: string) => {
    return accountType?.includes('Roth')? 'https://www.midlandtrust.com/forms/Roth-IRA-5305-RA.11.13.19-1.pdf' : 'https://www.midlandtrust.com/forms/Traditional-IRA-5305.pdf';
  }

  let appPages = menuSections.flatMap(menuSection => menuSection.pages);

  const menuCssClass = !isMobile(isPlatform) ? (viewMode === 'isp' ? 'ion-padding' : 'ion-padding ion-margin-top' ) : ''

  return (
    <IonMenu contentId="main" type="overlay" disabled={false} className={menuCssClass}>
      <IonContent forceOverscroll={false}>
        <IonList id="inbox-list" className={!isMobile(isPlatform) ? 'ion-justify-content-center' : ''}>
          {menuSections.map((menuSection, index) => {
            return (
              <React.Fragment key={index}>
                <IonListHeader>{menuSection.header}</IonListHeader>
                 {menuSection.pages.map((appPage, index) => {
                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem className={location.pathname === appPage.url ? 'selected menuItem' : 'menuItem'} onClick={setMenuClick} routerDirection="none" lines="none" detail={false} data-link={getLink( appPage.url, appPages)}>
                          <IonIcon slot="start" color={(appPage.isValid === true) ? 'success' : 'primary'} icon={(appPage.isValid === true ) ? checkmarkCircle : alertCircleOutline} ariaLabel={appPage.isValid === true ? 'Step Completed Checkmark' : 'Step Not Completed'} data-link={getLink( appPage.url, appPages)}/>
                          <IonLabel tabIndex={0} className='ion-text-wrap' data-link={getLink( appPage.url, appPages)}>{appPage.title}</IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    );
                 })}
              </React.Fragment>         
            )
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar color="primary" className='ion-padding'> 
        <div className='footer-padding'>
            <IonText color='light' className='ion-text-center'>
              <strong>Contact Us <br/></strong>
            </IonText> 
              <a href="tel:239-333-1032" className='contact-info' rel="noopener noreferrer" target="_blank" aria-label='link to Call (239) 333-1032'> 
                  <IonText className='contact-info'>
                    (239) 333-1032
                  </IonText><br/>
              </a>
              <a className='contact-info' rel="noopener noreferrer" href="mailto:EISnewaccounts@trustetc.com" target="_blank" aria-label='link to email EISnewaccounts@trustetc.com'>
                <IonText className='contact-info'>
                  EISnewaccounts@trustetc.com
                </IonText><br/>
              </a>
              <a className='contact-info' rel="noopener noreferrer" href="https://www.trustetc.com/index" target="_blank" aria-label="link to website www.trustetc.com/index">
                <IonText className="contact-info">
                  www.trustetc.com/index
                </IonText><br/>
              </a>
              {welcomePageFields.account_type && 
                <a className='contact-info' rel="noopener noreferrer" href={getDisclosurePdfUrl(welcomePageFields.account_type)} target="_blank" aria-label="view disclosure document">
                  <IonText className="contact-info">
                    View our disclosures
                  </IonText>
                </a>
              }
        </div>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

function getLink( url:string, appPagesInOrder:Array<AppPage>){
  let appPagesArr = [...appPagesInOrder];
  let currentPageIndex = appPagesArr.findIndex(appPage => (appPage.url === url));
  
  if (currentPageIndex >= 0 && !appPagesArr[currentPageIndex].isValid){
    let firstInvalidPageIndex = appPagesArr.findIndex(appPage => !appPage.isValid);

    if ((firstInvalidPageIndex === currentPageIndex) || firstInvalidPageIndex === -1) {
      url = appPagesInOrder[currentPageIndex].url;
    } else {
      url = appPagesInOrder[firstInvalidPageIndex].url; 
    }

  }
  return url;
}

const mapStateToProps = (state: StoreState) => {
  return {
    menuParams: state.menu.menuParams,
    menuSections: state.menu.menuSections,
    viewMode: state.onePathParams.viewMode,
    welcomePageFields: state.welcomePage
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setMenuSections: (payload: any) => dispatch(actions.setMenuSections(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

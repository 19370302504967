import React from 'react';
import { IonToast } from '@ionic/react';

import { connect } from 'react-redux';
import * as actions from '../store/actions';

interface toastState {
    showErrorToast: boolean,
    setShowErrorToast: Function,
    errorMessage: string,
    setErrorMessage: Function,
    showSuccessToast: boolean,
    setShowSuccessToast: Function,
    successMessage: string,
    setSuccessMessage: Function
}

const Toasts: React.FC<toastState> = ({showErrorToast, setShowErrorToast, errorMessage, showSuccessToast, setShowSuccessToast, successMessage}) => {
    return (
        <React.Fragment>
            <IonToast color="danger shade" position="top" isOpen={showErrorToast} onDidDismiss={(event) => event.detail.role === 'cancel' && setShowErrorToast(false)} message={(errorMessage === '' ? 'There are errors on this page' : errorMessage)} buttons={[
                {
                    icon: 'close',
                    role: 'cancel'
                }
            ]} />
            <IonToast color="success" position="top" isOpen={showSuccessToast} duration={3000} onDidDismiss={() => setShowSuccessToast(false)} message={successMessage} buttons={[{ icon: 'close', role: 'cancel' }]} />
        </React.Fragment>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        showErrorToast: state.toastControl.showErrorToast,
        errorMessage: state.toastControl.errorMessage,
        showSuccessToast: state.toastControl.showSuccessToast,
        successMessage: state.toastControl.successMessage
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
import { IonButtons, IonContent, IonPage, IonLoading, isPlatform, IonSplitPane } from '@ionic/react';
import ReactGA from 'react-ga';
import React, { useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { isMobile } from '../helpers/utils'
import { AppPage } from '../helpers/menuGenerator';
import WizardButtonsSavePageProps from '../components/WizardButtonsSavePage';
import Toasts from '../components/Toasts';
import Header from '../components/Header';
import Menu from '../components/Menu';

import PageLoader from '../components/LoadPage'
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import { useCallback } from 'react';
import { StoreState } from '../store/reducers/rootReducer';

export interface userState {
  prevPage?: AppPage,
  currentPage: AppPage,
  nextPage?: AppPage
}

export interface session {
  onePathParameters?: onePathParameters,
  menuSections: MenuSection[],
  showSpinner: boolean,
  setShowErrorToast: Function,
  setErrorMessage: Function,
  setShowSuccessToast: Function,
}

type ApplicationHistory = {
  page?: AppPage
}

export type SubmitState = {
  status: 'Success' | 'Error',
  message?: string
}

const Page: React.FC<session> = ({ menuSections, onePathParameters,
  showSpinner, setShowErrorToast, setErrorMessage, 
  setShowSuccessToast }) => {
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;

  const history = useHistory<ApplicationHistory>();

  const welcomePage = { 
    header: 'Getting Started',
    title: 'Welcome to Equity Trust!',
    url: '/Welcome',
    isValid: false
  };
  let activePage: AppPage = history?.location?.state?.page || welcomePage;

  let appPages = menuSections.flatMap(e => {
    return e.pages
  });

  const { name } = useParams<{ name: string; }>();

  useEffect(() => {
    if(process.env.NODE_ENV !== 'production'){
      return
    }

    ReactGA.pageview(name, [], name);
  }, [name]);

  const direction = useRef<'next' | 'back' | 'none'>();

  const stepInDirection = (navigationDirection: 'next' | 'back' | 'none') => () => {
    menuDestinationPath.current = '';
    direction.current = navigationDirection;
    console.info(`Navigating ${navigationDirection}`)
    formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true }));    
  }
  
  const menuDestinationPath = useRef('');

  const stepToPath = (path: string) => {
    direction.current = 'none';
    menuDestinationPath.current = path;
    formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true })); 
  } 

  const handleNavigation = (state: SubmitState) => { 
    if(state.status === 'Error' && direction.current === 'next') {
      console.log('An error with field submissions was found. Unable to navigate to the next step');
      menuDestinationPath.current = '';
      setErrorMessage(state.message || '');
      setShowErrorToast(true);
      return;
    }
    if(state.status === 'Error' && menuDestinationPath.current !== '' && !isPageBeforeActivePage(menuDestinationPath.current)) {
      console.log('An error with field submissions was found. Unable to navigate directly to the selected step');
      menuDestinationPath.current = '';
      setErrorMessage(state.message || '');
      setShowErrorToast(true);
      return;
    }

    if(menuDestinationPath.current !== '') {
      navigateDirectlyToStep();
    }
    else {
      incrementStep();
    }
  }

  const incrementStep = useCallback(() => {
    const matchActivePageURL = (page: AppPage) => page.url.includes(activePage.url);
    let currentPageIndex = appPages.findIndex(matchActivePageURL);

    if(!direction?.current) {
      console.log('Navigation unsuccesful, no direction set');
      return;
    }
    else if(direction.current === 'back' && currentPageIndex === 0) {
      console.log('Already on first step, unable to go back');
      return;
    }
    else if(direction.current === 'next' && currentPageIndex === appPages.length - 1) {
      console.log('Already on last step, unable to go forward');
      return;
    }

    setShowErrorToast(false);
    setShowSuccessToast(false);

    const destinationIndex = (direction?.current === 'back') ? currentPageIndex - 1 : currentPageIndex + 1;
    history.push(appPages[destinationIndex].url, {
      page: appPages[destinationIndex]
    });
  }, [history, direction, appPages, activePage, setShowErrorToast, setShowSuccessToast]);

  const navigateDirectlyToStep = useCallback(() => {
    const matchDestinationPageURL = (page: AppPage) => page.url.includes(menuDestinationPath.current || '/Welcome');
    let destinationPageIndex = appPages.findIndex(matchDestinationPageURL);
    menuDestinationPath.current = '';

    if(destinationPageIndex < 0) {
      console.log('Unable to find the selected step in the menu');
      return;
    }    
    setShowErrorToast(false);
    setShowSuccessToast(false);

    history.push(appPages[destinationPageIndex].url, {
      page: appPages[destinationPageIndex]
    });
  }, [appPages, history, menuDestinationPath, setShowErrorToast, setShowSuccessToast]);

  const isPageBeforeActivePage = (pageURL : string) => {
    const matchActivePageURL = (page: AppPage) => page.url.includes(activePage.url);
    const matchDestinationPageURL = (page: AppPage) => page.url.includes(pageURL)

    let activePageIndex = appPages.findIndex(matchActivePageURL)
    let destinationPageIndex = appPages.findIndex(matchDestinationPageURL);

    return destinationPageIndex < activePageIndex;
  } 

  const ionPageCssClass = isPlatform('android') ? 'android-fit-content ion-justify-content-around' : (onePathParameters?.viewMode === 'isp' ? '' : 'ion-padding-top')

  return (
    <IonContent>
      {onePathParameters?.viewMode === 'reg' && <Header stepInDirection={stepInDirection} />}
      <IonSplitPane contentId="main">
        <Menu stepToPath={stepToPath}/>
        <IonPage id='main' className={ionPageCssClass}>
          <IonContent className='ion-padding'>
            <Toasts/>
            <IonLoading id='page-load' isOpen={showSpinner} message={'Loading..'} spinner="lines" duration={20000}></IonLoading>
            {isMobile(isPlatform) && (
              <IonButtons className="ion-justify-content-center">
                <WizardButtonsSavePageProps buttonPositions={[]} buttonStyles={[]} handleStep={stepInDirection} />
              </IonButtons>
            )}
            <PageLoader pageName={name} formRef={formRef} handleNavigation={handleNavigation} />
            <IonButtons className={isMobile(isPlatform) ? "ion-justify-content-center ion-padding" : "ion-justify-content-end ion-padding"}>
            <WizardButtonsSavePageProps buttonPositions={[]} buttonStyles={[]} handleStep={stepInDirection}  />
            </IonButtons>
          </IonContent>
        </IonPage>
      </IonSplitPane>
    </IonContent>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    menuSections: state.menu.menuSections,
    showSpinner: state.toastControl.showSpinner,
    onePathParameters: state.onePathParams,
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
    setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
    setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
    setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
    setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);
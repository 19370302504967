import ReactGA from 'react-ga'; 
import Resume from './pages/Resume'
import InitPage from './components/InitPage';
import React, {useEffect} from 'react';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Switch, Redirect } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/apptheme.css';
import DocusignReturn from './pages/DocusignReturn';
import ErrorPage, {BASE_PATH} from './pages/ErrorPage';
 
const trackingId = "UA-77131423-3";
const App: React.FC = props => {
  useEffect(() => {
   ReactGA.initialize(trackingId); 
  }, [])

  return (
    <IonApp>
      <IonReactRouter> 
        <IonRouterOutlet animated={false} >
            <Switch>
              <Route path='/docusignReturn/:sessionId' render={() => <DocusignReturn/>}/> 
              <Route path="/resume/:herokuToken" render={() => <Resume/>}/>
              <Route path={`/${BASE_PATH}/:error?/:extra?`} component={ErrorPage}/>
              <Route exact path='/401k' render={(props)=> <Redirect to={{pathname: '/Welcome', search : props?.location.search, state: {"is401k": true}}} />}/>
              <Route exact path='/dyo401k' render={(props)=> <Redirect to={{pathname: '/Welcome', search : props?.location.search, state: {"isDYO401k": true}}} />}/>
              <Route exact path='/business' render={(props)=> <Redirect to={{pathname: '/Welcome', search : props?.location.search, state: {"useBusinessFeeSchedule": true}}} />}/>
              <Route exact path='/' render={(props)=> <Redirect to={{pathname: '/Welcome', search : props?.location.search}} />}/>
              <Route exact path="/:name" render={() => <InitPage />}/> 
            </Switch>
          </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  ); 
};


export default App;
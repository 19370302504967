import { IonButton, IonCol, IonGrid, IonItemDivider, IonRow } from '@ionic/react'
import React from 'react'
import { useState } from 'react'
import { MINIMUM_CASH_BALANCE } from '../helpers/sharedUtils';

type AssetCountFee = {
    fee: string
    description: string,
}

type AccountValueFee = {
    valueLowerBound: string,
    valueUpperBound: string,
    fee: string
}

const assetCountFees: AssetCountFee[] = [
    { fee: '$350', description: 'Per Asset for Real Estate, LLCs, Private Placements, Notes/Mortgages, Futures/Forex (per FCM), Crypto, Precious Metals (depository holding fees charged separately).' },
    { fee: '$350', description: 'Mortgage Payable' },
    { fee: '$100', description: 'Traditional Brokerage/Bank Account (excluding Crypto)' }
]

const accountValueFees: AccountValueFee[] = [
    { valueLowerBound: '$0', valueUpperBound: '$44,999.99', fee: '$350' },
    { valueLowerBound: '$45,000', valueUpperBound: '$59,999.99', fee: '$425' },
    { valueLowerBound: '$60,000', valueUpperBound: '$89,999.99', fee: '$500' },
    { valueLowerBound: '$90,000', valueUpperBound: '$124,999.99', fee: '$700' },
    { valueLowerBound: '$125,000', valueUpperBound: '$249,999.99', fee: '$950' },
    { valueLowerBound: '$250,000', valueUpperBound: '$499,999.99', fee: '$1,250' },
    { valueLowerBound: '$500,000', valueUpperBound: '$749,999.99', fee: '$1,650' },
    { valueLowerBound: '$750,000 and up', valueUpperBound: '', fee: '$1,850' }
]

const FeeSchedule: React.FC<{ showBothFeeOptions: boolean }> = ({ showBothFeeOptions }) => {
    const [showExpandedSchedule, setShowEntireSchedule] = useState<boolean>(false)

    const countBasedOption = (<>
        {showBothFeeOptions &&
            <IonRow>
                <h4><b>OPTION 1:</b> FEE BASED ON NUMBER OF ASSETS</h4>
            </IonRow>
        }
        {assetCountFees.map(countBasedFee => {
            return (
                <IonRow key={countBasedFee.fee}>
                    <IonCol size='2' sizeMd='2' sizeSm='4' sizeXs='4'>
                        <b>{countBasedFee.fee}</b>
                    </IonCol>
                    <IonCol size='10' sizeMd='10' sizeSm='8' sizeXs='8'>
                        {countBasedFee.description}
                    </IonCol>
                </IonRow>)
        })}
    </>)

    const valueBasedOption = (<>
        {showBothFeeOptions &&
            <>
                <IonRow>
                    <h4><b>OPTION 2:</b> FEE BASED ON TOTAL ACCOUNT VALUE</h4>
                </IonRow>
                <IonRow className='pl-3 pb-1'>
                    * Any acount holding a mortgage payable, futures, forex, and crypto is not eligible for Option 2
                </IonRow>
            </>
        }
        <IonRow>
            <IonCol size='12'>
                <IonRow className='p-0'>
                    <IonCol size='8' className='p-0'>
                        <b>Total Account Value Is Between:</b>
                    </IonCol>
                    <IonCol size='4' class='ion-text-end p-0'>
                        <b>Annual Fee</b>
                    </IonCol>
                </IonRow>

                {accountValueFees.map(valueBasedFee => {
                    return (
                        <IonRow className='smallFeeScheduleRow' key={valueBasedFee.valueLowerBound}>
                            <IonCol size='4'>
                                {valueBasedFee.valueLowerBound}
                            </IonCol>
                            <IonCol size='4'>
                                {valueBasedFee.valueUpperBound}
                            </IonCol>
                            <IonCol size='4' class='ion-text-end'>
                                <b>{valueBasedFee.fee}</b>
                            </IonCol>
                        </IonRow>)
                })}
            </IonCol>
        </IonRow>
    </>)

    const annualFeesSection = (<>
        <IonRow className='feeSectionHeader'>
            <h4><b>ANNUAL FEE AGREEMENT:</b></h4>
        </IonRow>
        <IonRow>
            <IonCol size={showBothFeeOptions ? '6' : '12'} sizeMd={showBothFeeOptions ? '6' : '12'} sizeSm='12' sizeXs='12'>
                {countBasedOption}
            </IonCol>
            {showBothFeeOptions &&
                <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12' className='leftBorder'>
                    {valueBasedOption}
                </IonCol>
            }
        </IonRow>
    </>)


    const miscellaneousServicesSection = (<>
        <IonRow className='feeSectionHeader'>
            <h4><b>MISCELLANEOUS SERVICES</b></h4>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            <IonCol size='2'>
                <b>Account Setup:</b>
            </IonCol>
            <IonCol size='10'>
                $50 for online applications. $100 if not submitted online. (Non-Refundable)
            </IonCol>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            <IonCol size='2'>
                <b>Transaction Fee:</b>
            </IonCol>
            <IonCol size='10'>
                $125 Applies to Each Purchase/Sale/Re-Registration of an Asset.{' '}
                Exceptions: $200 Real Estate, $50 Precious Metals/Trading/Bank Accounts.{' '}
                $25 Online Capital Calls/Additional Fundings/Trading Acct Withdrawals ($50 if not submitted online).
            </IonCol>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            <IonCol size='2'>
                <b>Wire Transfers:</b>
            </IonCol>
            <IonCol size='10'>
                $15 Incoming Wires (Free for Incoming IRA Transfers or Rollovers);{' '}
                $30 Outgoing Domestic (Free for Futures/Forex); $50 Outgoing International.
            </IonCol>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            <IonCol size='2'>
                <b>ACH Transfers:</b>
            </IonCol>
            <IonCol size='10'>
                $5 per request ($10 if not submitted online). Exceptions: Free ACH Distributions of Cash.
            </IonCol>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            <IonCol size='2'>
                <b>Trust Checks:</b>
            </IonCol>
            <IonCol size='10'>
                Free for Initial Asset Purchases. $5 for all other requests ($10 if not submitted online).
            </IonCol>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            <IonCol size='2'>
                <b>Check Deposits:</b>
            </IonCol>
            <IonCol size='10'>
                $5 for incoming checks related to rental and note income deposits (no charge for online payments made through pay.midlandtrust.com).
            </IonCol>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            <IonCol size='2'>
                <b>Cash Distributions:</b>
            </IonCol>
            <IonCol size='10'>
                Free If Submitted Online or If Recurring. ($25 if not submitted online). No Delivery Fee for ACH. Check/Wire Fee Applies Where Applicable.
            </IonCol>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            <IonCol size='2'>
                <b>Partial Transfer Out:</b>
            </IonCol>
            <IonCol size='10'>
                $50 per Outgoing IRA-To-IRA Transfer, Plus Asset Re-Registration Fees for In-Kind Transfers (If Applicable.)
            </IonCol>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            <IonCol size='2'>
                <b>Full Termination:</b>
            </IonCol>
            <IonCol size='10'>
                $250. Plus, Asset Re-Registration Fees for In-Kind Transfers or In-Kind Distributions (If Applicable).
            </IonCol>
        </IonRow>

        <IonRow className='standardFeeScheduleRow'>
            Official Check, Returned Deposit, Stop Payment, Cred Card/ACH Return: $30 per occurrence.
        </IonRow>
        <IonRow className='standardFeeScheduleRow'>
            Rush Transaction: For funding requests where investment documents or funds are not in good order before one business day of funding request: $150
        </IonRow>
        <IonRow className='standardFeeScheduleRow'>
            Special Notification/Research Fees: Matured Notes, Late Fair Market Valuation, Valuation Research: $75 Each (No Charge for 1st Notice. Fee Begins With 2nd Notice).
        </IonRow>
        <IonRow className='standardFeeScheduleRow'>
            Cash Only Accounts: $100 Annually (Only Applicable for Asset Based Fee Accounts With No Assets and No Buy/Sell Activity for Previous 12 Months).
        </IonRow>
    </>)

    const notesSection = (
        <IonRow className='p-1'>
            <IonRow className='pb-1'>
                <span><b>MINIMUM BALANCES:</b> Accounts must keep a minimum of ${MINIMUM_CASH_BALANCE} undirected cash at all times. Equity Trust reserves the right to not process transactions that will result in a cash balance below ${MINIMUM_CASH_BALANCE}.</span>
            </IonRow>
            <IonRow className='pb-1'>
                <span>
                    <b>BILLING CYCLE:</b> Administration fees will be assessed and due upon initial account opening, initial asset acquisition and annually on the 1st day of your billing cycle. Your 
                    billing cycle is defined as a 12-month period from day 1 of the month of your initial account opening. For example, if your account was initially opened on February 14th, your 
                    billing cycle would be February 1 through January 31. Fees for asset purchases made after the third quarter of your billing cycle may be pro-rated on a quarterly basis. For example, 
                    if you purchase an asset in December, the account is in quarter four of your normal billing cycle (Feb-Jan), so your pro-rated asset holding fee of $350 would be $350 x 1/4 = $87.5. 
                    Fees for assets sold, transferred or otherwise removed prior to the end of your billing cycle will not be pro-rated and/or refunded. Fees paid from your account will be reflected on your account statement.
                </span>
            </IonRow>
            <IonRow className='pb-1'>
                <span>
                    <b>PAST DUE FEES:</b> Fees that remain unpaid for more than 30 calendar days are considered past due. A late charge of $20 per month will apply. Past due fees will be deducted from
                    your Undirected Cash. In accordance with your Plan Agreement and Disclosures, if there is insufficient undirected cash in your account, we may liquidate other assets in your
                    account to pay for such fees after 30 day notification. Accounts with past due fees will be closed. Any remaining assets in the account will be a taxable event to the account
                    holder and will be reported on IRS Form 1099-R. Account reinstatement eligibility will be determined at Equity Trust sole discretion. All past due fees, late charges, and a $150
                    account reinstatement fee must be paid prior to account reinstatement.
                </span>
            </IonRow>
            <IonRow className='pb-1'>
                <span>
                    <b>CUSTODIAN’S FEES:</b> Equity Trust Company may use and earn income on your uninvested cash, as permitted by law. As the Custodian, Equity Trust Company reserves the
                    right to change all or part of the Custodial Fee Schedule at its discretion with 30 days advance notice. Please refer to the 5305 for the complete disclosure of the uninvested cash
                    policy.A copy of your plan agreement can be found online at{' '}
                    <a className='feeScheduleLink' href='https://www.midlandtrust.com/5305' target="_blank">www.midlandtrust.com/5305</a>{' '}
                    or by calling our office. The current Equity Trust fee schedule can be found at{' '}
                    <a className='feeScheduleLink' href="https://www.midlandtrust.com/fee-schedule/" target="_blank">www.midlandtrust.com/fee-schedule/</a>
                </span>
            </IonRow>
            <IonRow>
                <span>
                    <b>DEPOSITORY COLLECTION SURCHARGE:</b> For Clients with Precious Metals, Equity Trust Company pays all depository bills automatically. If clients do not leave enough cash{' '}
                    in their account or a credit card on file to cover depository fees, Equity Trust will charge a $5 surcharge per depository billing for our additional collection efforts.
                </span>
            </IonRow>
        </IonRow>)

    return (<>
        <IonGrid className='feeSchedule'>
            {annualFeesSection}
            {showExpandedSchedule && miscellaneousServicesSection}
            {showExpandedSchedule && notesSection}
        </IonGrid>
        <IonItemDivider className='noDecorationDivider'>
            <IonButton className='mr-1' slot='end' onClick={e => setShowEntireSchedule(!showExpandedSchedule)}>{showExpandedSchedule ? 'Less Details' : 'More Details'}</IonButton>
        </IonItemDivider>
    </>)
}

export default FeeSchedule
export const setShowErrorToast = (showErrorToast: boolean) => {
    return {
        type: 'SET_SHOW_ERROR_TOAST' as const, 
        showErrorToast
    }
}

export const setErrorMessage = (errorMessage: string) => {
    return {
        type: 'SET_ERROR_MESSAGE' as const, 
        errorMessage
    }
}

export const setAndShowErrorMessage = (errorMessage: string) => {
    return {
        type: 'SET_AND_SHOW_ERROR_MESSAGE' as const, 
        errorMessage
    }
}

export const setShowSpinner = (showSpinner: boolean) => {
    return {
        type: 'SET_SHOW_SPINNER' as const, 
        showSpinner
    }
}

export const setShowSuccessToast = (showSuccessToast: boolean) => {
    return {
        type: 'SET_SHOW_SUCCESS_TOAST' as const,
        showSuccessToast
    }
}

export const setAndShowSuccessMessage = (successMessage: string) => {
    return {
        type: 'SET_SHOW_SUCCESS_MESSAGE' as const, 
        successMessage
    }
}

export const setSuccessMessage = (successMessage: string) => {
    return {
        type: 'SET_SUCCESS_MESSAGE' as const, 
        successMessage
    }
}

export type ToastActions = ReturnType<typeof setShowErrorToast>
    | ReturnType<typeof setErrorMessage>
    | ReturnType<typeof setShowSpinner>
    | ReturnType<typeof setShowSuccessToast>
    | ReturnType<typeof setSuccessMessage>
    | ReturnType<typeof setAndShowErrorMessage>
    | ReturnType<typeof setAndShowSuccessMessage>
import { IonCol, IonRow, IonLabel, IonItem, IonInput, isPlatform, IonCheckbox } from '@ionic/react'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { Controller, useForm, UseFormMethods } from 'react-hook-form'
import { currencyFormatter, isMobile } from '../helpers/utils'
import TooltipComponent from './TooltipComponent';
import helpCircleOutline from '../images/help-circle-outline.svg';
import './Checkout.css';
import { FundingOptionsContext } from './steps/FundingOptions'
import { MINIMUM_CASH_BALANCE } from '../helpers/sharedUtils';
import { Fee, getEstimatedFees, Investment } from '../helpers/feeHelper';

export type CheckoutFormData = {}

export type CheckoutProps = {
    form: UseFormMethods<{ totalFundingAmount: number }>
    colSize: string,
    formData?: {
        investmentDetails?: { has_initial_investment: boolean, investment_amount: number, initial_subscription_amount: number, investment_type: string, checkout_validation_bypass: boolean},
        feeArrangement?: { fee_schedule: string, payment_method: string }
    },
    submitRef: any, //TODO: USE CORRECT TYPE HERE, NOT ANY
    onePathParameters: onePathParameters,
    requiredFundingAmount: number,
    setRequiredFundingAmount: Function,
    checkoutBypass: boolean,
    setCheckoutBypass: Function
}

const Checkout: React.FC<CheckoutProps> = ({ form, colSize, formData, submitRef, onePathParameters, requiredFundingAmount, setRequiredFundingAmount, checkoutBypass, setCheckoutBypass }) => {
    const ref = useRef() as any
    const { transferTypes, setTotalFundingBelowRequiredFunding } = useContext(FundingOptionsContext)
    const [estimatedFees, setEstimatedFees] = useState<Fee[] | undefined>()
    const [ showBypass, setShowBypass ] = useState<boolean>(false);

    const getDefaultValues = ()=>{
        const investmentDetails = formData?.investmentDetails
        
        let defaultValues: Forms.checkoutForm = {
            investment_amount: investmentDetails?.investment_amount,
            initial_subscription_amount: investmentDetails?.initial_subscription_amount,
            checkout_validation_bypass: investmentDetails?.checkout_validation_bypass
        }
        return defaultValues
    }
    
    const methods = useForm<Forms.checkoutForm>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });

    let investmentAmount = methods.watch('investment_amount')
    let initialSubscriptionAmount = methods.watch('initial_subscription_amount')
    let totalFundingAmount = form.watch('totalFundingAmount');

    submitRef.current = methods;

    useEffect(() => {
        const investmentDetails = formData?.investmentDetails 
        
        if (investmentDetails && (investmentDetails.investment_amount || investmentDetails.initial_subscription_amount)) {
            
            let defaultInvestmentAmount = (onePathParameters.hasOnePathParams && onePathParameters.call_based_asset) ? investmentDetails?.initial_subscription_amount : investmentDetails?.investment_amount | 0
            
            if (investmentDetails?.has_initial_investment === false) {
                defaultInvestmentAmount = 0
            }

            const feeArrangement = formData?.feeArrangement
            const paymentMethod = feeArrangement?.payment_method || ''
            const feeSchedule = feeArrangement?.fee_schedule || ''

            const investmentForCalculatingFees: Investment = {
                type: formData?.investmentDetails?.investment_type || '',
                value: defaultInvestmentAmount || 0
            }
        
            const estimatedFeeResults = getEstimatedFees(paymentMethod, feeSchedule, investmentForCalculatingFees)
            if (estimatedFeeResults.length > 0) {
                setEstimatedFees(estimatedFeeResults)
            }

            let sumOfFees = 0
            for (const fee of estimatedFeeResults) {
                sumOfFees += fee.value
            }

            let currentInvestmentAmount
            if (onePathParameters.hasOnePathParams && onePathParameters.call_based_asset) {
                currentInvestmentAmount = (initialSubscriptionAmount !== defaultInvestmentAmount) ? initialSubscriptionAmount : defaultInvestmentAmount | 0
            }
            else {
                currentInvestmentAmount = (investmentAmount !== defaultInvestmentAmount) ? investmentAmount : defaultInvestmentAmount | 0
            }
            
            if (!currentInvestmentAmount) {
                currentInvestmentAmount = 0
            }

            setRequiredFundingAmount(+currentInvestmentAmount + +MINIMUM_CASH_BALANCE + +sumOfFees)
        }
    }, [formData, investmentAmount, initialSubscriptionAmount])

    const getInvestmentAmountLabel = () => {
        const investmentDetails = formData?.investmentDetails 
        
        if (investmentDetails && investmentDetails.initial_subscription_amount) {
            return 'Initial Call Amount'
        }
        return 'Amount to Invest'
    }

    useEffect(() => {
        if (formData?.investmentDetails?.checkout_validation_bypass) {
            setCheckoutBypass(true);
            setShowBypass(true);
        } else {
            setCheckoutBypass(false);
        }
    }, [formData?.investmentDetails?.checkout_validation_bypass]);

    useEffect(() => {
        if (totalFundingAmount === requiredFundingAmount) {
            setCheckoutBypass(false);
        };
    }, [totalFundingAmount])
    
    const handleCheckoutBypass = (event: any) => {
        methods.setValue('checkout_validation_bypass', event.detail.checked);
        setCheckoutBypass(event.detail.checked);
    };

    return (
        <IonCol size={colSize} >
            <IonRow className={isMobile(isPlatform) ? 'checkout' : 'checkout checkout-desktop'} >
                <div ref={ref} style={{ width: '100%', height: '100%' }}>
                    <h5>
                        Costs:
                    </h5>
                    <IonRow className='ion-align-items-center'>
                        <IonCol size='6'>                            
                            <b>{getInvestmentAmountLabel()} <TooltipComponent icon={helpCircleOutline} message={'Amount to be invested originally entered on initial investment page'}/></b>
                            
                        </IonCol>
                        {onePathParameters?.hasOnePathParams && onePathParameters?.call_based_asset ?
                            <IonCol size='6'>
                                <IonItem>
                                    <Controller control={methods.control} name='initial_subscription_amount' render={ ({name, value, onBlur, onChange}) =>
                                        <IonInput name={name} type='number' value={value} onIonBlur={onBlur} onIonChange={onChange} class='ion-text-right'/>
                                    } rules={{
                                        required: true,
                                        validate: {
                                            validNumber: val => { return (val === '' || val === null || val?.toString().match( /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/)!== null)},
                                            greaterThanZero: val => val === '' || val === null || val > 0   
                                        }
                                    }} />
                                </IonItem>
                            </IonCol> :
                            <IonCol size='6'>
                                <IonItem>
                                    <Controller control={methods.control} name='investment_amount' render={ ({name, value, onBlur, onChange}) =>
                                        <IonInput name={name} type='number' value={value} onIonBlur={onBlur} onIonChange={onChange} class='ion-text-right'/>
                                    } rules={{
                                        required: true,
                                        validate: {
                                            validNumber: val => { return (val === '' || val === null || val?.toString().match( /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/)!== null)},
                                            greaterThanZero: val => val === '' || val === null || val > 0   
                                        }
                                    }} />
                                </IonItem>
                            </IonCol>
                        }
                    </IonRow>
                    <IonRow>
                        <IonCol size='6'>
                            Minimum Cash Balance
                            <TooltipComponent icon={helpCircleOutline} message={'Equity Trust requires a minimum of $' + MINIMUM_CASH_BALANCE + ' cash balance in your account at all times'}/>
                        </IonCol>
                        <IonCol size='6' class='ion-text-end'>
                            {currencyFormatter.format(MINIMUM_CASH_BALANCE)}
                        </IonCol>
                    </IonRow>

                    {estimatedFees && (<>
                        <h5>
                            Fees:
                        </h5>
                        {estimatedFees.map((fee) => {
                            return (
                                <IonRow key={fee.description}>
                                    <IonCol size='8'>
                                        {fee.description}
                                        <TooltipComponent icon={helpCircleOutline} message={fee.tooltipMessage}/>
                                    </IonCol>
                                    <IonCol size='4' class='ion-text-end'>
                                        {currencyFormatter.format(fee.value)}
                                    </IonCol>
                                </IonRow>
                            )
                        })}
                    </>)}

                    <h5>
                        Totals:
                    </h5>
                    <IonRow className='ion-align-items-center'>
                        <IonCol size='6'>
                            <b>Required Funding <TooltipComponent icon={helpCircleOutline} message={'The total amount required to open your account and complete your investment purchase including fees'}/></b>
                        </IonCol>
                        <IonCol size='6' class='ion-text-end'>
                            <b>{currencyFormatter.format(requiredFundingAmount)}</b>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='6'>
                            Funding Amount
                            <TooltipComponent icon={helpCircleOutline} message={'The sum of all transfers, rollovers, and contributions to your account'}/>
                        </IonCol>
                        <IonCol size="6" class='ion-text-end'>
                            <Controller name={'totalFundingAmount'} control={form.control} render={({ value }) =>
                                <IonLabel>{currencyFormatter.format(value)}</IonLabel>
                            } rules={{
                                required: true,
                                validate: (val: any) => {
                                    if (!transferTypes.some(transferType => transferType.isInKindTransfer) && +val < requiredFundingAmount && !checkoutBypass) {
                                        setTotalFundingBelowRequiredFunding(true)
                                        return `Funding amount error`
                                    }
                                }
                            }} />
                        </IonCol>
                    </IonRow>
                    {form.errors['totalFundingAmount'] && (<>
                        <IonItem className='ion-margin-top ion-margin-bottom' color='danger'>The transfer, rollover, and/or contribution amount must at least equal the required funding.</IonItem>
                    </>)}
                    {(showBypass || form.errors['totalFundingAmount']) &&
                        <>
                            <IonRow className={form.errors['totalFundingAmount'] ? '' : 'ion-margin-top'}>
                                (Optional) Funding Requirement Acknowledgement
                            </IonRow>
                            <IonRow className='ion-align-items-center'>
                                <IonCol size='2'>
                                    <Controller control={methods.control} name={'checkout_validation_bypass'} render={({name, value, onBlur}) =>
                                        <IonCheckbox data-testid='checkout-bypass-checkbox' name={name} checked={value} onIonBlur={onBlur} onIonChange={event => handleCheckoutBypass(event)} />
                                    } />
                                </IonCol>
                                <IonCol size='10'>
                                    I understand my account needs to be funded to cover my investment, the $250 minimum cash balance, and any applicable fees. Checking this box allows me to proceed for now, acknowledging that I will fund the account in full in the future.
                                </IonCol>
                            </IonRow>
                        </>
                    }
                </div>
            </IonRow>
        </IonCol>
    )
}

export default Checkout
import React from 'react';
import { IonCol, IonRow, IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';

const IncompleteApplication: React.FC<{hasIncompleteSteps: boolean}> = ({hasIncompleteSteps}) => {
   return (<>{
        hasIncompleteSteps && <IonRow className='well'>
            <IonCol>
                <IonRow>
                    <b>Uh Oh! There are a few errors to correct!</b>
                </IonRow>
                <IonRow>
                    <p>The sections that have any errors are marked with an &nbsp;
                        <IonIcon ariaLabel='Step Not Completed Icon' color='primary' icon={alertCircleOutline} size='large'/>
                    </p>
                </IonRow>
            </IonCol>
            <br/><br/>
        </IonRow>
    }</>
   )
}

export default IncompleteApplication
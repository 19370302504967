import React from 'react';
import { IonModal, IonButton, IonRow, IonContent } from '@ionic/react';
import './SaveAndExitModal.css';

const SaveAndExitModal: React.FC<{showModal: boolean, closeModal: Function}> = ({showModal, closeModal}) => {
    return (
        <IonModal cssClass={'save-and-exit-modal'} isOpen={showModal} onDidDismiss={() => closeModal()} backdropDismiss={true} >
            <IonContent>
                <IonRow className='modal-content'>
                    <IonButton className='x-button' onClick={() => closeModal()}>X</IonButton>
                    <IonRow className='save-and-exit-text'>
                        <strong>Your application was successfully saved. You may now close your browser tab.</strong>
                    </IonRow>
                    <IonButton className='return-button' onClick={() => closeModal()}>
                        Return to application
                    </IonButton>
                </IonRow>
            </IonContent>
        </IonModal>
    )
};

export default SaveAndExitModal;
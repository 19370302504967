import React, { useRef } from 'react';
import { IonButton, IonIcon, IonRouterLink} from '@ionic/react';
import { chevronBackCircleOutline, chevronForwardCircleOutline} from 'ionicons/icons';
import { useLocation } from 'react-router-dom';

type WizardButtonsProps = {
    buttonPositions: Array<string>, buttonStyles: Array<string>, 
    handleSave: () => Promise<void>, 
    handleStep(direction: 'next'|'back'):()=>void,
    viewMode?: ViewMode 
}

const WizardButtons: React.FC<WizardButtonsProps> = ({buttonPositions, buttonStyles, handleStep,  viewMode, handleSave}) => {
    const handleStepClicked = useRef<Boolean>(false);

    const debounceHandleStep: WizardButtonsProps['handleStep'] = (direction: 'next'|'back') => {
        const callBack = handleStep(direction)
        return ()=>{
            if(handleStepClicked.current){
                return
            }
            handleStepClicked.current = true;
            setTimeout(()=>{handleStepClicked.current = false}, 1000)
            callBack()
        }
    }

    const ispMode = viewMode === 'isp'

    const location = useLocation();
    const isPage = (page: string) => {
        return location.pathname.toUpperCase().includes(page.toUpperCase())
    }
    const welcomeOrOwnerInfoPage = (isPage('Welcome') || isPage('OwnerInformation'))
    const isSaveButtonDisabled = (!ispMode && welcomeOrOwnerInfoPage)
    const isPrevButtonDisabled = isPage('Welcome')
    const isNextButtonDisabled = isPage('ReviewAndSign')

    const onSaveAndExitClick = async () => {
        if (handleSave && !welcomeOrOwnerInfoPage) { 
            await handleSave();
        }

        if(ispMode)
        {
            window.parent.postMessage("close-iframe", document.referrer);
        }
    }

    let saveButtonPosition = buttonPositions.length >= 1 ? buttonPositions[0] : 'end';
    let prevButtonPosition = buttonPositions.length >= 2 ? buttonPositions[1] : 'end';
    let nextButtonPosition = buttonPositions.length >= 3 ? buttonPositions[2] : 'end';
    
    let saveButtonStyle = buttonStyles.length >= 1 ? buttonStyles[0] : '';
    let prevButtonStyle = buttonStyles.length >= 2 ? buttonStyles[1] : '';
    let nextButtonStyle = buttonStyles.length >= 3 ? buttonStyles[2] : '';
  
    return (
        <React.Fragment>
            {!isSaveButtonDisabled &&
                <IonRouterLink class='save-and-exit-button' slot={saveButtonPosition} className={saveButtonStyle} onClick={onSaveAndExitClick}>
                    {welcomeOrOwnerInfoPage ? 'Exit' : 'Save & Exit'}                    
                </IonRouterLink>
            }
            <IonButton slot={prevButtonPosition} className={prevButtonStyle} fill='solid' onClick={debounceHandleStep('back')} color='secondary' disabled={isPrevButtonDisabled}>
                <IonIcon icon={chevronBackCircleOutline} slot='start' />
                Prev
            </IonButton>
            <IonButton slot={nextButtonPosition} className={nextButtonStyle} fill='solid' onClick={debounceHandleStep('next')} color='secondary' disabled={isNextButtonDisabled}>
                <IonIcon icon={chevronForwardCircleOutline} slot='end' />
                Next
            </IonButton>
        </React.Fragment>
    );
}

export default WizardButtons;
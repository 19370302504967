import React, { useState, useEffect } from 'react';
import { getTotalFee, PaymentBody } from '../../helpers/utils';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { IonGrid, IonRow, IonCol, IonText, IonButton, IonLabel, IonLoading, IonItem, IonIcon, IonSelect, IonSelectOption } from '@ionic/react';
import { createPayments } from '../../helpers/calloutHelpers';
import { InitSessionApp } from '../../helpers/sessionInterfaces';
import { checkmarkCircle } from 'ionicons/icons';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

type promo_code =postgresSchema.promo_code 
type payment = postgresSchema.payment
type fee_arrangement = postgresSchema.fee_arrangement

interface PaymentInfoFC extends InitSessionApp {
    formData?:{data?: {promoCodeInfo: promo_code, paymentInfo:payment, feeArrangementInfo: fee_arrangement}},
    setShowSuccessToast: Function,
    setSuccessMessage: Function,
}

type ccOptions = 'New Credit Card'|'Card Provided Earlier'|''

interface paymentForm {
    creditCardNumber:'',
    expirationDateString:'',
    ccOption: ccOptions
}

const PaymentInformation: React.FC<PaymentInfoFC> = ({formData, sessionId, updateMenuItem, formRef,  is401kApplication, isDYO401kApplication, handleNavigation, setErrorMessage, setShowErrorToast, setSuccessMessage, setShowSuccessToast }) => {
    const defaultValues: paymentForm = {ccOption: '', creditCardNumber: '', expirationDateString: ''}

    const {control, handleSubmit, errors, formState, setValue, watch} = useForm<paymentForm>({
        mode: "onChange",
        defaultValues
    });
    
    const formFields = watch()

    const { isDirty, submitCount } = formState; 

    const totalFee = getTotalFee(is401kApplication, isDYO401kApplication);
    
    const promoCodeName = formData?.data?.promoCodeInfo?.name
    const promoCodeDiscountAmount = formData?.data?.promoCodeInfo?.discount_amount ? formData?.data?.promoCodeInfo?.discount_amount : 0
    const feeAmountMinusDiscount = formData?.data?.promoCodeInfo?.discount_amount ?  totalFee - formData.data.promoCodeInfo.discount_amount : totalFee;
    
    const [paymentStatus, setPaymentStatus] = useState<{ status:'Success'|'Pending'|'Error'|'', amount:number|null }>({ status:'', amount:0 })

    const [ccOptions, setCCOptions] = useState<Array<any>>([]);

    useEffect(()=>{
        if(formData?.data?.paymentInfo){
            setPaymentStatus({
                status:'Success',
                amount: formData.data.paymentInfo.payment_amount
            })
        }

        setCCOptions(getCCoptions())
    },[formData])

    const getCCoptions = () =>{
        let ccNumber = formData?.data?.feeArrangementInfo?.cc_number
        let expDate = formData?.data?.feeArrangementInfo?.cc_exp_date
        if (ccNumber && !ccNumber.includes('*') && expDate) {
            let ccStrLength = ccNumber.length; 
            let last4CC= ccNumber.substring(ccStrLength - 4); 
            let ccOption = {
                label: `Charge Card Ending in ${last4CC}`,  
                value: 'Card Provided Earlier'
            }
            let newCCOption = {
                label: 'Provide Different Card', 
                value: 'New Credit Card'
            }
            let ccOptionsArr = [ccOption, newCCOption];
            return ccOptionsArr
        }
        return []
    }

    const [ccInputMask, setCCInputMask] = useState('9999 9999 9999 9999');

    useEffect(() => {
        setTimeout(() => {
            setShowErrorToast(false);
        }, 2000)       
    }, [errors, paymentStatus]);

    const processCreditCard = (formFields: paymentForm) => {
        setPaymentStatus(prevState => {return {...prevState, status: 'Pending'}});

        let chosenCCandExpDate = {
            creditCardNumber: '',
            expirationDateString: ''
        }
        if(formFields.ccOption === 'Card Provided Earlier'){
            chosenCCandExpDate.creditCardNumber = formData?.data?.feeArrangementInfo.cc_number || ''
            let date = formData?.data?.feeArrangementInfo.cc_exp_date?.split('-')
            let newDate = date?.splice(0,2).reverse().join('/')
            chosenCCandExpDate.expirationDateString = newDate || ''
        }else{
            chosenCCandExpDate.creditCardNumber = formFields.creditCardNumber
            chosenCCandExpDate.expirationDateString = formFields.expirationDateString
        }
        

        let paymentBody: PaymentBody = {
            creditCardNumber: chosenCCandExpDate.creditCardNumber,
            expirationDateString: chosenCCandExpDate.expirationDateString,
            discountAmount:promoCodeDiscountAmount,
            feeAmount: totalFee,
            sessionId
        }

        createPayments(paymentBody).then(function(response: any) {
            setTimeout(function() {
                updateMenuItem('is_payment_information_page_valid', true, sessionId);
            }, 3000);
            setPaymentStatus(prevState => {return {...prevState, status: 'Success', amount: response.paymentAmount}});
            setSuccessMessage('Payment was successfully received');
            setShowSuccessToast(true);
        }).catch(function(error: any) {
            let errorMessage = (error.message && error.messsage !== '') ? `Error - ${error.message}` : 'Error Processing Payment'; 
            setErrorMessage(errorMessage);
            setShowErrorToast(true);
            setPaymentStatus(prevState => {return {...prevState, status: 'Error'}})
        })
    }

    const validateFields = () => {
        if (paymentStatus.status !== 'Success') {
            handleNavigation({status: 'Error', message: 'Payment Required.'})
            return;
        }
        updateMenuItem('is_payment_information_page_valid', true, sessionId);
        handleNavigation({status: 'Success'})
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = '';
        if ((submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };

    const onInvalidForm = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        }
        else {
            handleNavigation({status: 'Error'});
        }
    }
    
    return (
            <form ref={formRef} onSubmit={handleSubmit(validateFields, onInvalidForm)} className='ion-padding'>
                <IonGrid>
                    <IonRow className='well'>
                        <IonCol>
                            <p>
                            Equity Trust charges your credit card for today's non-refundable fee(s) listed below. If your account has insufficient funds, this credit card will be charged to cover future fees.
                            </p>
                        </IonCol>
                    </IonRow>
                        <IonText color='primary'>
                            <strong>
                                {!is401kApplication && !isDYO401kApplication && (
                                    <h5><strong>${totalFee} Setup Fee</strong></h5>
                                )}
                                {is401kApplication && (
                                    'Plan Fees'
                                )}
                            </strong>
                        </IonText>
                    {is401kApplication  && (
                        <>
                            <IonRow>
                                <IonCol size='3'>
                                    One-Time Setup
                                </IonCol>
                                <IonCol size='3'>
                                    $200
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='3'>
                                    Annual Account
                                </IonCol>
                                <IonCol size='3'>
                                   {isDYO401kApplication ? '$550' : '$400'} 
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='3'>
                                    <b>Total</b>
                                </IonCol>
                                <IonCol size='3'>
                                    <b>${totalFee}</b>
                                </IonCol>
                            </IonRow>
                            <br/>
                        </>
                    )}
                    {promoCodeName &&
                        <IonItem class='successItem ion-margin-bottom' lines='none'>
                            <IonIcon color='success' size="small" icon={checkmarkCircle}/>
                            {promoCodeName} applied for ${promoCodeDiscountAmount} off setup fee.
                        </IonItem>
                    }                
                    {paymentStatus?.status !== 'Success' && totalFee !== promoCodeDiscountAmount && 
                        <>
                            <IonRow className='well'>                      
                                <IonCol>
                                    <div><strong>Clicking "Submit & Proceed" will charge ${feeAmountMinusDiscount} to the credit card below.</strong></div>
                                    <div>You will then automatically proceed to the next step.</div>
                                </IonCol>    
                            </IonRow>
                            {ccOptions.length > 0 && (
                                <IonRow>
                                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                        <IonLabel>
                                            Credit Card *
                                        </IonLabel>
                                        <IonItem className={showError('ccOption')}>
                                            <Controller name='ccOption' control={control} render={ ({name, value}) =>
                                                <IonSelect name={name}  interface='action-sheet' value={value} onIonChange={(selected) => {
                                                    let ccOptionInput = selected.detail.value; 
                                                    if (ccOptionInput === 'Card Provided Earlier') {
                                                        setValue('ccOption', ccOptionInput)
                                                    } else {
                                                        setValue('ccOption', ccOptionInput)
                                                    }}} interfaceOptions={{animated:false, mode: 'ios'}}>
                                                    {ccOptions.map((option, index) => 
                                                        <IonSelectOption key={index} value={option.value}>{option.label}</IonSelectOption>
                                                    )}
                                                </IonSelect>
                                            }rules={{required: true}}/>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            )}
                            {(ccOptions.length === 0 || formFields.ccOption === 'New Credit Card') && (
                                <IonRow>
                                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                        <IonLabel>
                                            Credit Card Number *
                                        </IonLabel>
                                        <IonItem className={showError('creditCardNumber')}>
                                            <Controller name='creditCardNumber' control={control} render={ ({name, value}) =>
                                                <InputMask mask={ccInputMask} className='input-mask' value={value} onChange={(selected) => {
                                                    /*
                                                        Need to test this
                                                    */

                                                    let ccInput = selected.target.value;
                                                    if (/^3[47]/.test(ccInput)) {
                                                        setCCInputMask('9999 999999 99999');
                                                    } else {
                                                        setCCInputMask('9999 9999 9999 9999');
                                                    }
                                                    setValue('creditCardNumber', ccInput.replace(/\s/g, '').replace(/_/g,''))
                                                }}/>
                                            } rules={{required: true, maxLength: 20}}/>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                        <IonLabel>
                                            Expiration Date *
                                        </IonLabel>
                                        <IonItem className={showError('expirationDateString')}>
                                            <Controller name='expirationDateString' control={control} as={
                                                <InputMask mask='99/2099' className='input-mask'  placeholder='10/2025'/>
                                            } rules={{required: true,
                                                pattern:/^(0[1-9]|1[0-2])\/(20)\d{2}$/
                                                }}/>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            )}
                            <IonRow>
                                <IonCol>
                                    <IonButton className="ion-float-right" color="primary" onClick={() => processCreditCard(formFields)}>Submit & Proceed</IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    }               
                    <IonLoading isOpen={paymentStatus.status === 'Pending'} message={'Applying Payment...'} spinner="lines"></IonLoading>
                    {paymentStatus.status === 'Success' &&
                        <IonItem class='successItem ion-margin-bottom' lines='none'>
                            <IonIcon color='success' size="small" icon={checkmarkCircle}/>
                                    Thank you for your payment of ${paymentStatus.amount}.
                        </IonItem>
                    }
                </IonGrid>
            </form>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        is401kApplication: state.menu.menuParams.is401kApplication,
        isDYO401kApplication: state.menu.menuParams.isDYO401kApplication
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PaymentInformation);
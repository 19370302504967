import React, { useState, useEffect } from 'react'; 
import {useForm, useFieldArray, FormProvider, Controller, useWatch } from 'react-hook-form';
import { IonGrid, IonRow, IonCol, IonButton, IonIcon, IonModal, IonContent, IonCheckbox } from '@ionic/react';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { addOutline } from 'ionicons/icons';
import { saveBenePage } from '../../helpers/calloutHelpers';
import { SessionApp } from '../../helpers/sessionInterfaces'
import BeneficiaryForm from '../BeneficiaryForm'
import {connect } from 'react-redux';
import * as actions from '../../store/actions';
import {warning, chevronBackCircleOutline} from 'ionicons/icons'; 

interface beneFC extends SessionApp {
    formData?: {data? : {beneficiaries? : Array<SubForms.beneficiary>, applicant_data : applicantDataForBeneficiaries}},
    updateLastActivity: Function
}

const Beneficiaries: React.FC<beneFC> = ({sessionId, updateMenuItem, formRef, formData, updateLastActivity, handleNavigation, setShowErrorToast, setErrorMessage}) => { 
    const [showSpousalConsentModal, setShowSpousalConsentModal] = useState(false);
    const [hideSpousalConsentWarning, setHideSpousalConsentWarning] = useState(false);
        
    const spousalConsentStates : Array<string> = ['AZ', 'CA', 'LA', 'ID', 'NV', 'NM', 'TX', 'WA', 'WI'];

    const applicantLivesInSpousalConsentState = () => {
        if (formData?.data?.applicant_data?.legal_state) {
            return spousalConsentStates.includes(formData?.data?.applicant_data?.legal_state.toUpperCase());
        }
    }

    const applicantIsMarried = () => {
        if (formData?.data?.applicant_data?.marital_status) {
            return formData?.data?.applicant_data?.marital_status === 'Married';
        }
    }

    const blankBene : SubForms.beneficiary = {first_name:'',
        last_name: '',
        ssn: '',
        dob: '',
        email: '',
        mailing_city: '',
        mailing_state: '',
        mailing_street: '',
        mailing_zip: '',
        phone: '',
        relationship: null,
        share_percentage: null,
        type: null,
        entity_type: 'Individual',
        trustee_name: '',
        tax_id_type: 'Social Security Number'
    }

    const spousalConsentDefaultBene : SubForms.beneficiary = {
        ...blankBene,
        relationship: 'Spouse',
        type: 'Primary',
        share_percentage: 100
    }
    
    const setDefaultValues = () => {
        
        if (formData?.data?.beneficiaries !== undefined) {
            
            let spousalConsentAcknowledged = formData?.data?.applicant_data?.spousal_consent_acknowledged;
            
            if (formData.data?.beneficiaries?.length > 0) {
                return {bene: formData.data.beneficiaries, spousal_consent_acknowledged : spousalConsentAcknowledged}
            }
            else if (applicantLivesInSpousalConsentState() && applicantIsMarried()) {
                return {bene: [spousalConsentDefaultBene], spousal_consent_acknowledged : spousalConsentAcknowledged}
            }
        }
        return {spousal_consent_acknowledged : false}
    }

    const methods = useForm<{bene: Array<SubForms.beneficiary>, spousal_consent_acknowledged : boolean}>({
        mode: 'onChange',
        defaultValues: setDefaultValues()
    });

    const { fields, append, remove } = useFieldArray<SubForms.beneficiary>({
        control: methods.control,
        name: "bene"
    });

    const { isDirty } = methods.formState; 

    const [totalSharePercentage,setTotalSharePercentage] = useState<{Primary:number|null,Contingent:number|null}>({Contingent: null, Primary: null}) 

    useEffect(()=>{
        calcShare();
    },[fields.length])
    
    const addBeneficiary = () => {
        
        append(blankBene);
    }

    useEffect(() => {
        if (formData?.data?.beneficiaries) {
            let beneficiaries : Array<SubForms.beneficiary> = formData?.data?.beneficiaries;
            for (const beneficiary of beneficiaries) {
                if (isSolePrimarySpouseBeneficiary(beneficiary)) {
                    setHideSpousalConsentWarning(true);            
                }
            }
        }
    }, [formData?.data?.beneficiaries])

    const calcShare = () => {
        let beneficiaries = methods.getValues().bene
        let totalShareCalc:{Primary:number|null,Contingent:number|null} = {
            Contingent: null, Primary: null
        }

        if (beneficiaries !== undefined && beneficiaries?.length > 0) {
            for (const beneficiary of beneficiaries) {
                if (beneficiary !== undefined && beneficiary.type !== undefined && beneficiary.type !== null ) {
                    if(beneficiary.share_percentage !== null){
                        let beneShareNum = beneficiary.share_percentage as number
                        let beneficiaryShare = +beneShareNum;
                        if (beneficiaryShare !== null) {
                            let currentTotal = (totalShareCalc[beneficiary.type] !== null ? totalShareCalc[beneficiary.type] : 0) as number
                            totalShareCalc[beneficiary.type] = currentTotal + beneficiaryShare
                        }
                    }                    
                }
            }
        }
        setTotalSharePercentage(totalShareCalc);
    }

    const displayBeneficiaryForm = () => {
        
        let applicantData : applicantDataForBeneficiaries = {
            legal_street : formData?.data?.applicant_data?.legal_street,
            legal_city : formData?.data?.applicant_data?.legal_city,
            legal_state : formData?.data?.applicant_data?.legal_state,
            legal_zip : formData?.data?.applicant_data?.legal_zip,
        }
        
        return fields.map((value, index)=>{
            return (
                <BeneficiaryForm key={value.id} item={value} beneficiaryNumber={index} removeBeneficiary={()=>{remove(index)}} totalSharePercentage={totalSharePercentage} updateTotalShare={calcShare} applicantData={applicantData}/>
                )
        })
    }

    const updateMenus = () => {
        updateMenuItem('is_beneficiaries_page_valid', true, sessionId);
   }

   const isSharePercentageValid = () => {
       let isValid = true; 
       if (totalSharePercentage.Primary !== null) {
           isValid = isValid && (totalSharePercentage.Primary === 100);
       }
       if (totalSharePercentage.Contingent !== null) {
           isValid = isValid && (totalSharePercentage.Contingent === 100);
       }
       return isValid; 
   }

    const onInvalid = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        }
        else {
            handleNavigation({status: 'Error'});
        }
    }

    const validateFields = async (data: {bene: Array<SubForms.beneficiary>}) => {
        
        if (!isSharePercentageValid()) {            
            handleNavigation({status: 'Error', message: 'Share percentage for each beneficiary type must add up to 100.'});
            return;
        }

        let filledInFields = getFilledInFields(data?.bene);
        
        if (filledInFields && applicantLivesInSpousalConsentState() && applicantIsMarried() && !formData?.data?.applicant_data?.spousal_consent_acknowledged) { 
            let hasValidSpouseBeneficiary : boolean = false;
            for (const filledInField of filledInFields) {
                if (isSolePrimarySpouseBeneficiary(filledInField)) {
                    hasValidSpouseBeneficiary = true;
                }
            }

            if (hasValidSpouseBeneficiary) {
                setShowSpousalConsentModal(false);
            }
            else {
                setShowSpousalConsentModal(true);
                return;
            }
        }
        else {
            setShowSpousalConsentModal(false);
        }

        let payload : {beneficiaries:Array<SubForms.beneficiary>, spousal_consent_acknowledged: boolean}  = {beneficiaries:[], spousal_consent_acknowledged : false}
        payload = getPayload(filledInFields, false);

        saveBenePage(sessionId, payload, updateLastActivity)?.then(() => {
            updateMenus();
            handleNavigation({status: 'Success'});
        })
    }

    const saveAndContinueFromModal = async () => {
        
        let filledInFields = getFilledInFields(methods.getValues().bene);
        
        let payload : {beneficiaries:Array<SubForms.beneficiary>, spousal_consent_acknowledged: boolean}  = {beneficiaries:[], spousal_consent_acknowledged : false}
        payload = getPayload(filledInFields, methods.getValues('spousal_consent_acknowledged'));

        saveBenePage(sessionId, payload, updateLastActivity)?.then(() => {
            updateMenus();
            setShowSpousalConsentModal(false);
            handleNavigation({status: 'Success'});
        });
    }

    const getFilledInFields = (beneficiaries : Array<SubForms.beneficiary>) => {
        let filledInFields : Array<any> = [];
        
        if (beneficiaries) {
            filledInFields = beneficiaries.map((beneficiary) => {
                let filledInBene: any = {
                    ...blankBene,
                    ...beneficiary,
                    dob: beneficiary.entity_type === 'Individual' ? beneficiary.dob : '',
                    last_name: beneficiary.entity_type === 'Individual' ? beneficiary.last_name : beneficiary.entity_type,  
                    relationship: beneficiary.entity_type === 'Individual' ? beneficiary.relationship : '',
                    tax_id_type: beneficiary.entity_type === 'Individual' ? 'Social Security Number' : beneficiary.tax_id_type
                }
                let ordered: any = {}
                Object.keys(filledInBene).sort().forEach((key)=>{ //this is kind of a patch, I would rather update the upsert logic to generalize upserts out of order
                    ordered[key] = filledInBene[key]
                })
    
                return ordered;
            })
        }
        
        return filledInFields;
    }

    const getPayload = (filledInFields : any[], spousalConsentAcknowledged : boolean) => {
        
        let payloadWithNoBenes = {beneficiaries:[], spousal_consent_acknowledged: spousalConsentAcknowledged};
        
        let payloadWithBenes = {beneficiaries:filledInFields, spousal_consent_acknowledged: spousalConsentAcknowledged};
        
        return (!filledInFields) ? payloadWithNoBenes : payloadWithBenes;
    }

    const closeModal = () => {
        setShowSpousalConsentModal(false);
    }

    const spousal_consent_acknowledged = useWatch({
        name:'spousal_consent_acknowledged',
        control: methods.control
    })

    const isSolePrimarySpouseBeneficiary = (beneficiary : any) => {
        return (+beneficiary.share_percentage === 100) && beneficiary.relationship === 'Spouse' && beneficiary.type === 'Primary'; 
    }

    return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <IonGrid>
                    <IonRow className='well'>
                        <IonCol>
                            <p>
                                <b>Naming beneficiaries in this online application is optional, but highly recommended</b>. If you skip this step, we will provide you with a beneficiary form at a later date.
                            </p>
                            <p>
                                Naming a beneficiary allows your IRA assets to go to whomever you choose. Primary beneficiaries are the first set of individuals/entities that you wish to leave your retirement assets to. If you are married and leave the retirement account to your spouse, he/she inherits the account as if it were his/her own. Secondary beneficiaries receive the assets if your primary beneficiaries die before you or refuse to accept the inheritance.
                            </p>
                            <p>
                                If you elect not to designate a beneficiary, your assets may pass to your estate - subjecting them to the probate process, estate expenses, and creditor claims, causing delays for your beneficiary to receive these assets.
                            </p>
                        </IonCol>
                    </IonRow>
                    {applicantIsMarried() && applicantLivesInSpousalConsentState() && !hideSpousalConsentWarning && (
                        <IonRow className='warning-message'>
                            <div>
                                <IonIcon icon={warning} slot='start' ariaLabel='Warning' className='warning-icon'/>  &nbsp;
                            </div>
                            <IonCol>
                                <strong>Important Notice: You live in a state that requires spousal consent if your spouse is NOT the 100% sole primary beneficiary.</strong>
                                <p>Should you wish to continue without your spouse being listed as the 100% sole primary beneficiary, a further step of spousal consent will be necessary for Equity Trust to fully open your account.</p>
                            </IonCol>
                        </IonRow> 
                    )}
                    <FormProvider {...methods}>
                        {formRef && <form ref={formRef} onSubmit={methods.handleSubmit(validateFields, onInvalid)}>
                            {displayBeneficiaryForm()}
                        </form>}
                    </FormProvider>
                    {showSpousalConsentModal && (
                        <IonModal isOpen={showSpousalConsentModal} onDidDismiss={() => setShowSpousalConsentModal(false)} mode="ios">                    
                            <IonContent>
                                <h3 className="pl-3 pr-3">Important Notice</h3>
                                <p className="text-left pl-3 pr-3"><strong>You live in a state that requires spousal consent if your spouse is NOT the 100% sole primary beneficiary.</strong></p>
                                <p className="text-left pl-3 pr-3">Should you wish to continue without your spouse being listed as the 100% sole primary beneficiary, a further step of spousal consent will be necessary for Equity Trust to fully open your account.</p>
                                <p className="text-left pl-3 pr-3"><strong>Please click Back to edit your beneficiaries if needed.</strong></p>
                                <p className="text-left pl-3 pr-3">Otherwise, you can agree to the further spousal consent step:</p>
                                <p className="text-left pl-3 pr-3"><Controller name='spousal_consent_acknowledged' control={methods.control} render={({name, value}) => 
                                    <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                        methods.setValue('spousal_consent_acknowledged', event.detail.checked)
                                    }}></IonCheckbox> 
                                }/> &nbsp; I acknowledge a further step of spousal consent will be necessary for Equity Trust to fully open my account.</p>
                            </IonContent>
                            {spousal_consent_acknowledged && (
                                <IonButton onClick={() => saveAndContinueFromModal()}>Save & Continue</IonButton>
                            )}
                            <IonButton fill='solid' color='secondary' onClick={() => closeModal()}>
                                <IonIcon icon={chevronBackCircleOutline} slot='start'/>
                                Back
                            </IonButton>
                        </IonModal>
                    )}
                    <IonRow>
                        <IonCol>
                            {fields.length < 4 && 
                                <IonButton onClick={addBeneficiary}> <IonIcon icon={addOutline} slot='start'></IonIcon> Add Beneficiary </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </MuiPickersUtilsProvider>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        updateLastActivity: (lastActive: number) => dispatch(actions.updateLastActivity(lastActive)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default  connect(mapStateToProps,mapDispatchToProps)(Beneficiaries); 
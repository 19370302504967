export type State ={
    sessionId: string,
    herokuToken?: string,
    lastActive?: number,
    sessionLength?: number,
    sessionIntervalHandler?: NodeJS.Timeout,
    currentPageHistoryListener?: Function
    pollingRate: number,
    showModal: boolean,
    sessionState: 'start'|'running',
}
 
export function intervalCallback(sessionLength: any, lastActive: number, showTimeoutModal: Function, pollingRate: number ){
    let seshLength = sessionLength || 360000;
    let sessionInterval = setInterval(()=>{
    let elapsedTime = Date.now() - (lastActive || 0);
    if(elapsedTime > seshLength){
        //callout to kill session
        // dispatch({type:'showTimeoutModal', payload:true})
        showTimeoutModal(true);
        
        clearInterval(sessionInterval);
    }
    }, pollingRate)
  
    return sessionInterval;
}

export function updateActivityCallout(sessionId?: string){
    if(sessionId && sessionId !== ''){
        let url ='/updateSessionActivity'
        let body ={
            sessionId: sessionId
        }
        let options = {
            method : 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }
        return fetch(url, options).then((response)=>{
            if(response.ok){
                return response.json().then((result: API.AppSession)=>{
                    return result
                })
            }
        }).catch((err)=>{
            return undefined;
        })
    }else{
        return null
    }
}
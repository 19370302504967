import React, { useRef, useState, useEffect, createContext, MutableRefObject } from 'react';
import { IonGrid, IonRow, IonCol} from '@ionic/react';
import { FormData, isMobile } from '../../helpers/utils'
import { saveTransfers, saveRollovers, saveContribution, saveCheckout } from '../../helpers/calloutHelpers';
import { SessionApp } from '../../helpers/sessionInterfaces';
import Transfers from '../Transfers';
import Rollovers from '../Rollovers';
import Contribution from '../Contribution';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { useForm, UseFormMethods} from 'react-hook-form';
import Checkout from '../Checkout';
import { isPlatform } from '@ionic/core';

interface FundingOptionsPage extends SessionApp {
    updateLastActivity: Function,
    formData?: {
        data?: {
            transfers?: Array<SubForms.transfer>,
            rollovers?: Array<SubForms.rollover>,
            contributionData?: { contribution: Forms.contributionForm, applicant: { fifty_or_older: boolean } },
            investmentDetails: { has_initial_investment: boolean, investment_amount: number, initial_subscription_amount: number, investment_type: string, checkout_validation_bypass: boolean},
            feeArrangement: { fee_schedule: string, payment_method: string }
        }
    },
    account_type: string,
    is401kApplication: boolean,
    onePathParameters: onePathParameters,
    setShowSpinner: Function,
}

type TransferTypes = {
    isInKindTransfer?: boolean
}

type FundingOptionsContextType = {
    transferTypes: TransferTypes[],
    setTransferTypes: React.Dispatch<React.SetStateAction<TransferTypes[]>>
    totalFundingBelowRequiredFunding: boolean | undefined
    setTotalFundingBelowRequiredFunding: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

/**
 * I would suggest we either commit to using contextx to supplement the forms for sharing state between
 * the children and parents or we go all in on form state
 * 
 * I think making this one massive form might be better so we're not mixing contexts.
 * We can swap the sumit refs we're using and have one useForm at the parent level and have everything below subscribe to useFormContexts
 * 
 * We can register some fields that we want to persists regardless of if it's being rendered
 * 
 * I think shorter fix would be getting rid of these extra useStates and using a reducer+context or local redux store at this level to share state
 * 
 * I don't want to keep adding useStates to build out additional shared logic here.
 */
export const FundingOptionsContext = createContext<FundingOptionsContextType>({
    transferTypes: [],
    setTransferTypes: () => {},
    totalFundingBelowRequiredFunding: false,
    setTotalFundingBelowRequiredFunding: () => {},
});

const FundingOptions: React.FC<FundingOptionsPage> = ({ sessionId, updateMenuItem, formRef, formData, account_type, is401kApplication, updateLastActivity, onePathParameters, handleNavigation, setErrorMessage, setShowErrorToast, setShowSpinner }) => {
    const [fundingAmounts, setFundingAmounts] = useState<{ transfersAmount?: number, rolloversAmount?: number, contributionAmount?: number }>();
    const [transferTypes, setTransferTypes] = useState<TransferTypes[]>([])
    const [totalFundingBelowRequiredFunding, setTotalFundingBelowRequiredFunding] = useState<boolean>()
    const [requiredFundingAmount, setRequiredFundingAmount] = useState<number>(0)
    const [ checkoutBypass, setCheckoutBypass ] = useState<boolean>(false);
    const transferFormSubmitRef = useRef<UseFormMethods>()
    const rolloverFormSubmitRef = useRef<UseFormMethods>()
    const confirmationFormSubmitRef = useRef<UseFormMethods>()
    const checkoutFormSubmitRef = useRef<UseFormMethods>()
    //TODO: update the types in the Transfers, Rollovers, Contribution, and Checkout component

    const form = useForm<{ totalFundingAmount: number }>({
        mode: 'onChange',
        defaultValues: { totalFundingAmount: 0 }
    });
    
    useEffect(() => {
        const transfers = formData?.data?.transfers
        
        if (transfers && transfers?.length > 0) {
            for (var i = 0; i < transfers?.length - 1; i++) {
                if (transfers[i]?.transfer_type === 'In-Kind Transfer') {
                    setTransferTypes(prevState => [{isInKindTransfer: true}, ...prevState]);
                } else {
                    setTransferTypes(prevState => [{isInKindTransfer: false}, ...prevState]);
                };
            };
        };
        
        const checkoutValidationBypass = formData?.data?.investmentDetails?.checkout_validation_bypass
        if (checkoutValidationBypass) {
            setCheckoutBypass(true);
        }
        
    }, [formData])

    let formsValid = true
    let promiseArr: Promise<API.AppSession | undefined>[] = []

    const updateTransfersFundingAmount = (amount: number): void => {
        setFundingAmounts(fundingAmounts => (fundingAmounts?.transfersAmount !== amount ?  { ...fundingAmounts, transfersAmount: amount } : fundingAmounts))
    }

    const updateRolloversFundingAmount = (amount: number): void => {
        setFundingAmounts(fundingAmounts => (fundingAmounts?.rolloversAmount !== amount ?  { ...fundingAmounts, rolloversAmount: amount } : fundingAmounts))
    }

    const updateContributionFundingAmount = (amount: number): void => {
        setFundingAmounts(fundingAmounts => (fundingAmounts?.contributionAmount !== amount ?  { ...fundingAmounts, contributionAmount: amount } : fundingAmounts))
    }

    useEffect(() => {
        let totalFunding = 0
        if (fundingAmounts?.transfersAmount) {
            totalFunding += fundingAmounts?.transfersAmount
        }
        if (fundingAmounts?.rolloversAmount) {
            totalFunding += fundingAmounts?.rolloversAmount
        }
        if (fundingAmounts?.contributionAmount) {
            totalFunding += fundingAmounts?.contributionAmount
        }

        form.clearErrors('totalFundingAmount')
        form.setValue('totalFundingAmount', totalFunding)
    }, [fundingAmounts,])

    const onSubmit = async () => {
        setTotalFundingBelowRequiredFunding(false)
        
        formsValid = true
        if (allowTransfers()) {
            await transferFormSubmitRef.current?.handleSubmit(onTransferValid(), onInvalid(transferFormSubmitRef))()
        }
        if (allowRolloversAndContributions()) {
            await rolloverFormSubmitRef.current?.handleSubmit(onRolloverValid(), onInvalid(rolloverFormSubmitRef))()
            await confirmationFormSubmitRef.current?.handleSubmit(onContributionValid(), onInvalid(confirmationFormSubmitRef))()
        }

        if (showCheckout()) {
            await checkoutFormSubmitRef.current?.handleSubmit(onCheckoutValid(), onInvalid(checkoutFormSubmitRef))()
        }

        await Promise.all(promiseArr);

        if (formsValid) {
            updateMenuItem('is_funding_options_page_valid', true, sessionId)
            setShowSpinner(false)
            handleNavigation({ status: 'Success' })
        }
        else {
            handleNavigation({ status: 'Error' });
        }
    }

    const onError = () => {
        handleNavigation({ status: 'Error' })
    }

    const onTransferValid = () => (payload: FormData) => {
        //NOTE: Need to pass an empty array to ensure that transactions are deleted if removed in the UI
        if (Object.keys(payload).length === 0) {
            payload.transfers = []
        }

        const saveTransfersPromise = saveTransfers(sessionId, payload, updateLastActivity);
        if (saveTransfersPromise) {
            promiseArr.push(saveTransfersPromise)
        }
    }

    const onRolloverValid = () => (payload: FormData) => {
        //NOTE: Need to pass an empty array to ensure that rollovers are deleted if removed in the UI
        if (Object.keys(payload).length === 0) {
            payload.rollovers = []
        }

        const saveRolloversPromise = saveRollovers(sessionId, payload, updateLastActivity);
        if (saveRolloversPromise) {
            promiseArr.push(saveRolloversPromise)
        }
    }

    const onContributionValid = () => (payload: FormData) => {
        //NOTE: Need to pass an empty object to ensure that the contribution is deleted if removed in the UI
        if (Object.keys(payload).length === 0) {
            payload.contribution = {}
        }

        const saveContributionPromise = saveContribution(sessionId, payload, updateLastActivity)
        if (saveContributionPromise) {
            promiseArr.push(saveContributionPromise)
        }
    }

    const onCheckoutValid = () => (payload: FormData) => {
        payload.checkout_validation_bypass = checkoutBypass;
        if (checkoutBypass) {
            form.clearErrors();
        }
        const saveInvestmentAmountPromise = saveCheckout(sessionId, payload, updateLastActivity)
        if (saveInvestmentAmountPromise) {
            promiseArr.push(saveInvestmentAmountPromise)
        }
    }

    const onInvalid = (ref: MutableRefObject<UseFormMethods|undefined>) => () => {
        if (ref.current?.formState?.isDirty) {
            setErrorMessage('') ;
            setShowErrorToast(true);
        }
        formsValid = false;
    }

    const allowTransfers = () => {
        return !is401kApplication;
    }

    const allowRolloversAndContributions = () => {
        return !account_type.includes('Inherited')
    }

    const getRolloverText = () => {
        return is401kApplication ? 'For a rollover from an existing plan, you will receive rollover instructions following the account opening process that you can give to the plan provider.' : 'For a rollover from an employer plan, you will receive rollover instructions following the account opening process that you can give to the plan provider.'
    }

    const getInstructionText = () => {
        return !account_type.includes('Inherited')
            ? 'Click any of the button(s) below to provide the details for these funding options:'
            : 'Click Add Transfer to provide the details for an IRA to IRA Transfer.'
    }

    const showCheckout = () => {
        const investmentDetails = formData?.data?.investmentDetails
        
        if (investmentDetails && (investmentDetails.investment_amount || investmentDetails.initial_subscription_amount) && onePathParameters?.custom_ui_state !== 'Hide Funding Validation' && onePathParameters?.custom_ui_state !== 'Custom Fee Schedule') {
            return true;
        };
        return false;
    }

    return (
        <FundingOptionsContext.Provider
            value={{
                transferTypes,
                setTransferTypes,
                totalFundingBelowRequiredFunding,
                setTotalFundingBelowRequiredFunding
            }}
        >
            <IonGrid>
                <form ref={formRef} onSubmit={form.handleSubmit(onSubmit, onError)}>
                    <IonRow>
                        <IonCol size={requiredFundingAmount === 0 || isMobile(isPlatform) ? '12' : '9'}>
                            <IonRow className='well'>
                                <IonCol>
                                    <strong>Please provide information on how you plan to fund your account.</strong> {getInstructionText()}
                                    <ul data-type="circle">
                                        {allowTransfers() && !account_type.includes('Inherited') && (
                                            <li>For an IRA-to-IRA transfer, Equity Trust will be reaching out to your current IRA custodian to initiate the transfer once we receive the account details.</li>
                                        )}
                                        {allowRolloversAndContributions() && (
                                            <>
                                                <li>{getRolloverText()}</li>
                                                <li>For a contribution to the account, please have your bank account information readily available.</li>
                                            </>
                                        )}
                                    </ul>
                                </IonCol>
                            </IonRow>
                            {showCheckout() && isMobile(isPlatform) && (
                                <IonRow>
                                    <Checkout form={form} formData={formData?.data} submitRef={checkoutFormSubmitRef} colSize='12' requiredFundingAmount={requiredFundingAmount} setRequiredFundingAmount={setRequiredFundingAmount} onePathParameters={onePathParameters} checkoutBypass={checkoutBypass} setCheckoutBypass={setCheckoutBypass} />
                                </IonRow>
                            )}
                            {allowTransfers() && (
                                <Transfers formData={formData?.data} submitRef={transferFormSubmitRef} updateFundingAmount={updateTransfersFundingAmount} requiredFunding={requiredFundingAmount} showRequiredFundingOption={showCheckout()}/>
                            )}
                            {allowRolloversAndContributions() && (
                                <>
                                    <Rollovers formData={formData?.data} submitRef={rolloverFormSubmitRef} updateFundingAmount={updateRolloversFundingAmount} />
                                    <Contribution formData={formData?.data?.contributionData} submitRef={confirmationFormSubmitRef} updateFundingAmount={updateContributionFundingAmount} />
                                </>
                            )}
                        </IonCol>
                        {showCheckout() && !isMobile(isPlatform) && (
                            <Checkout form={form} formData={formData?.data} submitRef={checkoutFormSubmitRef} colSize='3' requiredFundingAmount={requiredFundingAmount} setRequiredFundingAmount={setRequiredFundingAmount} onePathParameters={onePathParameters} checkoutBypass={checkoutBypass} setCheckoutBypass={setCheckoutBypass} />
                        )}
                    </IonRow>
                </form>
            </IonGrid>
        </FundingOptionsContext.Provider>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        account_type: state.welcomePage.account_type,
        is401kApplication: state.menu.menuParams.is401kApplication,
        onePathParameters: state.onePathParams
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        updateLastActivity: (lastActive: number) => dispatch(actions.updateLastActivity(lastActive)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FundingOptions);
import React, { useCallback, useEffect, useState } from 'react';
import { IonPage, IonContent, IonRow, IonCol, IonButton, IonLoading, IonIcon, IonToast, isPlatform, IonSplitPane } from '@ionic/react';
import { handleDocusignReturn, downloadPenSignDocs } from '../helpers/calloutHelpers'
import { useParams, useLocation } from 'react-router-dom';
import { chevronBackCircleOutline, chevronForwardCircleOutline } from 'ionicons/icons';
import * as actions from '../store/actions';
import { connect } from 'react-redux';
import Header from '../components/Header';

const DocusignReturn: React.FC<{ setOnePathParameters: Function, initSession: Function, startSession: Function }> = ({ setOnePathParameters, initSession, startSession }) => {
    const SIGNING_COMPLETE = 'signing_complete';
    const ID_CHECK_FAILED = 'id_check_failed';
    const { sessionId } = useParams<{ sessionId: string, event: string }>();
    const location = useLocation();
    const [showSpinner, setShowSpinner] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [showPrintApplication, setShowPrintApplication] = useState(false);
    const searchParams = new URLSearchParams(location?.search);
    const DOCUSIGN_RESULT = searchParams?.get('event') || '' 

    const [docusignState, setDocusignState] = useState({
        docusignAttempts: 0,
        docusignUrl: '',
        accountType: '',
    });
    const [downloadUrl, setDownloadUrl] = useState('');

    useEffect(() => {
        if (sessionId && sessionId !== '') {
            // if (false) {
            setShowSpinner(true);
            initSession(sessionId);
            // updateActivityCallout(sessionId)?.then((appSession) => {
            //     if (appSession && appSession.heroku_token && appSession.last_active && appSession.session_length) {
            //         startSession({
            //             herokuToken: appSession.heroku_token,
            //             lastActive: appSession.last_active,
            //             sessionLength: appSession.session_length
            //         })
            //     } else {
            //         console.log('session may have expired')
            //     }
            // });
            // const searchParams = new URLSearchParams(location.search);
            // const DOCUSIGN_RESULT = searchParams.get('event') || '{}';
            // setDocusignState(prevState => { return { ...prevState, DOCUSIGN_RESULT: DOCUSIGN_RESULT } });

            // getOnePathDataFromSession(sessionId).then(res => res.json()).then((data: any) => {
            //     if (data.data && Object.keys(data.data).length > 0) {
            //         setOnePathParameters({ ...data.data, hasOnePathParams: true });
            //     }
            // }).catch((e) => {
            //     console.log(e)
            //     setErrorMsg('Error preparing the final application steps.');
            //     setShowSpinner(false);
            // })
            handleDocusignReturn(sessionId, DOCUSIGN_RESULT).then((response: any) => {
                setDocusignState(prevState => { return { ...prevState, docusignAttempts: response.docusignAttempts, docusignUrl: response.docusignUrl, accountType: response.accountType } }); 
            }).catch(function () {
                setErrorMsg('Error preparing the final application steps.');
                setShowSpinner(false);
            })

            // ReactGa.pageview('Docusign Return', [], 'Docusign Return');
        // }
        }
    }, [sessionId, initSession, DOCUSIGN_RESULT]) 

    const downloadPenSignDocsAfterEsignSuccess = useCallback( async (DOCUSIGN_RESULT: string) => {
        try{
            const response = await downloadPenSignDocs(sessionId, DOCUSIGN_RESULT)
            setDownloadUrl(response||'')
            setShowSpinner(false);
        }catch(e){
            setErrorMsg('Unable to download the signature document.');
            setShowSpinner(false);
        }
    }, [sessionId, setDownloadUrl])

    const shouldDownloadPenSignDocOnPageLoad = useCallback(() => {
        const { accountType, docusignAttempts } = docusignState;
        return !accountType.includes('401') && (DOCUSIGN_RESULT === SIGNING_COMPLETE || (DOCUSIGN_RESULT === ID_CHECK_FAILED && docusignAttempts >= 2));
    },[docusignState, DOCUSIGN_RESULT])

    useEffect(() => {
        const { docusignAttempts } = docusignState;
        if (shouldDownloadPenSignDocOnPageLoad()) {
            downloadPenSignDocsAfterEsignSuccess(DOCUSIGN_RESULT);
        }
        else if (DOCUSIGN_RESULT === ID_CHECK_FAILED && docusignAttempts === 1) {
            setShowSpinner(false);
        }
        else {
            setShowSpinner(false);
        }
    },[docusignState, DOCUSIGN_RESULT, downloadPenSignDocsAfterEsignSuccess, shouldDownloadPenSignDocOnPageLoad])

    const downloadFullSigningDoc = (docusignState: any) => {
        const searchParams = new URLSearchParams(location.search);
        const DOCUSIGN_RESULT = searchParams.get('event') || '{}';
        setShowSpinner(true);

        downloadPenSignDocs(sessionId, DOCUSIGN_RESULT).then((response: any) => {
            const link = document.createElement('a');
            link.href = response;
            link.setAttribute('download', 'Midland_Application_Documents.pdf');
            document.body.appendChild(link);
            link.click();
            setShowSpinner(false);
        })
    } 

    const toggleShowPrintApplication = () => {
        if (showPrintApplication) {
            setShowPrintApplication(false);
        }
        else {
            setShowPrintApplication(true);
        }
    }

    return (
        <React.Fragment>
            <Header />
            <IonSplitPane contentId="main">
                <IonPage id='main' className={isPlatform('android') ? 'android-fit-content ion-justify-content-around' : 'ion-padding-top'}>
                    <IonContent className='ion-padding'>
                        <IonToast color="danger shade" position="top" isOpen={errorMsg !== ''} onDidDismiss={() => { setErrorMsg('') }} message={errorMsg} buttons={[
                            {
                                icon: 'close',
                                role: 'cancel'
                            }
                        ]} />

                        {DOCUSIGN_RESULT === SIGNING_COMPLETE && docusignState.accountType.includes('401') === false &&
                        
                            <>
                                <h3 color='primary'>
                                    ONLINE APPLICATION: IMPORTANT FINAL STEPS
                                </h3>
                                <p>
                                    <br />
                                    <b>E-SIGNATURE COMPLETE:</b> Thank you for completing the electronic signing of your new account documents! Equity Trust's new account team will review your application and reach out to you within 1 business day.
                                </p>
                                <p>
                                    <b>PEN SIGNATURE PACKET PENDING:</b> There are <u>certain documents</u> that require to be <u>pen signed and returned to us</u> by fax, scan or mail. These documents include your account signature card as well as any applicable transfer forms, power of attorney, etc. Please click the button below to download and print the applicable documents for your account.
                                </p>
                            </>
                        }
                        {DOCUSIGN_RESULT === SIGNING_COMPLETE && docusignState.accountType.includes('401') &&
                            <>
                                <h3 color='primary'>
                                    ONLINE APPLICATION: COMPLETED
                                </h3>
                                <p>
                                    <br />
                                    <b>E-SIGNATURE COMPLETE:</b> Thank you for completing the electronic signing of your new account documents! Equity Trust's new account team will review your application and reach out to you within 1 business day.
                                </p>
                                <p>
                                    Please visit our website at <a href="https://midlandtrust.com">https://midlandtrust.com</a>
                                </p>
                            </>
                        }
                        {DOCUSIGN_RESULT === ID_CHECK_FAILED && docusignState.docusignAttempts === 1 &&
                            <>
                                <b>Whoops!  Equity Trust was unable to verify your identity.</b>
                                <br />
                                <br />

                                As a financial institution, Equity Trust is required to verify your identity.  During the application process, you were unable to answer some (or all) of the identity verification questions.  Equity Trust realizes that some of the questions can be challenging from time to time, so Equity Trust will allow you to try again one additional time.
                                <br />
                                <br />
                                If you would like to attempt to verify your identity a second time through the verification questions, please click the Proceed to E-Signature button. Or, if you would like to return to the start of the application to update any information related to the verification questions, such as your address, please click the Go Back to Application button.
                                <p>
                                    <IonButton color="primary" routerLink='/Welcome'>Go Back To Application
                                        <IonIcon icon={chevronBackCircleOutline} slot='start' />
                                    </IonButton>
                                    <IonButton color="primary" href={docusignState.docusignUrl}>Proceed to E-Signature
                                        <IonIcon icon={chevronForwardCircleOutline} slot='end' />
                                    </IonButton>
                                </p>
                                {docusignState.accountType.includes('401') === false &&
                                    <>
                                        <p>
                                            <IonButton fill="outline" onClick={toggleShowPrintApplication}>Print Application</IonButton>
                                        </p>
                                        {showPrintApplication &&
                                            <>
                                                You may also skip the verification questions / electronic submission and print your application instead by clicking the download button below:

                                                <p>
                                                    <IonButton color="primary" onClick={() => downloadFullSigningDoc(docusignState)}>Download My Signature Document</IonButton>
                                                </p>

                                                If you decide to skip the verification questions, be sure to physically sign your application where needed and return to Equity Trust with a copy of a valid government issued photo ID as well.  The application, ID, and IRA statement (if transferring funds from another custodian) can all be uploaded securely here:
                                                <a href="https://www.midlandtrust.com/secure-upload">https://www.midlandtrust.com/secure-upload/</a>
                                                <br />
                                                <br />
                                                You may also fax your application to 239-466-5496 or mail it to:                    <br />
                                                PO Box 07520 <br /> Fort Myers, FL 33919.
                                                <br />
                                                <br />
                                                Thank you for your interest in opening an account with Equity Trust.  Once received, Equity Trust's new account team will review your application and a knowledgeable dedicated representative will reach out to you.
                                                We look forward to a lasting relationship. Feel free to contact Equity Trust at 866-839-0429 if you need anything at all.
                                                <br />
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                        {DOCUSIGN_RESULT === ID_CHECK_FAILED && docusignState.docusignAttempts >= 2 &&
                            <>
                                <b>Whoops!  Equity Trust was unable to verify your identity.</b>
                                <br />
                                <br />
                                {docusignState.accountType.includes('401') === false &&
                                    <>
                                        As a financial institution, Equity Trust is required to verify your identity.  During the application process, you were unable to answer some (or all) of the identity verification questions.

                                        Because you were unable to answer the identity verification questions, you will need to print your application by clicking the download button below.
                                        <br /><br />
                                        Be sure to physically sign your application where needed and return to Equity Trust with a copy of a valid government issued photo ID as well.  The application, ID, and IRA statement (if transferring funds from another custodian) can all be uploaded securely at
                                        <br />
                                        <a href="https://www.midlandtrust.com/secure-upload/">https://www.midlandtrust.com/secure-upload/</a>
                                        <br />
                                        <br />
                                        You may also fax your application to 239-466-5496 or mail it to:
                                        <br />PO Box 07520
                                        <br />  Fort Myers, FL 33919.
                                        <br /><br />
                                        <p>
                                            Thank you for your interest in opening an account with Equity Trust.  Once received, Equity Trust's new account team will review your application and a knowledgeable dedicated representative will reach out to you.  We look forward to a lasting relationship. Feel free to contact Equity Trust at 866-839-0429 if you need anything at all.
                                        </p>
                                    </>
                                }
                                {docusignState.accountType.includes('401') &&
                                    <>
                                        <p>
                                            As a financial institution, Equity Trust is required to verify your identity. During the application process, you were unable to answer some (or all) of the identifying verification questions. Please contact our new accounts team for assistance completing your application.
                                        </p>
                                        <p>
                                            Please call 239.333.1032 Option 2.
                                        </p>
                                    </>
                                }
                            </>
                        }
                        {(DOCUSIGN_RESULT === 'session_timeout' || DOCUSIGN_RESULT.includes('ttl_expired')) &&
                            <>
                                <h2>Whoops! We see that the session has timed out.</h2>
                                <IonRow className='ion-padding-vertical'>
                                    <IonCol>
                                        Your signing session has timed out. Click Go Back To Application below to pick up where you left off.
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton color="primary" routerLink='/Welcome'>Go Back To Application
                                            <IonIcon icon={chevronBackCircleOutline} slot='start' />
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {(DOCUSIGN_RESULT.includes('decline') || DOCUSIGN_RESULT.includes('cancel')) &&
                            <>
                                <h2>Whoops - something went wrong!</h2>
                                <IonRow className='ion-padding-top'>
                                    <IonCol>
                                        You have cancelled or declined providing an electronic signature for this application. Our office will be contacting you within 1 business day to provide alternate signature options.
                                    </IonCol>
                                </IonRow>
                                <IonRow className='ion-padding-bottom'>
                                    <IonCol>
                                        You can also click Go Back to Application below to return to the application and E-Sign the application documents.
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton color="primary" routerLink='/Welcome'>Go Back To Application
                                            <IonIcon icon={chevronBackCircleOutline} slot='start' />
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        <IonLoading isOpen={showSpinner} message={'Loading...'} spinner="lines"></IonLoading>
                        {downloadUrl !== '' && (DOCUSIGN_RESULT === SIGNING_COMPLETE || (DOCUSIGN_RESULT === ID_CHECK_FAILED && docusignState.docusignAttempts >= 2)) &&
                            <IonRow>
                                <IonCol>
                                    <a className="btn btn-primary" href={downloadUrl} download='Midland_Application_Documents.pdf'>
                                        <IonButton>Download My Signature Document</IonButton>
                                    </a>
                                </IonCol>
                            </IonRow>
                        }
                    </IonContent>
                </IonPage>
            </IonSplitPane>
        </React.Fragment>
    )
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        initSession: (sessionId: string) => dispatch(actions.initSession(sessionId)),
        startSession: (payload: any) => dispatch(actions.startSession(payload)),
        setOnePathParameters: (payload: onePathParameters) => dispatch(actions.setOnepathParams(payload))
    }
}

export default connect(null, mapDispatchToProps)(DocusignReturn);
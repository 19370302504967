import React from 'react';
import {useParams} from 'react-router'
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonSplitPane, IonButton } from '@ionic/react';
import { warning } from 'ionicons/icons';
import Header from '../components/Header';

export const BASE_PATH = 'applicationError'

const ErrorPage: React.FC = () => {
    return (
        <React.Fragment>
            <Header/>
            <IonSplitPane contentId="main" className="top-space">
            <IonPage id='main' className='ion-padding-top'>
                <IonContent className='ion-padding'>
                    <IonGrid className='ion-padding'>
                        <IonRow className='well'>
                            <IonCol>
                                <div>
                                    <IonIcon icon={warning} slot='start' ariaLabel='Warning' className='warning-icon' />  &nbsp;
                                    <strong className='warning-text'>
                                    <RenderMessage/>
                                    </strong>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        </IonSplitPane>
        </React.Fragment>

    )
}

type OnePathParams = { error: 'one-path'; extra?: ViewMode | 'isp-invite'}
type UrlParams = { error: 'default' } | OnePathParams

const RenderMessage: React.FC = () => {
    console.log('render message called')
    const params = useParams<UrlParams>();
    console.log(params)
    if(params?.error === 'one-path'){
        return <OnePathLinkChanged extra={params.extra}/>
    }

    return <ResumeLinkChanged/>
}

const OnePathLinkChanged: React.FC<{extra?: OnePathParams['extra']}> = ({extra}) => {
     // need to move this to a central location
    const closeIframe = () => {
        // window.parent.postMessage("close-iframe", document.referrer);
        console.info(document.referrer)
        window.parent.postMessage("close-iframe", document.referrer);
        // window.parent.dispatchEvent(new Event('close-iframe'));
    }

    if(extra === 'isp'){
        return <>
            Oops! The link is no longer valid.
            <p className='ion-padding-left'>
                The related application or a part of it is missing.
            </p>
            <IonButton style={{width: '90px'}} color='primary' onClick={closeIframe}>Exit</IonButton>
        </>
    }

    return <>
        Oops! The link is no longer valid.
        <p className='ion-padding-left'>
            The opportunity token is incorrect or may no longer be valid.
        </p>
        { extra === 'isp-invite' && <IonButton style={{width: '90px'}} color='primary' onClick={closeIframe}>Exit</IonButton> }
    </>
}

const ResumeLinkChanged: React.FC = () => (
    <>
        Oops! We have updated our online application and the resume link you used is no longer valid.
        <p className='ion-padding-left'>
            Please reach out to the Equity Trust New Accounts Team <a href="tel:239-333-1032" className='error-page-contact' rel='noopener noreferrer' target='_blank' aria-label='link to call (239) 033 1032'> (239) 333-1032
            </a> for a new resume link.
        </p>
    </>
)

export const getErrorPath = (errorParams: UrlParams) => {
    const {error} = errorParams;
    if(error === 'one-path' && errorParams.extra){
        const {extra} = errorParams;
        return `/${BASE_PATH}/${error}/${extra}`
    }
    
    return `/${BASE_PATH}/${error}`
}

export default ErrorPage;
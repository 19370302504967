import { sharedParameters } from '../../helpers/utils';
import { SharedParamsActions } from '../actions/index';

export const initialState : sharedParameters = {
    initial_investment_type: '',
    applicant_email: ''
}

const sharedParamsReducer = (state = initialState, action: SharedParamsActions) => {
    switch (action.type) {
        case 'SET_INITIAL_INVESTMENT_TYPE': 
            return {
                ...state,
                initial_investment_type: action.initialInvestmentType
            };
        default:
            return state;
    }
}

export default sharedParamsReducer; 
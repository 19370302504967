import React, { ComponentPropsWithRef, useState } from 'react'
import { connect } from 'react-redux';
import WizardButtons from './WizardButtons'
import * as actions from '../store/actions';
import { saveApplication, sendResumeSessionEmail } from '../helpers/calloutHelpers';
import SaveAndExitModal from '../components/SaveAndExitModal';
import './WizardButtons.css'

type WizardButtonsSavePageProps = Omit<ComponentPropsWithRef<typeof WizardButtons>, 'handleSave'> & {
    sessionId: string,
    setAndShowErrorMessage: (payload: string) => void,
    setAndShowSuccessMessage : (payload: string) => void,
    setShowSpinner: (payload: boolean) => void,
}

const WizardButtonsSavePage: React.FC<WizardButtonsSavePageProps> = (props) => {
    const [showModal, setShowModal] = useState(false) // 'reg' or 'isp' [regular or iframe

    const { setAndShowErrorMessage, setAndShowSuccessMessage, setShowSpinner, sessionId, viewMode,
        ...rest
    } = props

    const saveAndExit = async () => {
        const isIspMode = (viewMode === 'isp')

        await handleSaveClick(isIspMode);
        if(isIspMode)
        {
            window.parent.postMessage("close-iframe", document.referrer);
        }
    }

    const handleSaveClick = async (skipEmail: boolean = false) => {
        if (!sessionId) {
            setAndShowErrorMessage('Error saving application.');
            return;
        }
        setShowSpinner(true);
        try{
            await saveApplication(sessionId)
            if(!skipEmail){
                await sendResumeSessionEmail({sessionId})
            }
            setAndShowSuccessMessage('Application saved successfully.');
            setShowModal(true);
        }catch(error){
            setAndShowErrorMessage('Error saving application.');
        }
        setShowSpinner(false);
    }
    return <>
        <SaveAndExitModal closeModal={()=>setShowModal(false)} showModal={showModal}/>
        <WizardButtons handleSave={saveAndExit} viewMode={props.viewMode} {...rest}/>
    </>
    
}

const mapDispatchToProps = (dispatch: Function) =>{
    return { 
        setAndShowSuccessMessage: (payload: string) => dispatch(actions.setAndShowSuccessMessage(payload)),
        setAndShowErrorMessage: (payload: string) => dispatch(actions.setAndShowErrorMessage(payload)),
        setShowSpinner: (payload: boolean) => dispatch(actions.setShowSpinner(payload))
    }
}

const mapStateToProps = (state: StoreState) => {
  return {
    sessionId: state.session.sessionId,
    menuSections: state.menu.menuSections,
    viewMode: state.onePathParams.viewMode
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardButtonsSavePage);
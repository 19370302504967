import React, { useEffect, useState } from 'react';
import { IonModal, IonButton, IonContent, IonTitle, IonGrid, IonRow } from '@ionic/react';
import { updateActivityCallout } from '../helpers/sessionManagement'
import './TimeoutModal.css';
import { connect } from 'react-redux';
import * as actions from '../store/actions';

const COUNT_DOWN = 10

type TimeoutMOdalProps = {
    resetSession: Function, 
    setCountdownIntervalRef: Function, 
    updateLastActivity: Function, 
    showTimeoutModal: Function, 
    sessionId: string, 
    herokuToken: string | undefined, 
    showModal: boolean, 
    sessionIntervalHandler: any
}

const TimeOutModal: React.FC<TimeoutMOdalProps> = ({ resetSession, setCountdownIntervalRef, updateLastActivity, showTimeoutModal, sessionId, herokuToken, showModal, sessionIntervalHandler}) => {
    const [countDown, setCountDown] = useState(COUNT_DOWN);

    useEffect(()=>{
        if(showModal){
            var countDownTest = countDown
            let countDownInterval = setInterval(()=>{
                if(countDownTest === 0){
                    resetSessionAndNavigateToResume(countDownInterval)
                    return
                }
                --countDownTest
                setCountDown(countDownTest);
            },1000)
            setCountdownIntervalRef( countDownInterval );
            return ()=>{ clearInterval( countDownInterval ); setCountdownIntervalRef( undefined );}
        }
    },[showModal])

    const resetSessionAndNavigateToResume = (countDownInterval?:NodeJS.Timeout)=>{
        if(countDownInterval){
            clearInterval(countDownInterval)
        }
        resetSession();
        window.location.href = `/resume/${herokuToken}`
    }

    const contiueApp = ()=>{
        if(sessionIntervalHandler){
            clearInterval(sessionIntervalHandler)
            setCountDown(COUNT_DOWN)
        }

        updateActivityCallout(sessionId)?.then((appSession?: API.AppSession)=>{
            if(appSession && appSession.last_active){
                updateLastActivity(appSession.last_active);
                showTimeoutModal(false);
            }else{
                resetSessionAndNavigateToResume(sessionIntervalHandler);
            }
        })
    }

    return (   
    <IonModal isOpen={showModal} backdropDismiss={false} cssClass='my-custom-class'>
        <IonContent className='ion-padding'>
            <IonGrid className='timeout-grid'>
                <IonRow>
                    <IonRow><strong>Session will end in {countDown} second(s)</strong></IonRow>
                </IonRow>
                <IonRow>
                    <IonButton onClick={contiueApp}>Continue</IonButton>
                    <IonButton onClick={()=>{resetSessionAndNavigateToResume(sessionIntervalHandler)}}>Close Application</IonButton>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonModal>
  );
};

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        herokuToken: state.session.herokuToken,
        showModal: state.session.showModal,
        sessionIntervalHandler: state.session.sessionIntervalHandler
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        resetSession: () => dispatch(actions.resetSession()),
        setCountdownIntervalRef: (payload: any) => dispatch(actions.setCountdownIntervalRef(payload)),
        updateLastActivity: (lastActive: number) => dispatch(actions.updateLastActivity(lastActive)), 
        showTimeoutModal: (showModal: boolean) => dispatch(actions.showTimeoutModal(showModal))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeOutModal);

import { State } from '../../helpers/sessionManagement';
import { SessionActions } from '../actions/index';

interface SessionState extends State {
    sessionTimeout: any
}

export const initialSessionState : SessionState = {
    sessionId:'', 
    showModal:false,
    pollingRate: 5000, 
    sessionState: 'start',
    sessionTimeout: null,
}

const sessionReducer = (state = initialSessionState, action: SessionActions): SessionState => {
    switch (action.type) {
        case 'RESET_SESSION':
            return initialSessionState; 
        case 'INIT_SESSION': 
            return {
                ...state,
                sessionId: action.sessionId, 
                showModal: false, 
                sessionState: 'start'
            }
        case 'START_SESSION': 
            if (state.sessionState !== 'start') {
                return state;
            }
            return {
                ...state, 
                ...action.payload,
                sessionState: 'running'
            }
        case 'UPDATE_LAST_ACTIVITY': 
            if (state.sessionState !== 'running') {
                return state;
            } 
            return {
                ...state, 
                lastActive: action.lastActive
            }
        case 'SHOW_TIMEOUT_MODAL': 
            if (!action.showModal) {
                return {
                    ...state,
                    showModal: action.showModal, 
                    sessionIntervalHandler: undefined
                }
            }
            return {
                ...state, 
                showModal: action.showModal
            }
        case 'SET_COUNTDOWN_INTERVAL_REF': 
            return {
                ...state,
                sessionIntervalHandler: action.payload
            }
        case 'SET_SESSION_TIMEOUT':
            return {
                ...state,
                sessionTimeout: action.payload
            }
        default:
            return state; 
    }
}

export default sessionReducer; 
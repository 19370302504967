import React, { useState } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { IonCol, IonRow, IonButton } from '@ionic/react';
import { sendESignLink, sendResumeSessionEmail, updateAppStatus } from '../../../helpers/calloutHelpers';
import PenSignDocument, {LoadingCallback} from './PensignDocument'

interface CompleteApplicationProps {
    sessionId: string
    loading: boolean
    hasIncompleteSteps: boolean,
    setErrorMessage: (payload: string) => void,
    setSuccessMessage: (payload: string) => void
}

const CompleteApplication: React.FC<CompleteApplicationProps> = ({sessionId, hasIncompleteSteps, loading, setErrorMessage, setSuccessMessage }) => {
    const [buttonActionRunning, setButtonActionRunning ] = useState(false)

    if(hasIncompleteSteps){
        return null
    }

    const loadingMarkup = (<span>
            <h1>Checking Application</h1>
            <br/>
            Please wait a moment while we check your progress and save your application.
        </span>
    )
                                            
    const completeMarkup = (<span>
            <h1>Application Complete</h1>
            <br/>
            You have the following options to complete the application process:
        </span>
    )
        
    const emailSigningLink = async () => {
        /**
        * Call new magic link email
        */
        loadingCallback(true)
        try{
            await sendESignLink(sessionId)
        }catch(error){
            loadingCallback(false, {callResult: 'error', message: 'Unable to send email'})
            return
        }
        loadingCallback(false, {callResult: 'success', message: 'ESign Email sent', updateStatus: 'Sent for Signing'})
    }

    const sendResumeLink = async () => {
        loadingCallback(true)
        try{
            await sendResumeSessionEmail({sessionId, fromThirdParty: true})
        }catch(error){
            loadingCallback(false, {callResult: 'error', message: 'Unable to send email'})
            return
        }
        loadingCallback(false, {callResult: 'success', message: 'Resume Email sent', updateStatus: 'Sent to Client'})
    }


    const loadingCallback: LoadingCallback = (loading, result) => {
        setButtonActionRunning(loading)
        if(!result){
            return
        }
        if(result.callResult === 'success'){
            setSuccessMessage(result.message)
            // updateStatus(result.updateStatus)
            updateAppStatus(sessionId, result.updateStatus)
            return
        }

        setErrorMessage(result.message)
    }

    return <> 
        <IonRow className='well'>
            <IonCol>
                {loading && loadingMarkup}
                {!loading && completeMarkup}
                {!loading && <IonRow>
                    <IonCol class='ion-button-list-spacing'>
                        <IonButton disabled={buttonActionRunning} onClick={emailSigningLink}> Send Docusign Signature Request to Client </IonButton>

                        <IonButton disabled={buttonActionRunning} onClick={sendResumeLink}> Send Online Application to Client </IonButton>
                        
                        <PenSignDocument autoDownload={false} disabled={buttonActionRunning} loadingCallback={loadingCallback}/>
                    </IonCol>
                </IonRow>}
            </IonCol>
        </IonRow>
    </>
}

const mapStateToProps = (state: StoreState) => {
    return {
        loading: state.toastControl.showSpinner,
        sessionId: state.session.sessionId,
    }
}

const mapDispatchToProps = (dispatch: Function)   =>{
    return { 
        setErrorMessage: (payload: string) => dispatch(actions.setAndShowErrorMessage(payload)),
        setSuccessMessage: (payload: string) => dispatch(actions.setAndShowSuccessMessage(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteApplication)
import React, {useEffect, useCallback} from 'react';
import {useForm, Controller, useWatch} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { IonText, IonGrid, IonRow, IonCol, IonItemDivider, IonLabel, IonSelect, IonSelectOption, IonInput, IonCheckbox, IonItem, isPlatform } from '@ionic/react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { states, today, isMobile, formatDateToString, validateDate } from '../../helpers/utils';
import {  convertStringDateToDate, EMAIL_REGEX } from '../../helpers/sharedUtils';
import { saveAppPage, saveWelcomePage} from '../../helpers/calloutHelpers';
import { InitSessionApp } from '../../helpers/sessionInterfaces';
import {connect } from 'react-redux';
import * as actions from '../../store/actions';

interface OwnerInfoPage extends InitSessionApp {
    welcomePageFields: welcomePageParameters,
    formData?: {data?: Forms.applicantIdForm},
    startSession: Function,
    setShowSpinner: Function,
    setWelcomePageFields: (payload: Partial<welcomePageParameters>) => void,
    viewMode?: ViewMode
}
 
const minDOB = `${String(new Date().getMonth()+ 1)}/${String(new Date().getDate())}/${String(new Date().getFullYear()-100)}`;
const phoneTypeOptions = ['Mobile', 'Home', 'Office'];
const OwnerInformation: React.FC<OwnerInfoPage> = ({sessionId, updateMenuItem, formRef, setShowSpinner, is401kApplication, welcomePageFields, setWelcomePageFields, formData, startSession, handleNavigation, setErrorMessage, setShowErrorToast, viewMode}) => {

    const updateContactInfoOnWelcomePage = useCallback((formFields: Forms.applicantIdForm) => {
        setWelcomePageFields({
            first_name: formFields.first_name,
            last_name: formFields.last_name,
            email: formFields.email
        })
    },[setWelcomePageFields])

    const getDefaultValues = ()=>{
        let defaultForm: Forms.applicantIdForm ={
            home_and_mailing_address_different: formData?.data?.home_and_mailing_address_different || false,
            alternate_phone: formData?.data?.alternate_phone || '',
            alternate_phone_type: formData?.data?.alternate_phone_type || '',
            email: formData?.data?.email || '',
            dob: formData?.data?.dob ||null,
            suffix: formData?.data?.suffix || '',
            first_name: formData?.data?.first_name || formData?.data?.first_name || '',
            middle_name: formData?.data?.middle_name || '',
            last_name: formData?.data?.last_name || '',
            has_hsa: formData?.data?.has_hsa || null,
            is_spouse_additional_participant: formData?.data?.is_spouse_additional_participant || false,
            legal_city: formData?.data?.legal_city || '',
            legal_state: formData?.data?.legal_state || '',
            legal_street: formData?.data?.legal_street || '',
            legal_zip: formData?.data?.legal_zip || '',
            mailing_city: formData?.data?.mailing_city ||'',
            mailing_state: formData?.data?.mailing_state || '',
            mailing_street: formData?.data?.mailing_street || '',
            mailing_zip: formData?.data?.mailing_zip || '',
            marital_status: formData?.data?.marital_status || '',
            mothers_maiden_name: formData?.data?.mothers_maiden_name || '',
            original_account_holder: formData?.data?.original_account_holder || '',
            other_type_of_id: formData?.data?.other_type_of_id || '',
            preferred_contact_method: formData?.data?.preferred_contact_method || '',
            primary_phone: formData?.data?.primary_phone || '',
            primary_phone_type: formData?.data?.primary_phone_type || '',
            salutation: formData?.data?.salutation || '',
            spouse_non_spouse: formData?.data?.spouse_non_spouse || '',
            ssn: formData?.data?.ssn || '',
            text_message_opt_in: formData?.data?.text_message_opt_in || 'false',
        }
        return defaultForm
    }

    const {control, handleSubmit, errors, formState, setValue} = useForm<Forms.applicantIdForm>({
        mode: "onChange",
        defaultValues: getDefaultValues()
    });
    
    const home_and_mailing_address_different = useWatch({
        name:'home_and_mailing_address_different',
        control: control
    })
    
    const alternate_phone = useWatch({
        name:'alternate_phone',
        control: control
    })
    
    const primary_phone_type = useWatch({
        name:'primary_phone_type',
        control: control
    })

    const alternate_phone_type = useWatch({
        name:'alternate_phone_type',
        control: control
    })
    
    const marital_status = useWatch({
        name:'marital_status',
        control: control
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { isDirty, submitCount } = formState;
    
    const validateFields = async (formFields:Forms.applicantIdForm) => {
        setShowSpinner(true);
        if (!home_and_mailing_address_different) {
            formFields.mailing_street = formFields.legal_street;
            formFields.mailing_city = formFields.legal_city;
            formFields.mailing_state = formFields.legal_state;
            formFields.mailing_zip = formFields.legal_zip;
        }

        try{
            /**
             * this call might no longer be needed
             * I believe this existed from when we previously not push values up to the server until after OwnerInfo page
             * we now save email/first/last name on the welcome page but they're not saved on the 'welcome page' table
             */
            console.log('saving')
            await saveWelcomePage(sessionId, welcomePageFields)
            await saveAppPage(sessionId, formFields, viewMode, startSession)
            updateMenuItem('is_owner_info_page_valid', true, sessionId);
            updateContactInfoOnWelcomePage(formFields)
            handleNavigation({status: 'Success'});
        }catch(err){
            console.error(err)
            handleNavigation({status: 'Error'});
            return
        }
        setShowSpinner(false);
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = '';
        if ((submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };

    const validateSSN = (val: string) => {
        return (val?.replace(/-/g,'').replace(/_/g, '').length === 9);
    }

    const getAlternatePhoneTypeOptions = () => {
        let altPhoneTypeOptions: string[] = [];
        if (primary_phone_type) {
            altPhoneTypeOptions = phoneTypeOptions.filter((phoneType) => phoneType !== primary_phone_type);
        }

        return altPhoneTypeOptions;
    }

    const onFormInvalid = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        }
        else {
            handleNavigation({status: 'Error'});
        }
    }

    return (
            <form ref={formRef} onSubmit={handleSubmit(validateFields,onFormInvalid)} className='ion-padding'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <IonGrid>
                    <IonRow className="well">
                        <IonCol>
                        Please complete your personal information below. Fields marked with an asterisk are required. Others are optional.
                        </IonCol>
                    </IonRow>
                    {
                        welcomePageFields.account_type?.toUpperCase().includes('INHERITED') && (
                            <>
                                <IonItemDivider>
                                    <strong>
                                        <IonText color='primary'>
                                            Account Type
                                        </IonText>
                                    </strong>
                                </IonItemDivider>
                                <IonRow>
                                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12"> 
                                        <IonLabel>
                                        Original Account Holder (First and Last Name)*
                                        </IonLabel>
                                        <IonItem className={showError('original_account_holder')}>
                                            <Controller name='original_account_holder' control={control} render={({onBlur, name, onChange, value})=>{
                                                return <IonInput name={name} onIonInput={onChange} onBlur={onBlur} value={value} clearInput/>
                                            }}
                                            rules={{required: true, maxLength: 100}}
                                             />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12"> 
                                        <IonLabel>
                                        Relationship to Original Account Holder *
                                        </IonLabel>
                                        <IonItem className={showError('spouse_non_spouse')}>
                                            <Controller name='spouse_non_spouse' control={control} render={({value, name, onBlur, onChange})=>
                                                (<IonSelect name={name} interface='action-sheet' onIonBlur={onBlur} onIonChange={onChange} value={value} interfaceOptions={{animated: false, mode: 'ios'}}>
                                                    <IonSelectOption value='Spouse'>Spouse</IonSelectOption>
                                                    <IonSelectOption value='Non-Spouse'>
                                                        Non-Spouse
                                                    </IonSelectOption>
                                                </IonSelect>)}
                                            rules={{required: true}}/>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        )
                    }
                    <IonItemDivider>
                        <strong>
                            <IonText color='primary'>
                            Identity
                            </IonText>
                        </strong>
                    </IonItemDivider>
                    <IonRow>
                        <IonCol size="1.5" sizeMd="1.5" sizeSm="12" sizeXs="12"> 
                            <IonLabel>
                                Salutation *
                            </IonLabel>
                            <IonItem className={showError('salutation')}>
                                <Controller name='salutation' control={control} render={({value, name, onBlur, onChange}) =>
                                    <IonSelect name={name} onIonBlur={onBlur} onIonChange={onChange} interface='action-sheet' value={value} interfaceOptions={{animated: false, mode: 'ios'}}>
                                        <IonSelectOption value="Mr.">Mr.</IonSelectOption>
                                        <IonSelectOption value="Ms.">Ms.</IonSelectOption>
                                        <IonSelectOption value="Mrs.">Mrs.</IonSelectOption>
                                        <IonSelectOption value="Dr.">Dr.</IonSelectOption>
                                    </IonSelect>
                                    
                                } rules={{required: true}}/>
                            </IonItem>
                        </IonCol>
                        <IonCol size="4.5" sizeMd="4.5" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Legal First Name *
                            </IonLabel>
                            <IonItem className={showError('first_name')}>
                                <Controller defaultValue={''}  name='first_name' control={control} render={ ({value, name, onBlur, onChange}) =>
                                <IonInput name={name} value={value} onIonInput={onChange} onIonBlur={onBlur} placeholder="First Name" clearInput/>} rules={{required: true, maxLength: 50}}/>
                                  {(formState.submitCount > 0 && errors.first_name?.type === 'maxLength')
                                    ? (
                                        <IonText color='danger'>First Name is restricted to 50 characters.</IonText>
                                    ) : null}
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Middle Name / Initial
                            </IonLabel>
                            <IonItem className={showError('middle_name')}>
                                <Controller defaultValue={''}  name='middle_name' control={control} render={ ({value, name, onBlur, onChange}) =>
                                <IonInput name={name} value={value} onIonInput={onChange} onIonBlur={onBlur} placeholder="Middle Name / Initial" clearInput/>} rules={{maxLength: 50}}/>
                                  {(formState.submitCount > 0 && errors.middle_name?.type === 'maxLength')
                                    ? (
                                        <IonText color='danger'>Middle Name is restricted to 50 characters.</IonText>
                                    ) : null}
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Legal Last Name *
                            </IonLabel>
                            <IonItem className={showError('last_name')}>
                                <Controller name='last_name' control={control} render={ ({value, name, onBlur, onChange}) =>
                                    <IonInput name={name} class='item-input' value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder="Last Name" clearInput/>
                                } rules={{required:true, maxLength: 100}}/> 
                                  {(formState.submitCount > 0 && errors.last_name?.type === 'maxLength')
                                    ? (
                                        <IonText color='danger'>Last Name is restricted to 100 characters.</IonText>
                                    ) : null}
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Suffix
                            </IonLabel>
                            <IonItem className={showError('suffix')}>
                                <Controller defaultValue={''}  name='suffix' control={control} render={ ({value, name, onBlur, onChange}) =>
                                <IonInput name={name} value={value} onIonInput={onChange} onIonBlur={onBlur} placeholder="Suffix" clearInput/>} rules={{maxLength: 50}}/>
                                  {(formState.submitCount > 0 && errors.suffix?.type === 'maxLength')
                                    ? (
                                        <IonText color='danger'>Suffix is restricted to 50 characters.</IonText>
                                    ) : null}
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Social Security Number *
                            </IonLabel>
                            <IonItem className={showError('ssn')}>
                                <Controller name='ssn' control={control} render={({name, value})=><InputMask name={name} value={value} className='input-mask' mask='xxx-xx-9999' maskChar='' formatChars={{'9':'[0-9]', 'x':'(\\*|\\d)'}}
                                    onChange={(selected)=>{
                                        let ssn = selected.target.value
                                        setValue('ssn' ,ssn?.replace('-','')?.replace('-',''))
                                    }}
                                />} 
                                rules={{required:true, validate: (val) => validateSSN(val)}}/>
                                  {((formState.submitCount > 0)&&
                                errors.ssn 
                                )
                                ? (
                                    <IonText color='danger'>SSN should be 9 digits</IonText>
                                ) : null}
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Date of Birth *
                            </IonLabel>
                            <IonItem className={showError('dob')}>

                                <Controller name='dob' control={control} render={({value, name})=>
                                        <KeyboardDatePicker
                                        name={name}
                                        className="date-width" disableFuture={true} margin='normal'  placeholder="mm/dd/yyyy" value={convertStringDateToDate(value)}  format="MM/dd/yyyy" KeyboardButtonProps={{'aria-label': 'change-date'}} onChange={(date)=>{
                                            if(date){
                                                setValue('dob', formatDateToString(date))
                                            }
                                        }}/>
                                    } 
                                    rules={{required:true, min: minDOB, max: today, validate: (val) => {
                                        if (typeof val === 'string') {
                                            val = new Date(val);
                                        }
                                        const maxDate = new Date(today);
                                        const minDate = new Date(minDOB); 
                                       
                                        return validateDate(val, minDate, maxDate); 
                                    }}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Marital Status *
                            </IonLabel>
                            <IonItem className={showError('marital_status')}>
                                <Controller name='marital_status' control={control} render={ ({value, name, onBlur, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' onIonChange={onChange} onIonBlur={onBlur} value={value} interfaceOptions={{animated: false, mode: 'ios'}}>
                                        <IonSelectOption value="Single">Single</IonSelectOption>
                                        <IonSelectOption value="Married">Married</IonSelectOption>
                                        <IonSelectOption value="Widowed/Divorced">Widowed/Divorced</IonSelectOption>
                                    </IonSelect>
                                } rules={{required:true}}/>
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>Mother's Maiden Name</IonLabel>
                            <IonItem>
                                <Controller
                                    name='mothers_maiden_name'
                                    control={control}
                                    render={({value, name, onBlur, onChange})=><IonInput name={name} value={value} onIonBlur={onBlur} onIonInput={onChange} clearInput/>}
                                    rules={{maxLength:255}}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    {is401kApplication && marital_status === 'Married' && (
                        <IonRow>
                            <IonCol>
                                <IonLabel>
                                    <strong>
                                        Will your spouse be an additional participant in this plan?
                                    </strong>
                                </IonLabel>
                                <p>
                                <i>Please understand that spousal participation in the plan is reliant on spousal earned income from the employer sponsor.</i>
                                </p>
                                <IonRow>
                                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                        <IonItem className={showError('is_spouse_additional_participant')}>
                                            <Controller name='is_spouse_additional_participant' control={control} rules={{required:true}} render={ ({value, name, onBlur, onChange}) =>
                                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value ? value.toString() : ''} onIonChange={onChange} onIonBlur={onBlur}>
                                                    <IonSelectOption value="true">Yes</IonSelectOption>
                                                    <IonSelectOption value="false">No</IonSelectOption>
                                                </IonSelect>
                                            }/>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    )}
                    <IonItemDivider>
                        <strong>
                            <IonText color='primary'>
                                Contact Information
                            </IonText>
                        </strong>
                    </IonItemDivider>
                    <IonRow>
                        <IonCol>
                            PHYSICAL ADDRESS
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <i>
                            If you currently reside outside of the US, please call our office at (239) 333-1032 for help setting up your IRA.
                            </i>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Physical Street Address *
                            </IonLabel>
                            <IonItem className={showError('legal_street')}> 
                                  <Controller name='legal_street' control={control} render={({value, name, onBlur, onChange})=>
                                      <IonInput name={name} placeholder='Street' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                  } rules={{required:true, maxLength: 255}} />
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                            Physical City *
                            </IonLabel>
                            <IonItem className={showError('legal_city')}>
                                <Controller name='legal_city' control={control}  render={({value, name, onBlur, onChange})=>
                                      <IonInput name={name} placeholder='City' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                  } rules={{required:true, maxLength: 255}} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Physical State * 
                            </IonLabel>
                            <IonItem className={showError('legal_state')}>
                                {isMobile(isPlatform) ? 
                                <Controller name='legal_state' control={control} render={({value, name, onBlur, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{cssClass: 'states-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                        {states.map((state, index) => <IonSelectOption value={state} key={index}>{state}</IonSelectOption>)}
                                    </IonSelect>
                                } rules={{required:true}}/> :
                                  <Controller name='legal_state' control={control} render={({value, name})=>
                                    <Autocomplete componentName={name} value={value} id={name} 
                                    onChange={(event, value)=>{
                                        setValue('legal_state', value)
                                    }} 
                                    options={states} getOptionLabel={option => option} autoSelect={true} freeSolo={true} renderOption={option =>(
                                    <span>{option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'State'};
                                        let innerInputProps = {...params.inputProps, maxLength: "2"};
                                        let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps}; 
                                        return (
                                        <TextField type="text" {...newParams}/>
                                    )}} />} 
                                    rules={{
                                        required: true, validate: (val) => {return /[A-Za-z]{2}/.test(val) && (states.findIndex((state) => state === val?.toUpperCase()) !== -1)}   
                                    }} />
                                }
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Zip *
                            </IonLabel>
                            <IonItem className={showError('legal_zip')}>
                                <Controller name='legal_zip' control={control} as={
                                     <InputMask maskChar='' mask='99999' placeholder='Zip' name='legal_zip' className='input-mask'/>
                                }  rules={{
                                    required: true,
                                    pattern:  /^[0-9]{5}(?:-[0-9]{4})?/
                                }}/>
                                {((formState.submitCount > 0)&&
                                errors.legal_zip 
                                )
                                ? (
                                    <IonText color='danger'>Invalid Zip</IonText>
                                ) : null}
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow class="home-mailing-differ">
                            <Controller
                                name='home_and_mailing_address_different'
                                control={control}
                                render={({value, name})=> <IonCheckbox name={name} checked={value} onIonChange={(event)=>{
                                    setValue('home_and_mailing_address_different', event.detail.checked)
                                }}/>} 
                            />
                            <div>My mailing address is different than my physical address</div>
                    </IonRow>
                    {home_and_mailing_address_different && <React.Fragment>
                        <IonRow>
                            <IonCol>
                                MAILING ADDRESS
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <i>
                                If you currently reside outside of United States, Equity Trust will make all client correspondence electronic.  
                                </i>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel>Mailing Street Address *</IonLabel>
                                <IonItem className={showError('mailing_street')}>
                                    <Controller name='mailing_street' control={control} render={({value, name, onBlur, onChange}) =>
                                        <IonInput name={name} value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='Street'/>
                                    } rules={{required:true, maxLength: 255}}/>
                                </IonItem>
                            </IonCol>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel>Mailing City *</IonLabel>
                                <IonItem className={showError('mailing_city')}>
                                    <Controller name='mailing_city' control={control} render={({value, name, onBlur, onChange}) =>
                                        <IonInput name={name} value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='City'/>
                                    } rules={{required:true, maxLength: 255}}/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel>Mailing State *</IonLabel>
                                <IonItem className={showError('mailing_state')}>
                                {isMobile(isPlatform) ? 
                                    <Controller name='mailing_state' control={control} render={ ({value, name, onBlur, onChange})=>
                                        <IonSelect name={name} interface='action-sheet' interfaceOptions={{cssClass: 'long-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                            {states.map((state, index) => <IonSelectOption value={state} key={index}>{state}</IonSelectOption>)}
                                        </IonSelect>
                                    } rules={{required:true}}/> :
                                    <Controller name='mailing_state' control={control} render={ ({value, name})=>                                    
                                        <Autocomplete componentName={name} value={value} freeSolo={true} autoSelect={true} options={states} getOptionLabel={option => option}  id={name} 
                                        onChange={(event, value)=>{
                                            setValue('mailing_state', value)
                                        }}
                                        renderOption={option =>(
                                        <span> {option}</span>
                                        )} renderInput={params => {
                                            let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'State'};
                                            let innerInputProps = {...params.inputProps, maxLength: "2"}
                                            let newParams = {...params, InputProps: newInputProps, 
                                            inputProps: innerInputProps};
                                            return (
                                            <TextField type="text" {...newParams}/>
                                        )}} />} rules={{
                                            required: true, 
                                            validate: (val) => (states.findIndex((state) => /[A-Za-z]{2}/.test(val) && state === val?.toUpperCase()) !== -1)
                                        }} />
                                }
                                </IonItem>
                            </IonCol>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel> Mailing Zip *</IonLabel>
                                <IonItem className={showError('mailing_zip')}>
                                    <Controller name='mailing_zip' control={control} as={
                                        <InputMask maskChar='' mask='99999' placeholder='Zip' className='input-mask'/>
                                    } rules={{
                                        required: true,
                                        pattern:  /^[0-9]{5}(?:-[0-9]{4})?/
                                    }}/>
                                    {((formState.submitCount > 0)&& errors.mailing_zip) ? (<IonText color='danger'>
                                       Invalid Zip
                                    </IonText>
                                    ): ''}
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </React.Fragment>}
                    <IonRow>
                        <IonCol>
                            CONTACT INFO
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Primary Phone *
                            </IonLabel>
                            <IonItem className={showError('primary_phone')}>
                                <Controller name='primary_phone' control={control} as={
                                    <InputMask type='tel' mask="(999)999-9999" placeholder='Primary Phone' className='input-mask'/>
                                } rules={{
                                    required:true, 
                                    pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/,
                                    validate: (val) => (val?.charAt(1) !== '0' && val?.charAt(1) !== '1')
                                }}/>
                            </IonItem>
                            {errors.primary_phone && <IonText color='danger'>The phone number entered does not appear valid</IonText>}
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Phone Type *
                            </IonLabel>
                            <IonItem className={showError('primary_phone_type')}>
                            <Controller name='primary_phone_type' control={control} render={({value, name, onBlur, onChange}) =>
                                <IonSelect name={name} interface='action-sheet' value={value} onIonBlur={onBlur} onIonChange={onChange} interfaceOptions={{animated: false, mode: 'ios'}}>
                                     {phoneTypeOptions.map((phoneType, index) => (
                                         <IonSelectOption key={index} value={phoneType}>
                                             {phoneType}
                                         </IonSelectOption>
                                     ))}
                                </IonSelect>} rules={{required:true}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>Alternate Phone</IonLabel>
                            <IonItem className={showError('alternate_phone')}>
                                <Controller name='alternate_phone' control={control} as={
                                    <InputMask className='input-mask' type='tel' mask="(999)999-9999" placeholder='Alternate Phone'/>
                                } rules={{
                                    pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/,
                                    validate: (val) => (val?.charAt(1) !== '0' && val?.charAt(1) !== '1')
                                }}/>
                            </IonItem>
                            {errors.alternate_phone && <IonText color='danger'>The phone number entered does not appear valid</IonText>}
                        </IonCol>
                        {alternate_phone && primary_phone_type && (
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel>Alternate Phone Type *</IonLabel>
                                <IonItem className={showError('alternate_phone_type')}>
                                    <Controller name='alternate_phone_type' control={control} render={ ({value, name, onBlur, onChange}) =>
                                    <IonSelect name={name} value={value} onIonChange={onChange} onIonBlur={onBlur} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}}>
                                        {getAlternatePhoneTypeOptions().map( (phoneType, index) => (
                                            <IonSelectOption key={index} value={phoneType}>{phoneType}</IonSelectOption>
                                        ))}
                                    </IonSelect>
                                    } rules={{required:true}} />
                                </IonItem>
                            </IonCol>
                        )}
                    </IonRow>
                    {(primary_phone_type === 'Mobile' || alternate_phone_type === 'Mobile') && (
                        <IonRow>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel>
                                    I agree to receive account-related text messages sent to my phone. *
                                </IonLabel>
                                <IonItem className={showError('text_message_opt_in')}>
                                    <Controller name='text_message_opt_in' control={control} rules={{required:true}} render={ ({value, name, onBlur, onChange}) =>
                                        <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value ? value.toString() : ''} onIonChange={onChange} onIonBlur={onBlur}>
                                            <IonSelectOption value="Yes">Yes</IonSelectOption>
                                            <IonSelectOption value="No">No</IonSelectOption>
                                        </IonSelect>
                                    }/>
                                </IonItem>
                            </IonCol>                                                     
                        </IonRow>
                    )}
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>Email *</IonLabel>
                            <IonItem className={showError('email')}>
                                <Controller name='email' control={control} render={({value, name, onBlur, onChange}) =>
                                <IonInput name={name} className='item-input' value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='Email' required={true} clearInput/>
                                } rules={{
                                      required:true, 
                                      pattern: new RegExp(EMAIL_REGEX)
                                      }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Preferred Contact Method *
                            </IonLabel>
                            <IonItem className={showError('preferred_contact_method')}>
                            <Controller name='preferred_contact_method' control={control} render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' value={value} onIonBlur={onBlur} onIonChange={onChange} interfaceOptions={{animated: false, mode: 'ios'}}>
                                    <IonSelectOption value='Email'>Email</IonSelectOption>
                                    <IonSelectOption value='Phone'>Phone</IonSelectOption>
                                </IonSelect>} rules={{required:true}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                </MuiPickersUtilsProvider>                  
            </form>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        welcomePageFields: state.welcomePage,
        is401kApplication: state.menu.menuParams.is401kApplication,
        isDYO401kApplication: state.menu.menuParams.isDYO401kApplication,
        sessionId: state.session.sessionId,
        viewMode: state.onePathParams.viewMode
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setWelcomePageFields: (payload: any) => dispatch(actions.setWelcomePageFields(payload)),
        startSession: (payload: any) =>  dispatch(actions.startSession(payload)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerInformation);
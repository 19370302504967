import React from 'react';
import {useForm, Controller, useWatch} from 'react-hook-form';
import { IonText, IonGrid, IonRow, IonCol, IonItemDivider, IonInput, IonSelect, IonSelectOption, IonLabel, IonItem, IonCheckbox, isPlatform} from '@ionic/react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import InputMask from 'react-input-mask'; 
import {UpdateSessionActivityApp} from '../../helpers/sessionInterfaces';
import { states, isMobile, formatDateToString } from '../../helpers/utils';
import { convertStringDateToDate } from '../../helpers/sharedUtils';
import { saveEmployerSponsorPage } from '../../helpers/calloutHelpers';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

interface EmployerSponsorInfo extends UpdateSessionActivityApp {
    formData: {
        data?: Forms.employerSponsorForm
    } | undefined,
    setShowSpinner: Function,
}

const EmployerSponsorInformation: React.FC<EmployerSponsorInfo> = ({sessionId, updateMenuItem, formRef, setShowSpinner, updateLastActivity, formData, handleNavigation, setErrorMessage, setShowErrorToast}) => {
    let defaultValues : Forms.employerSponsorForm = {
      employer_address_different_from_mailing: false,
      ...formData?.data   
    }
    const {control, handleSubmit, errors, setValue, formState} = useForm<Forms.employerSponsorForm>({
        mode: "onChange",
        defaultValues
    }); 

    const { isDirty } = formState;

    const validateFields = (data: Forms.employerSponsorForm) => {
        let fieldsSuccessfullyValidated = true;
        console.log(data);
        setShowSpinner(true);
        saveEmployerSponsorPage(sessionId, data, updateLastActivity)?.then(() => {
            updateMenuItem('is_employer_information_page_valid', true, sessionId);
        }).catch(err => {
            setErrorMessage(err);
            setShowErrorToast(true);
            fieldsSuccessfullyValidated = false;
        }).finally(() => {
            setShowErrorToast(false);
            setShowSpinner(false);
            if(fieldsSuccessfullyValidated) {
                handleNavigation({status: 'Success'})
            }
            else {
                handleNavigation({status: 'Error'})
            }
        })
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = '';
        if ((formState.submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    }

    const onInvalidForm = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        }
        else {
            handleNavigation({status: 'Error'});
        }
    }

    const plan_name = useWatch({
        name: 'plan_name',
        control
    });

    const new_or_existing_plan = useWatch({
        name: 'new_or_existing_plan',
        control
    })

    const employer_name = useWatch({
        name: 'employer_name',
        control
    })

    const business_entity_type = useWatch({
        name: 'business_entity_type',
        control
    })

    const employer_phone = useWatch({
        name: 'employer_phone',
        control
    })

    const employer_address_different_from_mailing = useWatch({
        name: 'employer_address_different_from_mailing',
        control
    })

    return (
            <form ref={formRef} onSubmit={handleSubmit(validateFields, onInvalidForm)} className='ion-padding'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <IonGrid>
                    <IonRow className='well'>
                        <IonCol>
                            Please provide information about the employee sponsor of this 401k.
                        </IonCol>
                    </IonRow>
                    <IonItemDivider>
                        <strong>
                            <IonText color='primary'>
                                Employer Information
                            </IonText>
                        </strong>
                    </IonItemDivider>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Employer Name *
                            </IonLabel> 
                            <IonItem className={showError('employer_name')}>
                                <Controller name='employer_name' control={control} render={ ({name, value, onChange}) =>
                                    <IonInput name={name}   value={value} placeholder='Employer Name' clearInput maxlength={255}
                                    onIonChange={onChange}
                                    onIonBlur={(selected: any) => {
                                        let employerName = selected.target.value != null ? selected.target.value : '';
                                        if (employerName.length > 0 && (plan_name === null || plan_name === '') && (new_or_existing_plan !== 'Restatement of a Plan')){
                                            setValue('plan_name', employerName + ' Individual 401(k)');
                                        }
                                    }
                                }/>}rules={{required: true, maxLength: 255}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                New or Existing Plan? *
                            </IonLabel>
                            <IonItem className={showError('new_or_existing_plan')}>
                                <Controller name='new_or_existing_plan' control={control} render={ ({name,  value }) => 
                                    <IonSelect name={name}  value={value} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} 
                                    onIonChange={
                                        (selected) => {
                                            let currentPlanName = plan_name; 
                                            let employerName = employer_name;
                                            let defaultPlanName = employerName + ' Individual 401(k)';
                                            setValue('plan_name', defaultPlanName);
                                            console.log(defaultPlanName)
                                            if ((selected.detail.value === 'Restatement of a Plan')&& (currentPlanName === defaultPlanName)){
                                                setValue('plan_name', ''); 
                                            }
                                            setValue('new_or_existing_plan', selected.detail.value); 
                                        }
                                    }>
                                        <IonSelectOption value='New Plan'>New Plan</IonSelectOption>
                                        <IonSelectOption value='Restatement of a Plan'> Restatement of a Plan</IonSelectOption>
                                    </IonSelect>
                                } rules={{required: true}}/>
                            </IonItem>
                        </IonCol>
                        {(new_or_existing_plan === 'Restatement of a Plan') && (
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel>
                                    Initial Effective Date *
                                </IonLabel>
                                <IonItem className={showError('initial_effective_date')}>
                                    <Controller name='initial_effective_date' defaultValue={null} control={control} render={ ({name,  value })=>
                                        <KeyboardDatePicker
                                        className="date-width" margin='normal' placeholder="mm/dd/yyyy" format="MM/dd/yyyy" KeyboardButtonProps={{'aria-label': 'change-date'}} 
                                        value={convertStringDateToDate(value)}
                                        onChange={(date)=>{
                                            if(date){
                                                setValue('initial_effective_date', formatDateToString(date))
                                            }
                                        }} 
                                        />
                                    } rules={{required: true, min: 'initial_effective_date'}}/>
                                </IonItem>
                            </IonCol>
                        )}
                    </IonRow>
                    <IonRow>
                        <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                            <IonLabel> Type of Business Entity *</IonLabel>
                            <IonItem className={showError('business_entity_type')}>
                                <Controller name='business_entity_type' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonSelect name={name}  value={value} interface='action-sheet' interfaceOptions={{ animated: false, mode: 'ios'}} onIonBlur={onBlur}
                                        onIonChange={(selected) => {
                                            if (selected.detail.value !== 'S Corp' && selected.detail.value !== 'C Corp') {
                                                setValue('incorporation_date', '')
                                            }
                                            onChange(selected);
                                        }}>
                                        <IonSelectOption value='C Corp'>C Corp</IonSelectOption>
                                        <IonSelectOption value='S Corp'>S Corp</IonSelectOption>
                                        <IonSelectOption value='Partnership'>Partnership</IonSelectOption>
                                        <IonSelectOption value='Sole Proprietor'>Sole Proprietor</IonSelectOption>
                                        <IonSelectOption value='Other'>Other</IonSelectOption>
                                    </IonSelect>
                                } rules={{required: true}}/>
                            </IonItem>
                        </IonCol>
                        {(business_entity_type === 'S Corp' || business_entity_type === 'C Corp') && (
                            <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                                <IonLabel> Incorporation Date *</IonLabel>
                                <IonItem className={showError('incorporation_date')}>
                                    <Controller name='incorporation_date' defaultValue={null} control={control} render={ ({name, value})=>
                                        <KeyboardDatePicker
                                        className="date-width" margin='normal'  placeholder="mm/dd/yyyy" format="MM/dd/yyyy" KeyboardButtonProps={{'aria-label': 'change-date'}} value={convertStringDateToDate(value)}  onChange={(date)=>{
                                            if(date){
                                                setValue('incorporation_date', formatDateToString(date))
                                            }
                                        }} />
                                    } rules={{required: true, min: '01/01/1900'}}/>
                                </IonItem>
                            </IonCol>
                        )}
                    </IonRow>
                    <IonRow>
                        <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                        <IonLabel>Employer EIN *</IonLabel>
                        <IonItem className={showError('employer_ein')}>
                            <Controller name='employer_ein' control={control} as={
                                <InputMask mask='99-9999999' placeholder='12-1234567' maskChar="" name='employer_ein'  className='input-mask'/>
                            } rules={{required: true, minLength: 9}}/>
                        </IonItem>
                    </IonCol>
                        {(new_or_existing_plan === 'Restatement of a Plan') && (
                            <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                                <IonLabel>401k Plan EIN *</IonLabel>
                                <IonItem className={showError('plan_ein')}>
                                   <Controller name='plan_ein' control={control} as={
                                        <InputMask mask='99-9999999' placeholder='12-1234567' maskChar="" name='plan_ein' className='input-mask'/>
                                    } rules={{required: true, minLength: 9}}/>
                                </IonItem>
                            </IonCol>
                        )}
                    </IonRow>
                    {(new_or_existing_plan === 'New Plan') && (
                        <IonRow>
                            <IonCol>
                                Equity Trust will obtain and provide you with your plan EIN along with plan documents as part of our standard setup fees.
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow>
                        <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                            <IonLabel>
                                Plan Name *
                            </IonLabel>
                            <IonItem className={showError('plan_name')}>
                                <Controller name='plan_name' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange}/>
                                } rules={{required: true, maxLength: 255}}/>
                            </IonItem>
                        </IonCol>
                        <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                            <IonLabel>
                               Employer Phone * 
                            </IonLabel>
                            <IonItem className={showError('employer_phone')}>
                                <Controller name='employer_phone' defaultValue={employer_phone} control={control} as={
                                    <InputMask className='input-mask' type='tel' mask="(999)999-9999" placeholder='Phone'/>
                                } rules={{
                                    required: true, 
                                    pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                    validate: (val) => (val?.charAt(1) !== '0' && val?.charAt(1) !== '1')
                                }}/>
                            </IonItem>
                            {errors.employer_phone && <IonText color='danger'>The phone number entered does not appear valid</IonText>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <Controller name='employer_address_different_from_mailing' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name='employer_address_different_from_mailing' checked={value} onIonChange={(event)=>{
                                    setValue('employer_address_different_from_mailing', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Employer address is different from my personal address
                        </IonCol>
                    </IonRow>
                    {employer_address_different_from_mailing && (
                        <>
                            <IonRow>
                                <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                                    <IonLabel>Employer Street *</IonLabel>
                                    <IonItem className={showError('employer_street')}>
                                        <Controller name='employer_street' control={control} render={ ({name, value, onBlur, onChange}) =>
                                            <IonInput name={name} value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='Street'/>
                                        } rules={{required: true, maxLength: 100}}/> 
                                    </IonItem>
                                </IonCol>
                                <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                                    <IonLabel>Employer City *</IonLabel>
                                    <IonItem className={showError('employer_city')}>
                                        <Controller name='employer_city' control={control} render={ ({name, value, onBlur, onChange})=>
                                            <IonInput name={name} value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='City'/>
                                        } rules={{required: true, maxLength: 50}}/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                                    <IonLabel>
                                        Employer State *
                                    </IonLabel>
                                    <IonItem className={showError('employer_state')}>
                                        {isMobile(isPlatform) ? 
                                        <Controller name='employer_state' control={control} render={ ({name, value, onBlur, onChange}) =>
                                            <IonSelect name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} interface='action-sheet' interfaceOptions={{cssClass: 'long-select', animated: false, mode: 'ios'}} placeholder='State'>
                                                {states.map((state, index) => <IonSelectOption value={state} key={index}>{state}</IonSelectOption>)}
                                            </IonSelect>
                                        } rules={{required: true}}/> :
                                        <Controller name='employer_state' control={control} render={({name, value})=>
                                            <Autocomplete value={value} id={name} 
                                            onChange={(event,value)=>{
                                                setValue('employer_state', value)
                                            }}
                                            freeSolo={true} autoSelect={true} options={states}  getOptionLabel={option => option} renderOption={option =>(
                                            <span> {option}</span>
                                            )} renderInput={params => {
                                                let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'State'};
                                                let innerInputProps = {...params.inputProps, maxLength: "2"};
                                                let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps};
                                                return (
                                                <TextField type="text" {...newParams}/>
                                            )}} />} 
                                            rules={{required: true,
                                                validate: (val) => (states.findIndex((state) => state === val?.toUpperCase()) !== -1)
                                            }} />     
                                        }
                                    </IonItem>
                                </IonCol>
                                <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                                    <IonLabel>
                                        Employer Zip *
                                    </IonLabel>
                                    <IonItem className={showError('employer_zip')}>
                                        <Controller name='employer_zip' control={control} as={
                                            <InputMask maskChar='' mask='99999' placeholder='Zip' className='input-mask'/>
                                        } onChangeName='onChange' rules={{
                                            required: true,
                                            pattern:  /^[0-9]{5}(?:-[0-9]{4})?/
                                        }}/>
                                    </IonItem>
                                    {(formState.submitCount > 0 && errors.employer_zip) ? (<IonText color='danger'> Invalid Zip</IonText>): '' }
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                </IonGrid>
                </MuiPickersUtilsProvider>
            </form>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        updateLastActivity: (lastActive: number) => dispatch(actions.updateLastActivity(lastActive)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(EmployerSponsorInformation); 
type setMenuParamArgs = {page: keyof MenuParameters, valid: boolean, sessionId: string}

export const skipPaymentForm = (payload: boolean) => {
    return {
        type: 'SKIP_PAYMENT_FORM' as const,
        payload
    }
}

export const updateValidStateOnMenu = (payload: setMenuParamArgs) => {
    return {
        type: 'UPDATE_MENU_PARAMS' as const, 
        payload
    }
}

export const setMenuParams = (payload: Partial<MenuParameters>) => {
    return {
        type: 'SET_MENU_PARAMS' as const, 
        payload
    }
}

export const setMenuSections = (payload: MenuSection[]) => {
    return {
        type: 'SET_MENU_SECTIONS' as const, 
        payload
    }
}

export type MenuAction = ReturnType<typeof setMenuSections>
    | ReturnType<typeof skipPaymentForm>
    | ReturnType<typeof setMenuParams>
    | ReturnType<typeof updateValidStateOnMenu>
import React, { useState, useEffect, useCallback } from 'react'
import { IonGrid } from '@ionic/react'
import { saveApplication, updateAppStatus } from '../../../helpers/calloutHelpers'
import { SessionAppBase } from '../../../helpers/sessionInterfaces'
import { connect } from 'react-redux'
import CompleteApplication from './CompleteApplication'
import IncompleteApplication from './IncompleteApplication'
import * as actions from '../../../store/actions'

interface session extends SessionAppBase {
    menuSections: MenuSection[],
    setShowSpinner: (showSpinner: boolean) => void,
}

const ReviewIsp: React.FC<session> = ({ sessionId, menuSections, setShowSpinner, formRef, handleNavigation, setErrorMessage, setShowErrorToast }) => {
    const [hasIncompleteSteps, setHasIncompleteSteps] = useState(false);
          
    const getApplicationState = useCallback(async (isSubbed: boolean) => {
        setShowSpinner(true)
        let appPages = menuSections.flatMap(e => e.pages)
        let incompletePages = appPages.filter(page => (!page.isValid && page.header !== 'Finishing Up'))
        
        if(!isSubbed) {
            return
        }

        if (incompletePages.length > 0) {
            setHasIncompleteSteps(true)
            setShowSpinner(false)
            return
        }
        
        try{
            await updateAppStatus(sessionId, 'Filled')
            await saveApplication(sessionId)
        }catch(error: any){
            const errorMessage = (error as any).message || 'unknown error'
            setErrorMessage('Unable to save application: ' + errorMessage)
            setShowErrorToast(true)
            setShowSpinner(false)
            return
        }

        if(!isSubbed) {
            return
        } 

        setShowSpinner(false)
   }, [setShowSpinner, sessionId, menuSections, setErrorMessage, setShowErrorToast]) 

    useEffect(() => {
        let isSubbed = true

        getApplicationState(isSubbed)

        return () => {
            isSubbed = false
        }
    }, [getApplicationState]) 

    const navigate = () => {
        // The ReviewAndSign component doesn't have submittable fields but it still needs a form tag.
        // Page.tsx uses the form and formRef to inform the component that navigation buttons have been pressed.
        handleNavigation({ status: 'Success' })
    }

    return (
        <IonGrid className='ion-padding'>
            <IncompleteApplication hasIncompleteSteps={hasIncompleteSteps}/>
            <CompleteApplication hasIncompleteSteps={hasIncompleteSteps}/>
            <form ref={formRef} onSubmit={navigate}/>
        </IonGrid>

    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        menuSections: state.menu.menuSections,
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReviewIsp)
import React, { useEffect, useState, useContext } from 'react';
import { taxYearOptions, contributionOptions } from '../helpers/utils';
import { IonItem, IonGrid, IonRow, IonCol, IonLabel, IonItemDivider, IonText, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import InputMask from 'react-input-mask';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { getIsBankRouteValid} from '../helpers/calloutHelpers'
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import contributionLimitsJson from '../helpers/contributionLimits.json';
import { FundingOptionsContext } from './steps/FundingOptions'

const currentDate = new Date();

interface ContributionProps {
    sessionId : string,
    welcomePageFields : welcomePageParameters,
    formData?: {contribution: Forms.contributionForm, applicant: {fifty_or_older:boolean}},
    submitRef: any, //TODO: USE CORRECT TYPE HERE, NOT ANY
    setShowSpinner: Function,
    setShowErrorToast: Function,
    setErrorMessage: Function,
    updateFundingAmount: Function
}

const Contribution: React.FC<ContributionProps> = ({ sessionId, formData, submitRef, setShowSpinner, welcomePageFields, setErrorMessage, setShowErrorToast, updateFundingAmount}) => {

    const [showContributionForm, setShowContributionForm] = useState<boolean>(false);
    const { totalFundingBelowRequiredFunding } = useContext(FundingOptionsContext);
    
    const blankContribution: Forms.contributionForm = {
        account_number: '',
        bank_account_type: '',
        bank_name: '',
        name_on_account: '',
        new_contribution_amount: null,
        routing_number: '',
        tax_year: ''
    }

    const getDefaultValues = ()=>{
        let defaultValues: Forms.contributionForm = {
            ...blankContribution,
            ...formData?.contribution
        }
        return defaultValues
    }

    const methods = useForm<Forms.contributionForm>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });

    submitRef.current = methods;

    const routing_number: string|undefined = useWatch({
        name:'routing_number',
        control: methods.control
    })

    const new_contribution_amount: string|undefined = useWatch({
        name:'new_contribution_amount',
        control: methods.control
    })

    const tax_year: string | undefined = useWatch({
        name: 'tax_year',
        control: methods.control
    })

    const { submitCount } = methods.formState; 

    useEffect(() => {
        if (formData?.contribution) {
            setShowContributionForm(true)    
        }
    }, [])

    useEffect(() => {
        if (taxYearSelections.length === 1) {
            methods.setValue('tax_year', taxYearSelections[0])
        }
    }, [showContributionForm])

    useEffect(() => {
        validateRoutingNumber()
    }, [routing_number])
   
    useEffect(()=>{
        updateFundingAmount(showContributionForm ? +(new_contribution_amount || '0') : 0)
    },[new_contribution_amount, showContributionForm])

    const addContribution = () => {
        setShowContributionForm(true)
    }

    const removeContribution = () => {
        setShowContributionForm(false)
    }
 
    const validateRoutingNumber = () => {
        if (routing_number && routing_number !== '' && routing_number.length == 9) {
            setShowSpinner(true);
            getIsBankRouteValid(routing_number, sessionId).then(res => res.json()).then(data => {
                let bankInfo = {...data.data};
                if (bankInfo.status === 'Valid'){
                    methods.setValue('bank_name', bankInfo.bank_name);
                    setShowSpinner(false);
                    setShowErrorToast(false);
                } else {
                    setShowSpinner(false);
                    methods.setValue('routing_number', '');
                    methods.setValue('bank_name', '');
                    setErrorMessage('Invalid Routing Number');
                    setShowErrorToast(true);
                }
            }).catch(err => {
                setShowSpinner(false);
                setErrorMessage(err.message ? err.message : err);
                setShowErrorToast(true);
            })
        }
    }

    const resolveContributionOptions = (accountType: string) => {
        const previousYearContributionCutoff = new Date(`${contributionLimitsJson[0].prevYearCutoffDay}, ${currentDate.getFullYear()} 00:00:00`);

        let options: contributionOptions;

        let fiftyOrOlder = formData?.applicant?.fifty_or_older;

        if (accountType.includes('SEP')) {
            options = { contributionLimit: contributionLimitsJson[0].SEP, taxYears: ['Current Year'] };
            return options;
        }
        
        const contributionLimits = fiftyOrOlder ? contributionLimitsJson[0].fiftyOrOlder : contributionLimitsJson[0].underFifty;
        
        let contributionLimit: number | undefined = undefined;
        
        let lastYear = (currentDate < previousYearContributionCutoff) && tax_year === 'Last Year';
        
        if (accountType === 'Traditional IRA' || 'Roth IRA') {
            contributionLimit = lastYear ? contributionLimits.tradRoth.prevYear : contributionLimits.tradRoth.currentYear;
        }
        
        if (accountType.includes('401')) {
            contributionLimit = lastYear ? contributionLimits['401k'].prevYear : contributionLimits['401k'].currentYear;
        }

        const taxYears: taxYearOptions = (currentDate < previousYearContributionCutoff) ? ['Current Year', 'Last Year'] : ['Current Year'];

        options = { taxYears, contributionLimit }

        return options;
    }
    
    const contributionLimit: number | undefined = resolveContributionOptions(welcomePageFields.account_type).contributionLimit;
    const taxYearSelections: any = resolveContributionOptions(welcomePageFields.account_type).taxYears;

    const amountErrorMessage = () =>{
        let currencyFormatter = new Intl.NumberFormat('en-US',{style:'currency',currency: 'USD'});
        let currentAmount: number | null = methods.getValues('new_contribution_amount');
        if(currentAmount && contributionLimit && +currentAmount > contributionLimit){
          return `Please enter an amount below or equal to the contribution limit ${currencyFormatter.format(contributionLimit)}`
        }
        return "Please provide the contribution amount."
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(methods.errors));
        let className = '';
        
        if ((submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };

    const showAmountError = (fieldName: string) => {
        if (totalFundingBelowRequiredFunding) {
            return 'danger'
        }

        return showError(fieldName)
    };
    
    return (
        <div>
            <IonGrid>
                {!showContributionForm && (
                    <IonRow>
                        <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                            <IonButton onClick={addContribution}>
                                <IonIcon icon={addOutline} slot='start'></IonIcon>
                                Add Contribution from Bank Account
                            </IonButton>
                        </IonCol>
                    </IonRow>
                )}
                {showContributionForm && (
                    <>
                        <IonRow className='well'>
                            <IonCol>
                                <b>Please provide details of the new contribution to your account.</b> Please complete the information below including the amount of your contribution and what bank account you would like the contribution debited from. Your request will be reviewed and processed within 1-2 business days of the account being opened. Please keep in mind that there will be a five business day hold on the funds before they can be released into your account. This contribution will be considered for the tax year in which it was received.
                            </IonCol>
                        </IonRow>
                        <IonItemDivider>
                            <IonText color='primary'>
                                <b>New Contribution</b>
                            </IonText>
                            <IonButton slot='end' onClick={e=> {
                                removeContribution()
                            }}>Remove Contribution </IonButton>
                        </IonItemDivider>
                        <IonRow>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12" >
                                <IonLabel><strong>Amount *</strong></IonLabel>
                                <IonItem className={showAmountError('new_contribution_amount')}>
                                    <Controller control={methods.control} name='new_contribution_amount' render={ ({name, value, onBlur, onChange}) =>
                                        <IonInput name={name} type='number' value={value} onIonBlur={onBlur} onIonChange={onChange}/>
                                    } rules={{
                                        required: true,
                                        pattern: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                                        validate: (value) => {
                                            if(contributionLimit){
                                                return (value !== null && (+value > 0) && (+value <= contributionLimit))
                                            }else{
                                                return (value !== null && (+value > 0))
                                            }
                                        }
                                    }} />
                                </IonItem>
                            {methods.formState.errors['new_contribution_amount'] && <IonItem class="mt-1" color="danger"><p className="white-color">{amountErrorMessage()}</p></IonItem>}
                            </IonCol>
                            {(!welcomePageFields.account_type.includes('SEP')) && <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel hidden={taxYearSelections?.length === 1}>
                                    Tax Year *
                                </IonLabel>
                                <IonItem className={showError('tax_year')} hidden={taxYearSelections?.length === 1}>
                                    <Controller name='tax_year' control={methods.control} render={({name,  value, onChange }) => {
                                        return <IonSelect name={name} class="w-100 gr-border p-1" interface="action-sheet" disabled={taxYearSelections?.length === 1} placeholder='Please Select'  mode="ios" onIonChange={onChange} value={value}>
                                            {taxYearSelections?.map((taxYear: any) => (<IonSelectOption key={taxYear} value={taxYear}>{taxYear}</IonSelectOption>))}
                                        </IonSelect>
                                    }
                                    } rules={{
                                        required: true,
                                    }} />
                                </IonItem>
                            </IonCol>}
                        </IonRow>
                        <IonRow>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12" >
                                <IonLabel>
                                    Name on Account
                                </IonLabel>
                                <IonItem className={showError('name_on_account')}>
                                    <Controller name='name_on_account' control={methods.control} render={({name, value, onBlur, onChange}) =>
                                        <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={100}/>
                                    }/>
                                </IonItem>
                            </IonCol>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12" >
                                <IonLabel>
                                    Bank Account Type
                                </IonLabel>
                                <IonItem className={showError('bank_account_type')}>
                                    <Controller name='bank_account_type' control={methods.control} render={({name, value, onBlur, onChange})=>
                                        <IonSelect name={name}  interface='action-sheet' value={value} onIonBlur={onBlur} onIonChange={onChange} interfaceOptions={{animated: false, mode: 'ios'}}>
                                            <IonSelectOption value='Checking'>Checking</IonSelectOption>
                                            <IonSelectOption value='Savings'>Savings </IonSelectOption>
                                        </IonSelect>
                                    }/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12" >
                                <IonLabel>
                                    Bank ABA/Routing Number
                                </IonLabel>
                                <IonItem className={showError('routing_number')}>
                                    <Controller name='routing_number' control={methods.control} as={
                                        <InputMask mask='999999999' maskChar='' className='input-mask'/>
                                    }
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12" >
                                <IonLabel>
                                    Account Number
                                </IonLabel>
                                <IonItem className={showError('account_number')}>
                                    <Controller name='account_number' control={methods.control} render={({name, value, onBlur, onChange}) =>
                                        <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={50}/>
                                    }/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12" >
                                <IonLabel>Bank Name</IonLabel>
                                <IonItem className={showError('bank_name')}>
                                    <Controller name='bank_name' control={methods.control} render={ ({name, value, onBlur, onChange}) =>
                                        <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={100}/>
                                    }/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className='well ion-margin-top'>
                            <IonCol>
                            <p>Providing Equity Trust with your account information is optional.</p>
                            <p>If you prefer to mail a check for your contribution, please send to the address below, mark the year in which you wish the contribution to be applied to, and make the check payable to Midland Trust Company FBO your name as it appears on your application.</p>
                            <p>Midland IRA, Inc. 
                            <br/>P.O. Box 07520
                            <br/>Fort Myers, FL 33919</p>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonGrid>
        </div>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        welcomePageFields: state.welcomePage,
        sessionId: state.session.sessionId
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contribution);
export const resetSession = () => {
    return {
        type: 'RESET_SESSION' as const 
    }
}

export const initSession = (sessionId: string) => {
    return {
        type: 'INIT_SESSION' as const, 
        sessionId
    }
}

export const startSession = (payload: any) => {
    return {
        type: 'START_SESSION' as const, 
        payload
    }
}

export const updateLastActivity = (lastActive: number) =>{
    return {
        type: 'UPDATE_LAST_ACTIVITY' as const,
        lastActive
    }
}

export const showTimeoutModal = (showModal: boolean) => {
    return {
        type: 'SHOW_TIMEOUT_MODAL' as const, 
        showModal
    }
}

export const setCountdownIntervalRef = (payload: any) => {
    return {
        type: 'SET_COUNTDOWN_INTERVAL_REF' as const, 
        payload
    }
}

export const setSessionTimeout = (payload: any) => {
    return {
        type: 'SET_SESSION_TIMEOUT' as const, 
        payload
    }
}

export type SessionActions = ReturnType<typeof resetSession>
    | ReturnType<typeof initSession>
    | ReturnType<typeof startSession>
    | ReturnType<typeof updateLastActivity>
    | ReturnType<typeof showTimeoutModal>
    | ReturnType<typeof setCountdownIntervalRef>
    | ReturnType<typeof setSessionTimeout>
import React, { useState } from 'react';
import './Menu.css';
import Tooltip from '@material-ui/core/Tooltip';
import { IonIcon, IonText, IonRow, IonCol } from '@ionic/react';
import { isMobile } from '../helpers/utils';
import { Box } from '@material-ui/core';
import { isPlatform } from '@ionic/react';

interface TooltipComponentProps {
  icon?: string;
  title?: string;
  body?: string;
  message?: string;
  className?: string;
  titleColor?: string;
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({ icon, title, body, message, className, titleColor }) => {

  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const toggleTooltip = () => {
    return (!showTooltip) ? setShowTooltip(true) : setShowTooltip(false);
  }

  return (
    <>
      <Tooltip
        placement="top"
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        title={<Box className='tooltip--text'>{message}</Box>}>
        <IonText className={className || ''}><b><span className={titleColor}>{title}</span> <IonIcon onClick={toggleTooltip} onMouseEnter={isMobile(isPlatform) ? () => (false) : () => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} icon={icon} class="tooltip" color={(!showTooltip) ? 'tooltip' : 'primary'}></IonIcon></b>{body}</IonText>
      </Tooltip>
    </>
  );
};

export default TooltipComponent;
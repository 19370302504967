import React, { useCallback, useEffect, useState } from 'react'
import Welcome from './steps/Welcome';
import OwnerInformation from './steps/OwnerInformation';
import OwnerQuestionnaire from './steps/OwnerQuestionnaire';
import Beneficiaries from './steps/Beneficiaries';
import FeeArrangement from './steps/FeeArrangement';
import AccountNotifications from './steps/AccountNotifications';
import InitialInvestment from './steps/InitialInvestment';
import FundingOptions from './steps/FundingOptions';
import PaymentInformation from './steps/PaymentInformation';
import Review from './steps/Review/Review';
import EmployerSponsorInformation from './steps/EmployerSponsorInformation';
import PlanInformation from './steps/PlanInformation';
import { connect } from 'react-redux';
import { makeGetPageInfoCallout } from '../helpers/calloutHelpers'
import { SubmitState } from '../pages/Page'
import { StoreState } from '../store/reducers/rootReducer';
import { PageName } from 'shared-utils'

interface PagerLoaderInterface {
  pageName: string, 
  formRef: any,
  welcomePageInitialized: welcomePageParameters,
  sessionId: string,
  handleNavigation: (value: SubmitState) => void
}

const Pages = [
  'Welcome',
  'OwnerInformation',
  'OwnerQuestionnaire',
  'Beneficiaries',
  'InvestmentDetails',
  'FeeArrangement',
  'FundingOptions',
  'AccountNotifications',
  'PaymentInformation',
  'ReviewAndSign',
  'EmployerSponsorInformation',
  'PlanInformation'
] as const

export type PageNames = typeof Pages[number]
export const PageNameValidator = (pageName: unknown): pageName is PageNames => {
  return Pages.includes(pageName as any) 
}

const PageLoader: React.FC<PagerLoaderInterface> = props => {
  const {handleNavigation} = props
  const {sessionId, formRef, welcomePageInitialized} = props
  const [formData, setFormData] = useState<{ data?: any, page: string }>();
  
  const pageName = PageNameValidator(props.pageName) ? props.pageName : 'Welcome' 
 
  const makeCallout = useCallback( async (calloutId: PageName) => {
    if(!sessionId || sessionId === '')
    {
      setFormData({ data: {}, page: pageName })
      return
    }
  
    setFormData((prev)=>{
      if(prev?.page === pageName){
        return prev
      } else {
        return { page: pageName }
      }
    })

    const newFormData = await makeGetPageInfoCallout(sessionId, calloutId)
    const data = ((newFormData as unknown) as {data: ReturnType<typeof makeGetPageInfoCallout>})?.data || newFormData || {}
    setFormData({ data , page: pageName })
  }, [setFormData, sessionId, pageName])

  const loadPageData = useCallback(async (pageName: PageNames) => {
    switch (pageName) {
      case 'Welcome':
        makeCallout('welcome')
        break
      case 'OwnerInformation':
        makeCallout('appId')
        break
      case 'OwnerQuestionnaire':
        makeCallout('appId')
        break
      case 'Beneficiaries':
        makeCallout('beneficiary')
        break
      case 'InvestmentDetails':
        makeCallout('initial_investment')
        break
      case 'FeeArrangement':
        makeCallout('feeArrangement')
        break
      case 'FundingOptions':
        makeCallout('fundingOptions')
        break
      case 'AccountNotifications':
        makeCallout('accountNotification')
        break
      case 'PaymentInformation':
        makeCallout('paymentInfo')
        break
      case 'EmployerSponsorInformation':
        makeCallout('employerSponsor');
        break;
      case 'PlanInformation':
        makeCallout('planInfo');
        break;
      default:
        setFormData({data: {}, page: pageName});
    }
  },[makeCallout])

  useEffect(() => {
    if(!PageNameValidator(pageName)){
      console.log('invalid page')
      return
    }
  
    loadPageData(pageName)
  }, [loadPageData, pageName]);
  
  const getComponent = useCallback((pageName: PageNames) => {
     switch (pageName) {
       case 'Welcome':
         return <Welcome handleNavigation={handleNavigation} formRef={formRef} />;
       case 'OwnerInformation':
         return <OwnerInformation handleNavigation={handleNavigation} formData={formData} formRef={formRef}  />;
       case 'OwnerQuestionnaire':
         return <OwnerQuestionnaire handleNavigation={handleNavigation} formData={formData} formRef={formRef} />;
       case 'Beneficiaries':
         return <Beneficiaries handleNavigation={handleNavigation} formData={formData} formRef={formRef} />;
       case 'InvestmentDetails':
         return <InitialInvestment handleNavigation={handleNavigation} formData={formData} formRef={formRef} />;
       case 'FeeArrangement':
         return <FeeArrangement  handleNavigation={handleNavigation} formData={formData} formRef={formRef} />;
       case 'FundingOptions':
         return <FundingOptions handleNavigation={handleNavigation} formData={formData} formRef={formRef} />;
       case 'AccountNotifications':
         return <AccountNotifications handleNavigation={handleNavigation} formData={formData} formRef={formRef} />;
       case 'PaymentInformation':
         return <PaymentInformation  handleNavigation={handleNavigation} formData={formData} formRef={formRef}/>;
       case 'ReviewAndSign':
         return <Review handleNavigation={handleNavigation} formRef={formRef}/>;
       case 'EmployerSponsorInformation':
         return <EmployerSponsorInformation handleNavigation={handleNavigation} formData={formData} formRef={formRef} />;
       case 'PlanInformation':
         return <PlanInformation  handleNavigation={handleNavigation} formData={formData} formRef={formRef} />
       default:
         return <Welcome handleNavigation={handleNavigation} formRef={formRef} />;
     }
    
  },[formData, formRef, handleNavigation])

  if(!PageNameValidator(pageName)){
    return <Welcome handleNavigation={handleNavigation} formRef={formRef}/>;
  }

  return ((formData?.data && welcomePageInitialized?.initialized) ? getComponent(pageName) : <h1>Loading ... </h1>)
}

const mapStateToProps = (state: StoreState) => {
  return {
    sessionId: state.session.sessionId,
    welcomePageInitialized: state?.welcomePage
  }
}

export default connect(mapStateToProps)(PageLoader);
import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { validateCreditCardExpirationDate } from '../../helpers/utils';
import { IonRow, IonCol, IonGrid, IonItemDivider, IonLabel, IonSelect, IonSelectOption, IonItem, IonText } from '@ionic/react';
import InputMask from 'react-input-mask';
import { saveFeeArrangementPage } from '../../helpers/calloutHelpers';
import { SessionApp } from '../../helpers/sessionInterfaces'
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import FeeSchedule from '../FeeSchedule';

const FUTURES_FOREX_INVESTMENT = 'Futures/Forex'
const BIZ_FEE_SCHED = 'Business Fee Schedule ($150)'
const CUSTOM_FEE_SCHEDULE = 'Custom Fee Schedule'

interface FeeArrangementFc extends SessionApp{
    formData: {data?: Forms.feeArrangementForm}|undefined,
    initial_investment_type: string,
    updateLastActivity: Function,
    onePathParameters: onePathParameters
}

const FeeArrangement: React.FC<FeeArrangementFc> = ({sessionId, updateMenuItem, formRef, updateLastActivity, formData, initial_investment_type, onePathParameters, handleNavigation, setErrorMessage, setShowErrorToast }) => {
    const getDefaultFeeSchedule = () => {
        if (initial_investment_type === FUTURES_FOREX_INVESTMENT || onePathParameters?.custom_ui_state === CUSTOM_FEE_SCHEDULE) {
            return 'Asset Based ($350)'
        } 
        else {
            return formData?.data?.fee_schedule ? formData.data.fee_schedule : ''
        }
    }

    const defaultValues: Forms.feeArrangementForm ={
        payment_method:'',
        cc_number: '',
        cc_exp_date: '',
        ...formData?.data,
        fee_schedule: getDefaultFeeSchedule()
    }
    const {control, handleSubmit, errors, setValue, formState } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const { isDirty, dirtyFields } = formState; 

    let ccRef = useRef<InputMask>(null);
    const [ccInputMask, setCCInputMask] = useState('xxxx xxxx xxxx 9999');
    
    const cc_number: string|undefined = useWatch({
        name:'cc_number',
        control
    })

    const payment_method: string|undefined = useWatch({
        name:'payment_method',
        control
    })

    useEffect(() => {
        if (cc_number && ccRef && ccRef.current) 
        {
            if ((cc_number === ccRef.current?.props?.value?.toString().replace(/\s/g, '').replace(/_/g,''))){
                if (/^3[47]/.test(cc_number)) {
                    setCCInputMask('xxxx xxxxxx x9999');
                } else {
                    setCCInputMask('xxxx xxxx xxxx 9999');
                }
            }
        }
    }, [cc_number])

    useEffect(() => {
        if (!isDirty) {
            return
        }
        if(dirtyFields.fee_schedule || dirtyFields.payment_method){
            updateMenuItem('is_funding_options_page_valid', false, sessionId)
        }
    }, [isDirty, dirtyFields, updateMenuItem, sessionId])

    const validateFields = (data: Forms.feeArrangementForm, event:any) => {
        saveFeeArrangementPage(sessionId, data, updateLastActivity)?.then(() => {
            updateMenuItem('is_fee_arrangement_page_valid', true, sessionId);
            handleNavigation({status: 'Success'});
        });
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = '';
        if ((formState.submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };

    const onInvalidForm = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        }
        else {
            handleNavigation({status: 'Error'});
        }
    }

    const formatDate = (date?: string) => {
        if(date){
            let dateArray = date.split('-')
            return `${dateArray[1]}/${dateArray[0].substring(0,4)}`
        }
        return ''
    }

    return (
            <form ref={formRef} onSubmit={handleSubmit(validateFields, onInvalidForm)} className='ion=padding'>

            <IonGrid>
                <IonRow className='well'>
                    <IonCol>
                        <p>
                        When you choose to work with Equity Trust, you can rest assured you are receiving an exceptional value. Our commitment is to provide the absolute best service at the best possible price. Our fees are flat and up-front, so you never have to worry about any hidden fees.
                        </p>
                        {formData?.data?.fee_schedule !== BIZ_FEE_SCHED &&
                            (<div>
                                {initial_investment_type !== FUTURES_FOREX_INVESTMENT && onePathParameters?.custom_ui_state !== CUSTOM_FEE_SCHEDULE && (<>
                                    Please review our Fee Schedule and choose which option is best suited for your account: <br />
                                </>)}
                                {onePathParameters?.custom_ui_state !== CUSTOM_FEE_SCHEDULE &&
                                    <FeeSchedule showBothFeeOptions={initial_investment_type !== FUTURES_FOREX_INVESTMENT}/>
                                }
                            </div>)}
                        <p>
                            On an annual basis, your Equity Trust administrative fees will be billed in the month that your account was opened. You will be billed for any non-recurring requests, such as new purchases, wire transfers, and overnight mail when it is processed.
                        </p>
                        <p>
                            Deducting your account remains the easiest way to pay your fees. Our clients typically transfer in enough cash to cover Equity Trust's fees for as long as they plan to hold their investment.
                        </p>
                        {formData?.data?.fee_schedule === BIZ_FEE_SCHED &&
                            <a target="_blank" href="https://midlandtrust.com/forms/open-an-account/Business_Fee_Schedule.pdf" >Click here to view our Fee Schedule</a>
                        }
                    </IonCol>
                </IonRow>
                {formData?.data?.fee_schedule !== BIZ_FEE_SCHED &&
                    <>
                        <IonItemDivider hidden={initial_investment_type === FUTURES_FOREX_INVESTMENT || onePathParameters?.custom_ui_state === CUSTOM_FEE_SCHEDULE}>
                            <strong>
                            Fee Payment Election
                            </strong>
                        </IonItemDivider>
                        <IonRow hidden={initial_investment_type === FUTURES_FOREX_INVESTMENT || onePathParameters?.custom_ui_state === CUSTOM_FEE_SCHEDULE}>
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel>
                                    Select fee agreement *
                                </IonLabel>
                                <IonItem className={showError('fee_schedule')}>
                                    <Controller name='fee_schedule' control={control} render={({name, value, onBlur, onChange}) =>
                                        <IonSelect name={name}  interface='action-sheet' value={value} onIonBlur={onBlur} onIonChange={onChange} interfaceOptions={{animated: false, mode: 'ios'}} disabled={initial_investment_type === FUTURES_FOREX_INVESTMENT || onePathParameters?.custom_ui_state === CUSTOM_FEE_SCHEDULE}>
                                            <IonSelectOption value='Asset Based ($350)'>Option 1 - Asset Based</IonSelectOption>
                                            <IonSelectOption value='Value Based'>Option 2 - Value Based</IonSelectOption>
                                        </IonSelect>
                                    } rules={{required: true}}  />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </>
                }
                <IonItemDivider>
                    <strong>
                    Payment Method for Annual Asset Administration
                    </strong>
                </IonItemDivider>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>
                            Payment Method *
                        </IonLabel>
                        <IonItem className={showError('payment_method')}>
                                <Controller name='payment_method' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonSelect name={name}  interface='action-sheet' value={value} onIonBlur={onBlur} onIonChange={onChange} interfaceOptions={{animated: false, mode: 'ios'}}>
                                        <IonSelectOption value='Account'>Deduct My Account</IonSelectOption>
                                        <IonSelectOption value='Credit Card'>Credit Card</IonSelectOption>
                                    </IonSelect>
                                } rules={{required: true}}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                {payment_method === 'Credit Card' &&  <React.Fragment>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Credit Card Number *
                            </IonLabel>
                            <IonItem className={showError('cc_number')}>
                                <Controller name='cc_number' control={control} as={
                                    <InputMask ref={ccRef} mask={ccInputMask} maskChar='' className='input-mask' formatChars={{'9':'[0-9]', 'x':'(\\*|\\d)'}} />
                                } rules={{required: true, maxLength: 20}}/>
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel> Expiration Date *</IonLabel>
                            <IonItem className={showError('cc_exp_date')}>
                                <Controller name='cc_exp_date' control={control} render={({name, value}) =>
                                    <InputMask className='input-mask' mask='m9/2099' formatChars={{'m':'(0|1)','9':'[0-9]'}} maskChar='_' value={formatDate(value)}//need to format value
                                        onChange={(selected)=>{
                                            let monYearInput = selected.target.value;
                                            let monYearArr = monYearInput.split('/');
                                            monYearArr.reverse().push('01')
                                            setValue('cc_exp_date', monYearArr.join('-'))
                                        }}
                                    />
                                } rules={{
                                    required: true,
                                    pattern:/^20\d{2}-(0[1-9]|1[0-2])-01$/,
                                    validate: (val) : any => validateCreditCardExpirationDate(val)
                                }}/>
                                {((formState.submitCount > 0) &&
                                errors.cc_exp_date 
                                )
                                ? (
                                    <IonText color='danger'>Expiration Date can't be in the past.</IonText>
                                ) : null}
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    </React.Fragment>}
            </IonGrid>
            </form>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        initial_investment_type: state.sharedParams.initial_investment_type,
        onePathParameters: state.onePathParams
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        updateLastActivity: (lastActive: number) => dispatch(actions.updateLastActivity(lastActive)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FeeArrangement);
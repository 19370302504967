export const setContactInfo = (payload: ContactFields) => {
    return {
        type: 'SET_CONTACT_FIELDS' as const, 
        payload
    }
}

export const initWelcomePageFields = (payload: Partial<welcomePageParameters>) => {
    return {
        type: 'INIT_WELCOME_PAGE_FIELDS' as const, 
        payload
    }
}

export const setWelcomePageFields = (payload: Partial<welcomePageParameters>) => {
    return {
        type: 'SET_WELCOME_PAGE_FIELDS' as const, 
        payload
    }
}

export const setOnePathWelcomeFields = (payload: Partial<welcomePageBody>) => {
    return {
        type: 'SET_ONEPATH_PARAMS' as const, 
        payload
    }
}

export type WelcomeActions = ReturnType<typeof setWelcomePageFields>
    | ReturnType<typeof initWelcomePageFields>
    | ReturnType<typeof setOnePathWelcomeFields>
    | ReturnType<typeof setContactInfo>
import React, {useState, useEffect} from 'react';
import { SessionAppLoadingPage } from '../../../helpers/sessionInterfaces';
import { connect } from 'react-redux';
import { ViewMode } from '../../../helpers/utils';
import ReviewAndSign from './ReviewAndSign';
import ReviewIsp from './ReviewIsp';
import * as actions from '../../../store/actions';

type ReviewProps = SessionAppLoadingPage & {
    viewMode?: ViewMode,
    menuSections: MenuSection[],
    updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => ReturnType<StoreDispatch>
}

const Review: React.FC<ReviewProps> = ({ formRef, handleNavigation, viewMode, menuSections, updateMenuItem, sessionId }) => {
    const [hasIncompleteSteps, setHasIncompleteSteps] = useState(false);

    useEffect(() => {
        let appPages = menuSections.flatMap(e => e.pages);
        let incompletePages = appPages.filter(page => (!page.isValid && page.header !== 'Finishing Up'));
        setHasIncompleteSteps(incompletePages.length > 0)
    }, [menuSections])

    useEffect(() =>{
        updateMenuItem('is_review_and_sign_page_valid', !hasIncompleteSteps, sessionId)
    }, [hasIncompleteSteps, updateMenuItem, sessionId])
 
    if(viewMode === 'isp') {
        return <ReviewIsp formRef={formRef} handleNavigation={handleNavigation} />
    }

    return <ReviewAndSign formRef={formRef} handleNavigation={handleNavigation} hasIncompleteSteps={hasIncompleteSteps} />
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        viewMode: state.onePathParams.viewMode,
        menuSections: state.menu.menuSections,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
})


export default connect(mapStateToProps, mapDispatchToProps)(Review);
import React, { useState, useEffect, useCallback } from 'react';
import * as actions from '../../../store/actions';
import { downloadPenSignDocs, notifyDocusignNavigationOccurred } from '../../../helpers/calloutHelpers';
import { IonCol, IonButton, IonSpinner } from '@ionic/react';
import { connect } from 'react-redux';

type LoadingStatusResult = {
    callResult: 'success',
    message: string,
    updateStatus: Status 
} | {
    callResult: 'error',
    message: string,
}

export type LoadingCallback = (loading: boolean, result?: LoadingStatusResult) => void

type PenSignDocumentProps = {
    autoDownload?: boolean,
    sessionId: string,
    setShowSpinner: (showSpinner: boolean) => void,
    setErrorMessage: (payload: any) => void,
    loadingCallback?: LoadingCallback
    disabled?: boolean
}

const PenSignDocument: React.FC<PenSignDocumentProps> = ({autoDownload=false, sessionId, setErrorMessage, disabled, loadingCallback}) => {
    const [downloadUrl, setDownloadUrl] = useState('');
    const [downloading, setDownloading] = useState(autoDownload);
    const [downloadFailed, setDownloadFailed] = useState(false);

    const isIframe = window.location !== window.parent.location

    const callDownloadPenSignDocs = useCallback(async (sessionId: string) => {
        const handleError = (errorMessage: string) => {
            if(loadingCallback){
                loadingCallback(false, {callResult: 'error', message: errorMessage})
            }
            setErrorMessage(errorMessage)
        }

        try{
            const response = await downloadPenSignDocs(sessionId, 'id_check_failed')
            if(typeof response === 'string'){
                setDownloadUrl(response)
            }
            if(autoDownload && isIframe){
                setTimeout(()=>{
                    (document.querySelector('a.download-link') as any)?.click()
                }, 1000)
            }
            setDownloading(false)
        }catch(error) {
            setDownloadFailed(true)
            handleError('Unable to download the signature document: ' + error)
        }
        setDownloading(false)
    }, [setDownloadUrl, autoDownload, isIframe, setErrorMessage, loadingCallback])

    useEffect(() => {
        if(downloading){
            callDownloadPenSignDocs(sessionId);
        }
        loadingCallback&&loadingCallback(!!downloading)
    }, [loadingCallback, downloading, callDownloadPenSignDocs, sessionId])


    if(downloadFailed){
        return <IonButton disabled> Download Failed</IonButton>
    }
    
    if(!downloading && !downloadUrl) {
        return <IonButton disabled={disabled} onClick={() => {
                    setDownloading(true)
                }}>Download Signature PDF Document</IonButton>
    }

    if(downloading && !downloadUrl) {
        return <IonCol>
                Downloading Documents... <IonSpinner /> 
        </IonCol>
    }

    return <a className='download-link' href={downloadUrl} download='Midland_Application_Documents.pdf'>
            <IonButton color='secondary' disabled={disabled} onClick={() => {
                loadingCallback&&loadingCallback(false, {callResult: 'success', message: 'Downloaded signature document', updateStatus: 'Downloaded'})
                notifyDocusignNavigationOccurred(sessionId)
            }}>Open My Signature PDF Document</IonButton>
        </a>
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setErrorMessage: (payload: any) => dispatch(actions.setAndShowErrorMessage(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PenSignDocument);
import React, {useState, useEffect, useRef} from 'react';
import { formatDateToString } from '../helpers/utils';
import { EMAIL_REGEX } from '../helpers/sharedUtils'
import {useForm, Controller} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {IonPage, IonContent, IonInput, IonButton, IonGrid, IonCol, IonRow, IonItem, IonLabel, IonToast, isPlatform, IonSplitPane, IonSpinner} from '@ionic/react'
import { useHistory } from 'react-router-dom';
import { getOnePathDataFromSession, resumeCallout } from '../helpers/calloutHelpers';
import {KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import Header from '../components/Header';
import "./ResumeStyle.css"

const BAD_RESPONSE_ERR = 'We had a problem pulling back your saved application.'

const Resume: React.FC<{ setOnePathParameters: Function, initSession: Function, startSession: Function}> = ({setOnePathParameters, initSession, startSession}) => {
    const history = useHistory();
    const toastEl = useRef<HTMLIonToastElement>(null);
    const { herokuToken } = useParams<{ herokuToken: string; }>();

    const [submitting, setSubmitting] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const {control, handleSubmit, errors,  formState} = useForm({
        mode: "onChange"
    }); 

    useEffect(() => {
        initResumeApplication()       
    }, [])

    useEffect(() => {
        let errorsArr = Object.keys(errors);
        if (errorsArr.length > 0) {
            if (toastEl && toastEl.current) {
                toastEl.current.dismiss().then(() =>{
                    setErrorMessage('Required Fields Missing');
                }
                );
            } else {
                setErrorMessage('Required Fields Missing');
            }
        }
    }, [errors]);

    const onSubmit = (data: ResumeForm) => {
        let updatedData = {
            ...data,
            date_of_birth: formatDateToString(new Date(data.date_of_birth))
        }
        setSubmitting(true)
        resumeApplication(updatedData);
        setSubmitting(false)
    }

    async function initResumeApplication() {
        setLoading(true)
        const result = await resumeApplication()
        if(!result)
        {
            setLoading(false)
        }
    }

    async function resumeApplication(authInput?: ResumeForm) {
        setErrorMessage('');
        
        const response = await resumeCallout(herokuToken, authInput)
        if(response.ok) {
            try {
                const text = await response.text()
                let data = text.length === 0 ? undefined : JSON.parse(text) as API.resumeResponse

                if(!data)
                {
                    return false
                }

                if(data.appErrorMessage && !authInput){
                    return false
                }

                if(data.appErrorMessage){
                    let errorMessage = data?.appErrorMessage ? data?.appErrorMessage : BAD_RESPONSE_ERR
                    setErrorMessage(errorMessage);
                    return false
                }

                if(data.appSession?.session_id){
                    initSession(data.appSession?.session_id);
                    const res = await getOnePathDataFromSession(data.appSession?.session_id)
                    const onePathdata = await res.json()
                    if (onePathdata.data && Object.keys(onePathdata.data).length > 0) {
                        setOnePathParameters({...onePathdata.data, hasOnePathParams: true});
                    }
                }

                if(data.appSession?.heroku_token && data.appSession?.last_active && data.appSession?.session_length)
                {
                    startSession({
                        herokuToken: data.appSession?.heroku_token,
                        lastActive: data.appSession?.last_active, 
                        sessionLength: data.appSession?.session_length
                    });
                    history.push('/Welcome')
                    return true
                }

            } catch(err) {
                setErrorMessage(BAD_RESPONSE_ERR);
            }
        } 
        setErrorMessage(BAD_RESPONSE_ERR);

        return false
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = '';
        if ((formState.submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };

    const renderInput = () => (
       <> 
            <Header />
            <IonSplitPane contentId="main" className="top-space">
                <IonPage id='main' className={isPlatform('android') ? 'android-fit-content ion-justify-content-around' : 'ion-padding'}>
                    <IonContent className='ion-padding'>
                        <IonToast ref={toastEl} color='danger shade' position='top' isOpen={errorMessage !== ''} message={errorMessage} onDidDismiss={() => setErrorMessage('')} buttons={
                            [{
                                icon: 'close',
                                role: 'cancel'
                            }]
                        } />
                        <form onSubmit={handleSubmit(onSubmit)} className='ion-padding'>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                            <IonLabel id='ion-input-0-lbl' aria-label='last_name'>
                                                Last Name *
                                            </IonLabel>
                                            <IonItem className={showError('last_name')}>
                                                <Controller control={control} name='last_name' render={({name,  value, onBlur, onChange }) =>
                                                    <IonInput name={name} id='lastName' aria-label='Last Name' role='generic' placeholder='Your Last Name' title='Your Last Name' value={value} onIonBlur={onBlur} onIonChange={onChange} />
                                                } rules={{ required: true, maxLength: 100 }} />
                                            </IonItem>
                                        </IonCol>
                                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                            <IonLabel id="ion-input-1-lbl">
                                                Last 4 Digits of SSN *
                                            </IonLabel>
                                            <IonItem className={showError('last_4_ssn')}>
                                                <Controller control={control} name='last_4_ssn' render={({name,  value, onBlur, onChange }) =>
                                                    <IonInput name={name} placeholder='Last 4 Digits of Your SSN' title='Last 4 Digits of Your SSN' type='number' value={value} onIonBlur={onBlur} onIonChange={onChange} />
                                                } rules={{ required: true, maxLength: 4, minLength: 4, pattern: /\d{4}$/ }} />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                            <IonLabel id="ion-input-2-lbl">
                                                Date of Birth *
                                            </IonLabel>
                                            <IonItem className={showError('date_of_birth')}>
                                                <Controller control={control} name='date_of_birth' defaultValue={null} render={({name,  value, onChange }) =>
                                                    <KeyboardDatePicker
                                                        name={name} className="date-width" margin='normal' value={value} placeholder="Date of Birth" format="MM/dd/yyyy" KeyboardButtonProps={{ 'aria-label': 'change-date' }} onChange={onChange} />
                                                } rules={{ required: true }} />
                                            </IonItem>
                                        </IonCol>
                                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                            <IonLabel id="ion-input-3-lbl">
                                                Email Address *
                                            </IonLabel>
                                            <IonItem className={showError('email')}>
                                                <Controller control={control} name='email' render={({name,  value, onBlur, onChange }) =>
                                                    <IonInput name={name} value={value} placeholder='youremail@domain.com' title='Your Email Address' type='email' onIonBlur={onBlur} onIonChange={onChange} />
                                                } rules={{ required: true, pattern: new RegExp(EMAIL_REGEX) }} />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonButton className='ion-float-right text-light' type='submit' disabled={submitting}>Resume Application</IonButton>
                                </IonGrid>
                            </MuiPickersUtilsProvider>
                        </form>
                    </IonContent>
                </IonPage>
            </IonSplitPane>
        </>
    )
    
    const renderForm = () => {
        const herokuTokenIsInvalid = typeof herokuToken !== 'string' || herokuToken.split('-')?.length !== 5

        if(herokuTokenIsInvalid)
        {
            return (
                <>
                    <Header />
                    <IonContent className='ion-padding'>
                        We're sorry but this resume link is invalid.
                    </IonContent>
                </>
            )
        }

        if(loading)
        {
            return centerLoadingIcon()
        }

        return renderInput()
    }

    return renderForm()
}

const centerLoadingIcon = () => (
    <IonCol className="spinner-container">
        <IonSpinner/>
    </IonCol>
)

const mapDispatchToProps = (dispatch: Function) => {
    return {
        initSession: (sessionId: string) => dispatch(actions.initSession(sessionId)),
        startSession: (payload: any) => dispatch(actions.startSession(payload)),
        setOnePathParameters: (payload: any) => dispatch(actions.setOnepathParams(payload))
    }
}

export default connect(null, mapDispatchToProps)(Resume);
import { OnepathActions } from '../actions/onePathActions';

export const initialState : onePathParameters = {
    hasOnePathParams: false, 
    viewMode: 'reg'
}

const onePathReducer = (state = initialState, action: OnepathActions) => {
    switch (action.type) {
        case 'SET_ONE_PATH_PARAMS': 
            return {
                ...state,
                ...action.params
            };
        default:
            return state;
    }
}

export default onePathReducer; 
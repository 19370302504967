import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { IonGrid, IonRow, IonCol, IonSelect, IonLabel, IonSelectOption, IonItem, IonCheckbox, IonInput, IonButton, IonItemDivider, IonText, IonIcon } from '@ionic/react';
import './Welcome.css';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { getTotalFee, SF_SYSTEM_USER_ID, feeArrangementFeeScheduleOnlyForm, PromoCodeData } from "../../helpers/utils";
import { EMAIL_REGEX } from "../../helpers/sharedUtils"
import { InitSessionApp, SessionAppWithSharedParams } from "../../helpers/sessionInterfaces"
import { startApp, saveWelcomePage, saveFeeScheduleToFeeArrangementPage, getAllSalesReps, getPromoCodeById, getPromoCodeByName, saveAppPage } from '../../helpers/calloutHelpers'
import { checkmarkCircle } from 'ionicons/icons';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';


interface WelcomePage extends InitSessionApp, SessionAppWithSharedParams {
    initSession: typeof actions.initSession,
    setShowSpinner: typeof actions.setShowSpinner,
    welcomePageFields: welcomePageParameters,
    setShowSuccessToast: Function,
    setSuccessMessage: Function,
    setWelcomePageFields: typeof actions.setWelcomePageFields,
}


const Welcome: React.FC<WelcomePage> = (props) => {
    const [promoCodeData, setPromoCodeData] = useState<PromoCodeData>({
        name: null,
        discount_amount: null,
        payment_options: null,
        salesforce_id:  null,
        promoCodeLoaded: false
    });
    const {setShowSpinner} = props;
    useEffect(() => {
        getPromoCodeById(props.welcomePageFields.promo_code).then(promoCodes => {
            if (promoCodes.length > 0) {
                const promoCode = promoCodes[0];
                setPromoCodeData({
                    name: promoCode.name || null,
                    discount_amount: promoCode.discount_amount || null,
                    payment_options: promoCode.payment_options || null,
                    salesforce_id: promoCode.salesforce_id || null,
                    promoCodeLoaded: true
                });
            }else {
                setPromoCodeData((prev)=>({
                    ...prev,
                    promoCodeLoaded: true
                }));
            }                
            setShowSpinner(false)
        }).catch(err => {
            setShowSpinner(false)
            throw err; 
        })
    },[props.welcomePageFields.promo_code, setShowSpinner, setPromoCodeData])
    
    if(promoCodeData.promoCodeLoaded){
        return <WelcomeForm promoCodeData={promoCodeData} {...props}/>
    }
    
    return <></>
}

const WelcomeForm: React.FC<WelcomePage & {promoCodeData: PromoCodeData}> = (props) => {
    const [salesRepOptions, setSalesRepOptions] = useState<{name: string, salesforce_id: string}[]>([]);
    const {promo_code} = props.welcomePageFields;
    const {setShowSpinner} = props;
    const {is401kApplication, isDYO401kApplication, setWelcomePageFields} = props;
    let location:any = useLocation();
    
    let accountTypes = is401kApplication ? ['Traditional 401k', 'Roth 401k', 'Both Traditional and Roth 401k']:[
        'Traditional IRA',
        'Roth IRA',
        'SEP IRA',
        'Inherited IRA - Traditional',
        'Inherited IRA - Roth'
    ]

    const defaultValues = useMemo(() => ({
        account_type: props.welcomePageFields.account_type?.includes('401k') ? props.welcomePageFields.account_type_401k : props.welcomePageFields.account_type,
        roth_acknowledgment_dyo: props.welcomePageFields.roth_acknowledgment_dyo,
        is_self_employed: props.welcomePageFields.is_self_employed,
        has_plan_eligible_employees: props.welcomePageFields.has_plan_eligible_employees,
        is_employer_part_of_group: props.welcomePageFields.is_employer_part_of_group,
        understand_irs_rules: props.welcomePageFields.understand_irs_rules,
        open_third_party_trading_account: props.welcomePageFields.open_third_party_trading_account,
        sales_rep: props.welcomePageFields.sales_rep,
        promo_code: promo_code,
        first_name: props.welcomePageFields.first_name || '',
        last_name: props.welcomePageFields.last_name || '',
        email: props.welcomePageFields.email || '',
    }), [props.welcomePageFields, promo_code])

    const {handleSubmit, errors, control, setValue } = useForm<WelcomePageFormFields>({defaultValues});

    const promoCodeFormField = useWatch<string>({control, name: "promo_code"});

    const [promoCodeData, setPromoCodeData] = useState<PromoCodeData>(props.promoCodeData);

    const handleAccountTypeSelected = (event: CustomEvent, onChange: Function) =>{
        let accountType = event.detail.value as string
        onChange(accountType)
    }

    const resolveAccountType = (selectedAccountType: string) => {
        if (selectedAccountType.includes('Inherited')) {
            return {
                account_type: selectedAccountType
            }
        }
        
        if (selectedAccountType.includes('401k')) {
            let accountType = getAccountTypeFor401k(selectedAccountType);
            
            if (props.isDYO401kApplication){
                accountType = 'Individual 401k - DYO';
            }
            return {
                account_type: accountType, 
                account_type_401k: selectedAccountType
            }
        }

        return {account_type:selectedAccountType}
    }

    const getAccountTypeFor401k =  (accountType: string) => {
        switch (accountType) {
        case 'Traditional 401k':
            return 'Individual 401k - Traditional';
        case 'Roth 401k':
            return 'Individual 401k - Roth'; 
        case 'Both Traditional and Roth 401k':
            return 'Individual 401k - Both Traditional and Roth';
        default:
         return accountType;    
        }
    }

    const handleApplicantInformationChange = (event: any, onChange: Function) => {
        onChange(event)
    }

    const savePage = async (data: welcomePageParameters) => {
        const accountType = resolveAccountType(data.account_type)
        if(accountType.account_type_401k){
            data.account_type = accountType.account_type
            data.account_type_401k = accountType.account_type_401k
        }

        props.setWelcomePageFields(data)
        try{
            if (!props.sessionId) {
                const sessionId = await startApp(data)
                if(sessionId)
                {
                    await saveAppPage(sessionId, data, props?.onePathParameters?.viewMode)
                    props.initSession(sessionId)
                    return await saveFeeScheduleIfBusinessFeeSchedule(sessionId)
                }else{
                    console.error('failed to start app')
                }
            } else {
                await saveWelcomePage(props.sessionId, data)
                await saveAppPage(props.sessionId, data,  props.onePathParameters?.viewMode)
                return await saveFeeScheduleIfBusinessFeeSchedule(props.sessionId)
            }
        }catch(err)
        {
            console.error(err)
        }
    }

    const checkReferralCode = async () => {
        setShowSpinner(true)
        const promoCodes = await getPromoCodeByName(promoCodeFormField as string)

        if(promoCodes.length === 0){
            setShowSpinner(false)
            return
        }

        if (promoCodes.length === 0) {
            setValue('promo_code', '');
            props.setErrorMessage('Invalid Referral / Group Code provided');
            props.setShowErrorToast(true);
        }
        else {
            const promoCode = promoCodes[0];
            if(promoCode.discount_amount === null || promoCode.payment_options === null){
                return
            }
            setPromoCodeData({
                name: promoCode.name || '', 
                discount_amount: promoCode.discount_amount || 0,
                payment_options: promoCode.payment_options || '',
                salesforce_id: promoCode.salesforce_id || '',
                promoCodeLoaded: true
            });
            setValue('promo_code', promoCode.salesforce_id)
            props.setSuccessMessage('Referral / Group Code was successfully applied!');
            // props.skipPaymentForm()
            // props.updateMenuItem('is_payment_information_page_valid', true, props.sessionId)
            props.setShowSuccessToast(true);
        }
        setShowSpinner(false)
    }

    useEffect(()=>{
        setShowSpinner(true)
        getAllSalesReps().then(response => response.json()).then((data:{data:Array<{salesforce_id:string, name:string}>}) => {
            setSalesRepOptions([{name:'Not Applicable', salesforce_id: SF_SYSTEM_USER_ID}, ...data.data])

            setShowSpinner(false)
        }).catch(err => {
            setShowSpinner(false)
            console.log(err)
        })
    },[setShowSpinner])

    const updatePaymentFormAccess = useCallback(() =>{
        if(!promoCodeData.salesforce_id){
            return
        }

        let totalFee = getTotalFee(is401kApplication, isDYO401kApplication);
        const hidePaymentForm = promoCodeData?.payment_options === 'Hide Credit Card' || promoCodeData.discount_amount === totalFee;
        setWelcomePageFields({
            promo_code: promoCodeData.salesforce_id,
            payment_form: hidePaymentForm
        })
    },[promoCodeData, is401kApplication, isDYO401kApplication, setWelcomePageFields])

    useEffect(() => {
        if(!promoCodeData.salesforce_id){
            return
        }

        updatePaymentFormAccess()

        // let totalFee = getTotalFee(props.is401kApplication, props.isDYO401kApplication);
        // if(promoCodeData?.payment_options === 'Hide Credit Card' || promoCodeData.discount_amount === totalFee) {
        //     // we should move 'payment_form' someplace else
        //     props.setWelcomePageFields({
        //         promo_code: promoCodeData.salesforce_id,
        //         payment_form: false
        //     })
        // }
        
        if (promo_code && promo_code !== '' && promo_code.length === 18 && promo_code.startsWith('a4')) {
            setShowSpinner(true)
            // getPromoCodeById(promo_code).then(promoCodes => {
            //     if (promoCodes.length > 0) {
            //         setPromoCodeData(prevState => {return {...prevState, name: promoCodes[0].name, discount_amount: promoCodes[0].discount_amount, payment_options: promoCodes[0].payment_options}});    
            //         // props.skipPaymentForm(true)
            //     }                
            //     setShowSpinner(false)
            // }).catch(err => {
            //     setShowSpinner(false)
            //     throw err; 
            // })   
        }
    },[promoCodeData, setShowSpinner, updatePaymentFormAccess])

    const save = async (data: WelcomePageFormFields) => {
        const mergedData: welcomePageParameters = {
            ...props.welcomePageFields,
            ...data
        }

        if (props.is401kApplication && (!mergedData.is_self_employed || mergedData.has_plan_eligible_employees)){
            props.setErrorMessage('Based on your responses, you do not qualify for an Individual 401k plan. Please contact Equity Trust at 239.333.1032 Option 2.');
            props.setShowErrorToast(true);
            return;
        }

        if (mergedData.promo_code && !promoCodeData.name && !promo_code) {
            props.setErrorMessage('Referral/Group Code was not verified. Please click the Apply button.');
            props.setShowErrorToast(true);
            return;    
        }

        setShowSpinner(true)
        try{
            const sessionId = await savePage(mergedData)
            if(sessionId === undefined){
                console.log('failed to save app initially no sessionId provided');
                return;
            }
            props.updateMenuItem('is_welcome_page_valid', true, sessionId);
            props.handleNavigation({status: "Success"});
        }catch(err){
            console.error(err)
        }
        setShowSpinner(false)
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = errorsArr.includes(fieldName) ? 'danger' : '';

        if (fieldName === 'email' && errorsArr.includes('email')) {
            className = 'danger';
        }
        if (fieldName === 'understand_irs_rules' && !props.welcomePageFields.understand_irs_rules){
            className = 'danger';
        }
        if (fieldName === 'roth_acknowledgment_dyo' && !props.welcomePageFields.roth_acknowledgment_dyo) {
            className = 'danger';
        }
        return className;
    };

    const onInvalidForm = (e: any) => {
        let message = ''
        if(e?.email?.type === 'pattern') {
            message = 'The email provided does not appear valid'
        }
        props.handleNavigation({status: "Error", message});
    }

    const saveFeeScheduleIfBusinessFeeSchedule = (sessionId: string) => {
        if (location?.state?.hasOwnProperty('useBusinessFeeSchedule') || (props.onePathParameters?.fee_schedule !== null && props.onePathParameters?.fee_schedule !== undefined)) {
            
            let feeScheduleData : feeArrangementFeeScheduleOnlyForm = {fee_schedule : 'Business Fee Schedule ($150)'};
            if (props.onePathParameters?.fee_schedule !== undefined) {
                feeScheduleData = {fee_schedule : props.onePathParameters?.fee_schedule};
            }
            
            return saveFeeScheduleToFeeArrangementPage(sessionId, feeScheduleData)?.then(() => {
                return sessionId;
            });
        }
        return sessionId;
    }

    const getAccountTypeFieldLabel = (is40kApplication : Boolean) => {
        if (is40kApplication) {
            return 'What type of Self-Directed 401k would you like to open? *'
        }
        return 'What type of Self-Directed IRA would you like to open? *'
    }

    return (
            <form ref={props.formRef} onSubmit={handleSubmit(save,onInvalidForm)} className='ion-padding'>
             <IonGrid> 
                <WelcomePageIntro is401k={props.is401kApplication} isDyp401k={props.isDYO401kApplication}/>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel color='primary'>
                            <strong>Legal First Name *</strong>
                        </IonLabel>
                        <IonItem className={showError('first_name')}>
                            <Controller defaultValue={''} name='first_name' control={control} render={({ name, value, onBlur, onChange }) =>
                                <IonInput name={name} value={value} onIonBlur={onBlur} placeholder="First Name" clearInput maxlength={50} onIonChange={event => handleApplicantInformationChange(event, onChange)} />} rules={{ required: true, maxLength: 50 }} 
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel color='primary'>
                            <strong>Legal Last Name *</strong>
                        </IonLabel>
                        <IonItem className={showError('last_name')}>
                            <Controller name='last_name' control={control} render={({ name, value, onBlur, onChange }) =>
                                <IonInput name={name} value={value} placeholder="Last Name" clearInput maxlength={100} onIonBlur={onBlur} onIonChange={event => handleApplicantInformationChange(event, onChange)} />
                            } rules={{ required: true, maxLength: 100 }} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel color='primary'>
                            <strong>Email *</strong>
                        </IonLabel>
                        <IonItem className={showError('email')}>
                            <Controller name='email' control={control} render={({ name, value, onBlur, onChange }) =>
                                <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={event => handleApplicantInformationChange(event, onChange)} placeholder='Email' required={true} clearInput>
                                </IonInput>
                            } rules={{
                                required: true,
                                pattern: new RegExp(EMAIL_REGEX)
                            }} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="6" sizeSm="12">
                        <IonLabel color="primary" id="ion-sel-0-lbl">
                            <strong>
                                {getAccountTypeFieldLabel(props.is401kApplication)}
                            </strong>
                        </IonLabel>
                        <IonItem className={showError('account_type')}>
                            <Controller name='account_type'
                                control={control}
                                render={({value, onChange, name})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={event=>{
                                            handleAccountTypeSelected(event, onChange)
                                            if(props.isDYO401kApplication && event.detail.value.includes('Roth')){
                                                setValue('roth_acknowledgment_dyo', false)
                                            }
                                        }}>
                                        {accountTypes.map((accountType, index) => 
                                        (<IonSelectOption key={index} value={accountType}>
                                            {accountType}
                                        </IonSelectOption>))}
                                    </IonSelect>
                                }rules={{required:true}}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                {props.isDYO401kApplication && props.welcomePageFields.account_type_401k.includes('Roth 401k') && (
                    <IonRow>
                        <IonCol>
                            <Controller
                                name='roth_acknowledgment_dyo'
                                control={control}
                                render={({name, value, onChange})=> <IonCheckbox tabIndex={0}  aria-label='checkbox' name={name} checked={value} className={showError('roth_acknowledgment_dyo')} onIonChange={(event)=>onChange(event.detail.checked)}
                                />}
                                rules={{validate:(value)=>value}}
                            />
                            &nbsp;
                           
                            <b>
                                I acknowledge and understand that it is my responsibility as the administrator to keep track of Roth contributions, principal, and earnings separately from any traditional contributions, principal, and earnings. * 
                            </b> 
                        </IonCol>
                    </IonRow>
                )}
                <IonRow>
                    <IonCol>
                        <i>
                            {props.is401kApplication && !props.isDYO401kApplication && (
                                <p>
                                    <i>Equity Trust will provide plan documents as well as the administration of assets held within the plan.  This plan is only eligible for individuals who are self-employed with no eligible employees other than a spouse/partner.</i>
                                </p>
                            )}
                            {props.isDYO401kApplication &&  (<p><i>
                                    Equity Trust will ONLY provide plan documents.  Clients will be responsible for setting up bank accounts, administration, investment purchases/sales, and record-keeping of all income/expenses associated with the plan.  This plan is only eligible for individuals who are self-employed with no eligible employees other than a spouse
                                </i></p>) }
                        </i>
                    </IonCol>
                </IonRow>
                {props.is401kApplication && (
                    <>
                    <IonRow>
                        <IonCol sizeMd="6" sizeSm="12"> 
                            <IonLabel color='primary' id='ion-sel-1-lbl'>
                                <strong>Are you self-employed? </strong>
                            </IonLabel>
                            <IonItem className={showError('is_self_employed')}>
                                <Controller
                                    name='is_self_employed'
                                    control={control}
                                    render={({name, value, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode:'ios'}} value={value} onIonChange={(event)=>onChange(event.detail.value)}>
                                        <IonSelectOption value={true}>
                                            Yes
                                        </IonSelectOption>
                                        <IonSelectOption value={false}>
                                            No
                                        </IonSelectOption>
                                    </IonSelect>}
                                    rules={{validate:(value)=>(value !== undefined)}}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeMd="6" sizeSm="12">
                            <IonLabel color='primary' id='ion-sel-2-lbl'>
                                <strong>Do you have any plan eligible employees other than a spouse? </strong>
                            </IonLabel>
                            <IonItem className={showError('has_plan_eligible_employees')}>
                                <Controller
                                    name='has_plan_eligible_employees'
                                    control={control}
                                    render={({value, name, onChange})=>
                                    <IonSelect name={name} interface='action-sheet'interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={(event)=>onChange(event.detail.value)}>
                                        <IonSelectOption value={true}>
                                            Yes
                                        </IonSelectOption>
                                        <IonSelectOption value={false}>
                                            No
                                        </IonSelectOption>
                                    </IonSelect>}
                                    rules={{validate:(value)=>(value !== undefined)}}
                                    
                                    />
                                
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel color='primary' id="ion-sel-3-lbl">
                                <strong>The employer is part of a controlled group or affiliated service group?</strong>
                            </IonLabel>
                            <p>
                            <i>Under the IRS’ controlled group rules, two or more employers with common ownership are considered a single employer for purposes for 401k testing. 
                                For additional information, see the following IRS publication: <a href='https://www.irs.gov/pub/irs-tege/epchd704.pdf'>https://www.irs.gov/pub/irs-tege/epchd704.pdf</a></i>
                             </p>  
                             <IonRow>
                                <IonCol sizeMd="6" sizeSm="12">
                                    <IonItem className={showError('is_employer_part_of_group')}>
                                        <Controller
                                            name='is_employer_part_of_group'
                                            control={control}
                                            render={({name, value, onChange})=>
                                            <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={(event)=>{
                                                onChange(event.detail.value)}}>
                                                <IonSelectOption value={true}>
                                                    Yes
                                                </IonSelectOption>
                                                <IonSelectOption value={false}>
                                                    No
                                                </IonSelectOption>
                                            </IonSelect>}
                                            rules={{validate:(value)=>(value !== undefined)}}
                                        />                                        
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            {props.welcomePageFields.is_employer_part_of_group && (
                                <>
                                    <Controller
                                        name='understand_irs_rules'
                                        control={control}
                                        render={({name, value, onChange})=><IonCheckbox tabIndex={0} checked={value} name={name} value={value} className={showError('understand_irs_rules')} onIonChange={(event)=>onChange(event.detail.checked)}/>}
                                        rules={{validate:(value)=>value}}
                                    />                                    
                                    &nbsp; 
                                    <IonLabel color='primary'>
                                        <strong>I understand the IRS’ rules and regulations on controlled or affiliated service groups and agree to proceed. *</strong>
                                    </IonLabel>
                                </>
                            )}      
                        </IonCol>
                    </IonRow>
                    </>
                )}
                {!props.onePathParameters?.owner_id && <IonRow>
                    <IonCol sizeMd="6" sizeSm="12">
                        <IonLabel color='primary' id={!props.is401kApplication
                             ? 'ion-sel-2-lbl' : (props.isDYO401kApplication ? 'ion-sel-4-lbl' : 'ion-sel-6-lbl')}>
                            <strong>Have you been working with a Equity Trust rep?</strong>
                        </IonLabel>
                        <IonItem className={showError('sales_rep')}>
                            <Controller
                                name='sales_rep'
                                control={control}
                                render={({name, value, onChange})=>
                                <IonSelect name={name}  interface='action-sheet' interfaceOptions={{cssClass: 'sales-rep-select',animated: false, mode: 'ios'}} value={value} onIonChange={(event)=>onChange(event.detail.value)}>
                                    {salesRepOptions.map((rep, index) => (<IonSelectOption value={rep.salesforce_id} key={index}>{rep.name}</IonSelectOption>))}
                                </IonSelect>}
                            />                        
                        </IonItem>
                    </IonCol>
                </IonRow>}
                <IonRow>
                    {(promoCodeData.name == null && !props.onePathParameters?.promo_code) && 
                        <>
                            <IonCol sizeMd="6" sizeSm="6" sizeXs="9">
                                <IonLabel color='primary' id={'ion-input-1-lbl'}>
                                    <strong>Referral / Group Code (Optional)</strong>
                                </IonLabel>
                                <IonItem>
                                    <Controller control={control} name='promo_code'  defaultValue={promo_code} render={({value, name, onChange})=> 
                                        <IonInput name={name} value={value} onIonChange={onChange}/>
                                    }/>
                                </IonItem>
                            </IonCol>
                            <IonCol sizeMd="2" sizeSm="2" sizeXs="3" class="ion-margin-top">
                                <IonButton color="primary" onClick={checkReferralCode} disabled={promoCodeFormField?.trim() === ''}>Apply</IonButton>
                            </IonCol>
                        </>
                    }
                    {typeof promoCodeData.name === 'string' && 
                        <IonCol sizeMd="6" sizeSm="12">
                            <IonLabel color='primary'>
                                <strong>Referral / Group Code</strong>
                            </IonLabel>
                            {promoCodeData.payment_options !== 'Hide Credit Card' &&
                                <IonItem class='successItem' lines='none'>
                                    <IonIcon color='success' size="small" icon={checkmarkCircle}/>
                                    {promoCodeData.name} applied for ${promoCodeData.discount_amount} off setup fee.
                                </IonItem>
                            }
                            {promoCodeData.payment_options === 'Hide Credit Card' && 
                                <IonItem class='successItem' lines='none'>
                                        <IonIcon color='success' size="small" icon={checkmarkCircle}/> &nbsp; 
                                    {promoCodeData.name} applied.
                                </IonItem>
                            }
                        </IonCol>  
                    }
                </IonRow>
             </IonGrid>
            </form>
    );
}

const WelcomePageIntro: React.FC<{is401k?: Boolean, isDyp401k?: Boolean}> = ({is401k, isDyp401k}) => {
    return <>
        <IonRow color="medium" className="well">
            <IonCol>
                <p>
                    <strong>
                        EQUITY TRUST ONLINE APPLICATION: &nbsp;
                    </strong>
                    This online application will take about 10-15 minutes to complete. Before you start, we recommend having a credit card, photo ID, beneficiary information, and a current retirement plan statement on hand.
                </p>
                <p>
                    <strong>
                    IMPORTANT NOTICE: &nbsp;
                </strong>
                    Federal law requires all financial institutions to obtain, verify and record information that identifies each person who opens an account. We require that you provide your name, date of birth, and taxpayer ID.
                </p>
                <p>
                    <strong>
                    THIS IS A SECURE APPLICATION: &nbsp;
                    </strong>
                    Equity Trust uses several security features including browser encryption to ensure the privacy and security of your personal financial information.
                </p>
            </IonCol>
        </IonRow>
        {is401k && !isDyp401k && 
        (
            <IonItemDivider>
                <strong>
                        <IonText color='primary'>
                        Individual 401k (Equity Trust Administered)
                        </IonText>
                    </strong>
            </IonItemDivider>
        )}
        {isDyp401k && (
            <IonItemDivider>
                <IonText color='primary'>
                    <strong>Individual 401k (Do Your Own Administration)</strong>
                </IonText>
            </IonItemDivider>
        )}
    </>
}

const mapStateToProps = (state: StoreState) => {
    return {
        welcomePageFields: state.welcomePage,
        is401kApplication: state.menu.menuParams.is401kApplication,
        isDYO401kApplication: state.menu.menuParams.isDYO401kApplication,
        sessionId: state.session.sessionId,
        onePathParameters: state.onePathParams,
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => {
    return {
        setWelcomePageFields: (payload: Partial<welcomePageParameters>) => dispatch(actions.setWelcomePageFields(payload)),
        initSession: (sessionId: string) => dispatch(actions.initSession(sessionId)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
        // skipPaymentForm: (shouldSkip:boolean) => dispatch(actions.skipPaymentForm(shouldSkip)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
import React, { useEffect, useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { makeGetPageInfoCallout, getValidatedPages } from '../helpers/calloutHelpers';
import { StoreState } from '../store/reducers/rootReducer';
import * as actions from '../store/actions';
// import Page from '../pages/Page';
import SessionPage from '../components/SessionManagement'
import { connect } from 'react-redux';
import OnePathLoadingPage from './OnePathLoadingPage'
import { PageNameValidator, PageNames } from './LoadPage'
import { PageValidationParameters } from '../helpers/menuGenerator';

type InitPage = {
  sessionId: string,
  setMenuParams: Function,
  initWelcomePageFields: (payload: Partial<welcomePageParameters>) => void,
  welcomePageFields: welcomePageParameters,
}

const RenderPage: React.FC<{token: string|PageNames, welcomePageInitialized: boolean}> = ({token, welcomePageInitialized}) => {
  if(PageNameValidator(token)){
    return welcomePageInitialized ? <SessionPage/> : <>Loading ...</>
  }

  if(typeof token === 'string')
  {
    return (<OnePathLoadingPage onePathToken={token}/>)
  }

  return (<> Invalid path </>)
}

const InitializePage: React.FC<InitPage> = (props) => {
  const { sessionId, setMenuParams, initWelcomePageFields, welcomePageFields } = props;
  const [loadedValidatedPages, setLoadedValidatedPages] = useState<Partial<PageValidationParameters>>({})
  const [loadedPageInfo, setLoadedPageInfo] = useState<Partial<welcomePageParameters>>({})
  useEffect(() => {
    const loadValidatedPages = async () => {
      if(!sessionId){
        return
      }
      // leaving console log for now
      console.log('load validated pages called')
      const result = await getValidatedPages(sessionId)
      setLoadedValidatedPages(result);
    }
    loadValidatedPages();
  }, [sessionId])

  useEffect(() => {
    const loadPageInfo = async () => {
      if(!sessionId){
        return 
      }
      let result = await makeGetPageInfoCallout<postgresSchema.WelcomePage>(sessionId, 'welcome')
      const welcomePage: welcomePageParameters = {
        account_type: result?.account_type || '',
        payment_form: result?.payment_form || false,
        account_type_401k: result?.account_type_401k || '',
        deal_opp: result?.deal_opp || '',
        email: result?.email || '',
        first_name: result?.first_name || '',
        has_plan_eligible_employees: result?.has_plan_eligible_employees || false,
        has_read_diclosure: result?.has_read_diclosure || false,
        is_employer_part_of_group: result?.is_employer_part_of_group || false,
        is_self_employed: result?.is_self_employed || false,
        last_name: result?.last_name || '',
        open_third_party_trading_account: result?.open_third_party_trading_account || false,
        promo_code: result?.promo_code || '',
        roth_acknowledgment_dyo: result?.roth_acknowledgment_dyo || false,
        sales_rep: result?.sales_rep || '',
        understand_irs_rules: result?.understand_irs_rules || false 
      }
      setLoadedPageInfo(welcomePage)
    }
    loadPageInfo();
  }, [sessionId])

  const location = useLocation<{is401k?: boolean, isDYO401k?: boolean}>();
  const { name } = useParams<{ name: string; }>();

  const getNewMenuParams = useCallback((menuParams: Partial<PageValidationParameters>, welcomePageFields: Partial<welcomePageParameters>) => {
    const newMenuParams: Partial<MenuParameters> = {...menuParams}
    if(!welcomePageFields){
      return
    }
    newMenuParams.planInfo = welcomePageFields?.account_type?.includes('SEP') || false
    if ((location?.state?.is401k) || welcomePageFields.account_type?.includes('401k')) {
      newMenuParams.is401kApplication = true;
    }
    if ((location?.state?.isDYO401k) || welcomePageFields.account_type?.toUpperCase().includes('DYO')) {
      newMenuParams.is401kApplication = true;
      newMenuParams.isDYO401kApplication = true;
    }

    return newMenuParams
  },[location?.state?.is401k, location?.state?.isDYO401k]) 


  const loadMenuParams = useCallback(()=>{
    let formParams = getNewMenuParams(loadedValidatedPages, loadedPageInfo);
    setMenuParams(formParams)
    initWelcomePageFields(loadedPageInfo)
  },[ getNewMenuParams, setMenuParams, loadedPageInfo, loadedValidatedPages, initWelcomePageFields])



  useEffect(() => {
    loadMenuParams();
  }, [loadMenuParams])


  return <RenderPage token={name} welcomePageInitialized={!!welcomePageFields.initialized}/>
}

const mapStateToProps = (state: StoreState) => {
  return {
    welcomePageFields: state.welcomePage,
    menuParams: state.menu.menuParams,
    sessionId: state.session.sessionId
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    initWelcomePageFields: (payload: Partial<welcomePageParameters>) => dispatch(actions.initWelcomePageFields({ ...payload, initialized: 'INIT_PAGE'})),
    setMenuParams: (payload: any) => dispatch(actions.setMenuParams(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InitializePage);
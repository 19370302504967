import { PaymentBody, FormData, feeArrangementFeeScheduleOnlyForm } from './utils'
import { updateLastActivity } from '../store/actions';
import { PageName } from 'shared-utils'

type AppSession = API.AppSession

export function resumeCallout(herokuToken: string, authInput?: ResumeForm) {
    let url = '/resume';
    let body : API.resumeRequest ={
        appExternalId: herokuToken,
        data: authInput
    }

    let options ={
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }

    return fetch(url, options)
}

export function getOnePathDataFromSession(sessionId: string) {
    let url = '/getOnePathDataFromSession';
    let body= {sessionId};
    let options = {
        method: 'POST', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };
    return fetch(url, options);
}

export async function startApp(formData: welcomePageParameters):Promise<string|undefined>{
    let formDataToSubmit: welcomePageParameters = {
        ...formData,
        initialized: undefined
    }

    const response = await makeStartApplicationCallout(formDataToSubmit)

    if(response.ok){
        try{
            const responseBody = await response.json()
            return responseBody.sessionId
        }catch(err){
            console.log(err);
            return undefined
        }
    }
    return undefined
}

export function saveWelcomePage(sessionId: string, formData: welcomePageParameters){
    let formDataToSubmit: welcomePageParameters = {
        ...formData,
        initialized: undefined
    }
    return makeSaveStateCallout(sessionId, 'welcomePage', formDataToSubmit)
}

export function getIsBankRouteValid(routingNumber: string, sessionId: string){
    let url= '/bankRoutes';
    let body = {sessionId, routingNumber}; 
    let options = {
        method: 'POST', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };
    return fetch(url, options);
}

export function getAllCustodians(sessionId: string) {
    let url = '/custodians';
    let body = {sessionId};
    let options = {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }
    return fetch(url, options);
}

export function getAllSalesReps() {
    let url = '/salesReps';
    let options = {
        method : 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(url, options);
}

export async function getPromoCodeById(promoCodeId: string) {
    let url = '/promoCode?promoCodeId=' + promoCodeId;
    let options = {
        method : 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    const result = await fetch(url, options);
    try{
        return (await result.json() as API.PromoCodeResponseBody).data
    }catch(err){
        return []
    }
}

export async function getPromoCodeByName(promoCodeName: string) {
    let url = '/promoCode?promoCodeName=' + promoCodeName;
    let options = {
        method : 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    const result = await fetch(url, options);
    try{
        return (await result.json() as API.PromoCodeResponseBody).data
    }catch(err){
        return []
    }
}

export function saveAppPage(sessionId: string, formData: Forms.applicantIdForm, viewMode: ViewMode|undefined, startSession?: Function){
    return makeSaveStateCallout(sessionId, 'appId', formData, viewMode)?.then((result?:any)=>{
        if(startSession) {
            startAppSession(startSession, result)
        }
    })
}

function startAppSession(startSession: Function, result?:AppSession){
    if( result && result.last_active && result.heroku_token && result.session_length){
        let herokuToken = result.heroku_token
        let lastActive = result.last_active
        let sessionLength = result.session_length
        startSession({
            herokuToken,
            lastActive,
            sessionLength
        })
    }
}

export function getAppPage(sessionId: string) {
    return makeGetPageInfoCallout(sessionId, 'appId');
}

export function saveOwnerQuestionnairePage(sessionId: string, formData: Forms.applicantOwnerQuestionnaireForm) {
    return makeSaveStateCallout(sessionId, 'ownerQuestionnaire', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function saveBenePage(sessionId: string, formData: {beneficiaries: Array<SubForms.beneficiary>, spousal_consent_acknowledged: boolean}, updateLastActivity: Function) {
    return makeSaveStateCallout(sessionId, 'beneficiary', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function getBenePage(sessionId: string){
    return makeGetPageInfoCallout(sessionId, 'beneficiary')
}

export function saveFeeArrangementPage(sessionId: string, formData: Forms.feeArrangementForm, updateLastActivity: Function){
    return makeSaveStateCallout(sessionId, 'feeArrangement', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function getFeeArrangementPage(sessionId: string){
    return makeGetPageInfoCallout(sessionId, 'feeArrangement')
}

export function saveFeeScheduleToFeeArrangementPage(sessionId: string, feeScheduleData: feeArrangementFeeScheduleOnlyForm){
    return makeSaveStateCallout(sessionId, 'feeArrangement', feeScheduleData);
}

export function saveAccountNotificationsPage(sessionId: string, formData: Forms.accountNotificationsForm, updateLastActivity: Function){
    return makeSaveStateCallout(sessionId, 'accountNotification', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function getAccountNotificationsPage(sessionId: string){
    return makeGetPageInfoCallout(sessionId, 'accountNotification')
}

export function saveTransfers(sessionId: string, formData: FormData, updateLastActivity: Function){
    return makeSaveStateCallout(sessionId, 'transfer', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function saveContribution(sessionId: string, formData: FormData, updateLastActivity: Function){
    return makeSaveStateCallout(sessionId, 'contribution', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function saveRollovers(sessionId: string, formData: FormData, updateLastActivity: Function){
    return makeSaveStateCallout(sessionId, 'rollover', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function saveCheckout(sessionId: string, formData: FormData, updateLastActivity: Function){
    return makeSaveStateCallout(sessionId, 'checkOut', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function getFundingOptionsPage(sessionId: string){
    return makeGetPageInfoCallout(sessionId, 'fundingOptions')
}

export function saveInitialInvestmentPage(sessionId: string, formData: FormData, updateLastActivity: Function){
    return makeSaveStateCallout(sessionId, 'initial_investment', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function getInitialInvestmentPage(sessionId: string){
    return makeGetPageInfoCallout(sessionId, 'initial_investment');
}

export function savePlanInfoPage(sessionId: string, formData: FormData, updateLastActivity: Function) {
    return makeSaveStateCallout(sessionId, 'planInfo', formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function getPlanInfoPage(sessionId: string){
    return makeGetPageInfoCallout(sessionId, 'planInfo'); 
}

export function getPaymentInfoPage(sessionId: string){
    return makeGetPageInfoCallout(sessionId, 'paymentInfo'); 
}

export function saveEmployerSponsorPage(sessionId: string, formData: FormData, updateLastActivity: Function) {
    return makeSaveStateCallout(sessionId, 'employerSponsor' , formData)?.then((result?:AppSession)=>{
        return updateActivity(updateLastActivity, result)
    })
}

export function getEmployerSponsorPage(sessionId: string){
    return makeGetPageInfoCallout(sessionId, 'employerSponsor');
}

function updateActivity(updateLastActivity: Function, result?:AppSession){
    if(result && result.last_active && updateLastActivity){
        updateLastActivity(result.last_active);
    }
    return result
}

function makeStartApplicationCallout(formData: welcomePageParameters){
    let url = '/startApplication'
    let body : API.RequestBody = {
        session: {sessionId: '', page: 'welcomePage'},
        data: formData
    }
    let options = {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }
    return fetch(url, options);
}

function makeSaveStateCallout(sessionId: string, page: string, bodyData: any, viewMode?: ViewMode){
    let url = '/saveState'
    let body : API.RequestBody= {
        session: {sessionId: sessionId, page: page, viewMode},
        data: bodyData
    }
    let options = {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }

    if(!sessionId || sessionId === ''){
        return null;
    }

    return fetch(url, options).then((response)=>{
        if(response.ok){
            return response.json().then((result:AppSession) => {
                return result;
            })
        }
        return undefined;
    }).catch((err)=>{
        console.log(err)
        return undefined
    });
}

export const makeGetPageInfoCallout = async <t extends postgresSchema.Tables[keyof postgresSchema.Tables]>(sessionId: string, page: PageName):Promise<t|undefined> => {
    let url = '/getPageFields'
    let body : API.RequestBody ={
        session:{sessionId: sessionId, page: page},
        data: undefined
    }

    let options = {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }

    console.log(`url: ${url}`)
    let response
    try{
        response = await fetch(url, options)
    }catch(err){
        console.error(err);
        return
    }

    try{
        return await response.json() as t
    }catch(err){
        console.error(err);
        return
    }
}

export const getValidatedPages = async (sessionId: string) => {
    const urlValidation = '/validatedPages'
    const optionsValidation = {
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'session-id': sessionId
        },
    }
    try{
        const response = await fetch(urlValidation, optionsValidation)
        if(response.ok)
        {
            return (await response.json()) as API.validatedPagesResponse
        }
    }catch(err){
        console.log(`err fetching validated pages`)
    }
    return {}
}

export function clientCheckIn(sessionId: string) {
    const body = { sessionId: sessionId }
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }
    fetch('/clientCheckIn', options)
}

export function sendResumeSessionEmail(body: API.ResumeEmailRequestBody) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }
    return fetch('/applicant-resume-email', options)
}

export const sendESignLink = (sessionId: string) => {
    const body = { sessionId: sessionId }
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }
    return fetch('/send-esign-link', options)
}

export function notifyDocusignNavigationOccurred(sessionId: string)
{
    const body = { sessionId: sessionId }
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }
    return fetch('/notifyDocusignNavigationOccurred', options)
}

//Salesforce related calls

export function uploadFile(sessionId: string, fileName: string, base64String: string) {
    let url = '/fileUpload';
    let body ={sessionId, fileName, base64String};
    let options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }
    return fetch(url, options);
}

export function createPayments(paymentBody:PaymentBody) {
    let url = '/createPayments'
    let options = {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(paymentBody)     
    }
    return fetch(url, options).then(function(response: any) {
        return response.json().then((data: any) => {
            if (response.status === 200) {
                return data;
            }
            else {
                throw Error(data.message);
            }         
        }).catch(function(error: any) {
            throw Error(error.message);
        })
    })
}

export const startOnePathSession =  async (one_path_token: string, app_id?: string | null, load_session?: string | null)=>{
    let url = '/startOnePathData'
    let body = {
        one_path_token,
        app_id,
        load_session
    }
    let options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }

    const result = await fetch(url, options)
    if(result.status === 200){
        return await result.json() as API.OnePathResponse
    }
    throw Error('Error starting one path session')
}

export function getESignUrl(sessionId: string)
{
    let url = '/esign-url'
    
    let options = {
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'session-id': sessionId
        },
    }

    return fetch(url, options).then(function(response: any){
        return response.json().then(function(data: any){
            if (response.status === 200) {
                return data;
            }
            else {
                throw Error(data.message);
            } 
        }).catch(function(error: any) {
            throw Error(error.message);
        })
    })
}

export function handleDocusignReturn(sessionId: string, eSignResult: string)
{
    let url = '/handleDocusignReturn'
    let body = {
        sessionId: sessionId,
        eSignResult: eSignResult
    }
    let options = {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)     
    }
    return fetch(url, options).then(function(response: any){
        return response.json().then(function(data: any){
            return data;
        }).catch(function(error: any) {
            throw Error(error);
        })
    })
}

export function downloadPenSignDocs(sessionId: string, eSignResult: string){
    return new Promise<string|undefined|null>((resolve, reject) => {
        const errorMessage = 'Error downloading pen sign docs'; 
        const xhr = new XMLHttpRequest();
        
        xhr.open('GET', `${window.location.origin}/pen-sign-documents?eSignResult=${eSignResult}`, true);

        xhr.setRequestHeader('session-id', sessionId);

        xhr.responseType = "arraybuffer";

        xhr.onload = function () {
            if (this.status === 200) {
                var blob = new Blob([xhr.response], {type: "application/pdf"});
                var objectUrl = URL.createObjectURL(blob);
                resolve(objectUrl);
            }
            if (this.status !== 200) {
                reject(errorMessage);
            }
        };

        xhr.addEventListener("error", (ev) => {
            reject(errorMessage);
        })

        xhr.send();
    })
}

export function updateValidationTable(page: string, isValid: boolean, sessionId: string){
    let validated_page :any = {}
    validated_page[page] = isValid
    let body: API.RequestBody ={
      session: {
        page: 'root',
        sessionId: sessionId
      },
      data: validated_page
    }
    let options = {
      method : 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }

    return fetch('/validatePage', options);
}

export function saveApplication(sessionId: string){
    let body: API.SaveStateRequestBody ={sessionId}
    let options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }
    return fetch('/saveApplication',options)
}

export function updateAppStatus(sessionId: string, status: Status){
    let body: API.StatusUpdateRequestBody ={sessionId, status}
    let options = {
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    }
    return fetch('/applicationStatus',options)
}
import React, { useEffect, useContext } from 'react';
import { useFormContext, useWatch, Controller, ArrayField } from 'react-hook-form';
import { IonRow, IonCol, IonButton, IonItemDivider, IonText, IonLabel, IonInput, IonSelectOption, IonSelect, IonItem, } from '@ionic/react';
import { FundingOptionsContext } from './steps/FundingOptions'

type RolloverFormProps = {item:Partial<ArrayField<SubForms.rollover, "id">>, applicationAccountType:string, rolloverNumber:number, remove():void, calcFundingAmount:Function}

const RolloverForm : React.FC<RolloverFormProps> = ({item, applicationAccountType, rolloverNumber, remove, calcFundingAmount}) => {
    const {errors, control} = useFormContext<{rollovers: Array<SubForms.rollover>}>()
    const { totalFundingBelowRequiredFunding } = useContext(FundingOptionsContext);
    
    const cashAmount: number|undefined = useWatch({
        name: `rollovers[${rolloverNumber}].cash_amount`,
        control
    })
    
    const showError = (fieldName: string) => {
        let rolloverErr = (errors === undefined || errors.rollovers === undefined || errors.rollovers[rolloverNumber] === undefined) ? {} : errors.rollovers[rolloverNumber]
        let errorsArr = (Object.keys(rolloverErr as any));
        let className = '';

        if (errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };

    const showAmountError = (fieldName: string) => {
        if (totalFundingBelowRequiredFunding) {
            return 'danger'
        }

        return showError(fieldName)
    };

    useEffect(()=>{
        calcFundingAmount();
    },[cashAmount])

    return (<div>
    <IonItemDivider>
    <p>
        <strong>
            <IonText color='primary'>
                Rollover {rolloverNumber + 1}
            </IonText>
        </strong>
    </p>
    <IonButton slot='end' onClick={remove}>Remove Rollover </IonButton>
</IonItemDivider>
<IonRow>
    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
        <IonLabel> Institution Name * </IonLabel>
        <IonItem className={showError(`institution_name`)}>
            <Controller name={`rollovers[${rolloverNumber}].institution_name`} defaultValue={item[`institution_name`]} control={control} render={ ({name, value, onBlur, onChange}) => 
                <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} placeholder='Institution Name' maxlength={255}/>
            } rules={{required: true}}/>
        </IonItem>
    </IonCol>
    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
        <IonLabel><strong>Amount (approximate value allowed) *</strong></IonLabel>
        <IonItem className={showAmountError(`cash_amount`)}>
            <Controller name={`rollovers[${rolloverNumber}].cash_amount`} defaultValue={item[`cash_amount`]} control={control} render={ ({name, value, onBlur, onChange}) =>
                <IonInput name={name} value={value} type='number' onIonBlur={onBlur} onIonChange={onChange}/>
            } rules={{required: true, pattern: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/}}/>
        </IonItem>
    </IonCol>
</IonRow>
<IonRow>
    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
        <IonLabel>
            Rollover Type *
        </IonLabel>
        <IonItem className={showError(`rollover_type`)}>
            <Controller name={`rollovers[${rolloverNumber}].rollover_type`} control={control} defaultValue={item[`rollover_type`]} render={ ({name, value, onBlur, onChange}) =>
                <IonSelect name={name}  interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonBlur={onBlur} onIonChange={onChange}>
                    <IonSelectOption value='Direct Rollover'>Direct Rollover</IonSelectOption>
                    <IonSelectOption value='Indirect Rollover'>
                        Indirect Rollover
                    </IonSelectOption>
                </IonSelect>
            } rules={{required: true}}/>
        </IonItem>
        <em><b>Direct Rollover </b> - Funds are currently in an employer plan.<br/><b>Indirect Rollover</b> - I have or will receive the funds directly from my plan and would like to rollover those funds to my Equity Trust.<br/></em>
    </IonCol>
    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
        <IonLabel>
            Account Type *
        </IonLabel>
        <IonItem className={showError(`account_type`)}>
            <Controller name={`rollovers[${rolloverNumber}].account_type`} control={control} defaultValue={item[`account_type`]}  render={ ({name, value, onBlur, onChange}) =>
                <IonSelect name={name}  interface='action-sheet' value={value} onIonBlur={onBlur} onIonChange={onChange} interfaceOptions={{cssClass: 'long-select', animated: false, mode: 'ios'}}>
                    {applicationAccountType.includes('Roth') ? (
                        <React.Fragment>
                            <IonSelectOption value='Roth IRA'>Roth IRA</IonSelectOption>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <IonSelectOption value='Traditional IRA'>
                                Traditional IRA
                            </IonSelectOption>
                            <IonSelectOption value='SEP IRA'>
                                SEP IRA
                            </IonSelectOption>
                            <IonSelectOption value='Simple IRA'>
                                Simple IRA
                            </IonSelectOption>
                        </React.Fragment>
                    )}
                    <IonSelectOption value='Individual(k)'>Individual(k)</IonSelectOption>
                    <IonSelectOption value='Profit Sharing Plan'>
                        Profit Sharing Plan
                    </IonSelectOption>
                    <IonSelectOption value='401(k)'>401(k)</IonSelectOption>
                    <IonSelectOption value='403(b)'> 403(b)</IonSelectOption>
                    <IonSelectOption value='Defined Benefit Plan'>Defined Benefit Plan</IonSelectOption>
                </IonSelect>
            } rules={{required: true}} />
        </IonItem>
    </IonCol>
</IonRow>
<IonRow>
    
</IonRow>
</div>
)}

export default RolloverForm
import { ToastActions } from "../actions/toastActions";

export const initialToastState = {
    showErrorToast: false, 
    errorMessage: '', 
    showSpinner: false, 
    showSuccessToast: false, 
    successMessage: ''
}

const toastReducer = (state = initialToastState, action: ToastActions) => {
    switch (action.type) {
        case 'SET_SHOW_ERROR_TOAST': 
            return {
                ...state, 
                showErrorToast: action.showErrorToast
            }
        case 'SET_ERROR_MESSAGE': 
            return {
                ...state,
                errorMessage: action.errorMessage,
                showErrorToast: false,
                showSuccessToast: false
            }
        case 'SET_AND_SHOW_ERROR_MESSAGE': 
            return {
                ...state,
                errorMessage: action.errorMessage,
                showErrorToast: true,
                showSuccessToast: false
            }
        case 'SET_SHOW_SPINNER': 
            return {
                ...state,
                showSpinner: action.showSpinner
            }
        case 'SET_SHOW_SUCCESS_TOAST': 
            return {
                ...state,
                showSuccessToast: action.showSuccessToast
            }
        case 'SET_SUCCESS_MESSAGE':
            return {
                ...state, 
                successMessage: action.successMessage,
                showErrorToast: false,
                showSuccessToast: false
            }
        case 'SET_SHOW_SUCCESS_MESSAGE':
            return {
                ...state, 
                successMessage: action.successMessage,
                showErrorToast: false,
                showSuccessToast: true 
            }

        default: 
            return state; 
    }
}

export default toastReducer; 
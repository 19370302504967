import { updateValidationTable } from '../../helpers/calloutHelpers'
import { MenuAction } from '../actions'; 

interface MenuState {
    menuParams: MenuParameters, 
    menuSections: MenuSection[],
    feeAmountMinusDiscount: number
}

export const initialMenuState : MenuState = {
    menuParams: {
        planInfo: false,
        is401kApplication: false,
        isDYO401kApplication: false, 
        is_welcome_page_valid: false,
        is_disclosure_page_valid: false,
        is_owner_info_page_valid: false,
        is_owner_questionnaire_page_valid: false,
        is_beneficiaries_page_valid: false,
        is_investment_details_page_valid: false,
        is_funding_options_page_valid: false,
        is_fee_arrangement_page_valid: false,
        is_account_notifications_page_valid: false,
        is_payment_information_page_valid: false,
        is_review_and_sign_page_valid: false,
        is_plan_information_page_valid: false,
        is_employer_information_page_valid: false
    },
    menuSections: [],
    feeAmountMinusDiscount: 0
}

const menuReducer = (state = initialMenuState, action: MenuAction): MenuState => {
    switch (action.type) {
        // case 'SKIP_PAYMENT_FORM':
        //     return {
        //         ...state,
        //         menuParams: {
        //             ...state.menuParams,
        //             showPaymentForm: true
        //         }
        //     }
        case 'UPDATE_MENU_PARAMS':
            updateValidationTable(action.payload.page, action.payload.valid, action.payload.sessionId);
            const updatedMenuParams = {...state.menuParams, [action.payload.page]: action.payload.valid};
            return {
                ...state, 
                menuParams: updatedMenuParams
            };

        case 'SET_MENU_PARAMS': 
            const newMenuParams = {
                ...state.menuParams, 
                ...action.payload
            }
            console.info(state.menuParams)
            console.info(action.payload)
            console.info(state)
            console.info(newMenuParams)
            return {
                ...state,
                menuParams: {
                    ...newMenuParams
                    // ...state.menuParams, 
                    // ...action.payload
                }
            }

        case 'SET_MENU_SECTIONS': 
            let newMenuSections = [...action.payload];
            return {
                ...state,
                menuSections: newMenuSections
            }
        default:
            return state;
    }
}

export default menuReducer; 
import React, {useEffect} from 'react';
import { MINIMUM_CASH_BALANCE } from '../../helpers/sharedUtils';
import { IonItem, IonGrid, IonRow, IonCol, IonItemDivider, IonText, IonLabel, IonSelect, IonSelectOption, IonInput, IonCheckbox } from '@ionic/react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { saveInitialInvestmentPage } from '../../helpers/calloutHelpers';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import { SessionAppWithSharedParams } from '../../helpers/sessionInterfaces'

const validNumberRegex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;

export interface InitialInvestmentPage extends SessionAppWithSharedParams {
    updateLastActivity: Function,
    formData?: {data?: Forms.initialInvestmentForm},
    setInitialInvestmentType?: Function,
}

interface extendedForm extends Forms.initialInvestmentForm {
    hasReadMinCashBal?:boolean
}

const InitialInvestment: React.FC<InitialInvestmentPage> = ({ formData, sessionId, updateMenuItem, formRef, onePathParameters, updateLastActivity, setInitialInvestmentType, handleNavigation, setErrorMessage, setShowErrorToast }) => {
    const getDefaultValues = ()=>{
        
        let defaultValues:extendedForm = {
            has_initial_investment: null,
            investment_type: '',
            initial_investment_name: '',
            investment_contact_person: '',
            investment_amount: null,
            min_cash_balance_checkbox: null,
            referred_by: '',
            plan_for_account_without_investment: '',
            hasReadMinCashBal: false,
            investment_contact_person_phone: formData?.data?.investment_contact_person_phone || '',
            ...formData?.data
        }

        if (onePathParameters && onePathParameters.hasOnePathParams) {
            defaultValues.has_initial_investment = true
            defaultValues.investment_type = onePathParameters.initial_investment_type || ''
            defaultValues.initial_investment_name = onePathParameters.initial_investment_name || ''
            defaultValues.investment_contact_person = onePathParameters.investment_contact_person || ''
            defaultValues.investment_contact_person_phone = onePathParameters.investment_contact_person_phone || ''
            defaultValues.referred_by = onePathParameters.referred_by || ''
        }

        return defaultValues
    }

    const {control, handleSubmit, errors, formState }  = useForm<extendedForm>({
        defaultValues:getDefaultValues()
    });

    const has_initial_investment = useWatch({
        name:'has_initial_investment',
        control: control
    })
    
    const investment_type:string = useWatch({
        name:'investment_type',
        control: control,
        defaultValue:''
    })

    const referred_by:string|undefined = useWatch({
        name:'referred_by',
        control: control,
    })

    const hideReferredBy = referred_by?.startsWith('001') && (referred_by?.length === 15 || referred_by?.length === 18)

    const showMinCashBalanceCheckbox = has_initial_investment || onePathParameters?.custom_ui_state === 'Hide Funding Validation' || onePathParameters?.custom_ui_state === 'Custom Fee Schedule'

    const { isDirty, dirtyFields, submitCount } = formState; 

    const initialInvestmentTypes = [
        {label: 'Private Fund (Hedge, Private Equity, Venture Capital, etc.)', value: 'Private Fund'},
        {label: 'Private Stock', value: 'Private Stock'},
        {label: 'Real Estate - Fund/Syndication', value: 'Real Estate Syndication'},
        {label: 'Real Estate - Property', value: 'Real Estate'},
        {label: 'Lending', value: 'Promissory Note'},
        {label: 'Trading Account', value: 'Futures/Forex'},
        {label: 'Checkbook Control', value: 'LLC'},
        {label: 'Precious Metals', value: 'Precious Metals'},
        {label: 'Other', value: 'Other'}         
    ];

    useEffect(()=>{
        if (investment_type && investment_type !== '' && setInitialInvestmentType) {
            setInitialInvestmentType(investment_type);
        }
    },[investment_type, setInitialInvestmentType])

    useEffect(() => {
        if(!isDirty) return;

        const { investment_amount, has_initial_investment } = dirtyFields
        const investmentDetailsChanged = investment_amount || has_initial_investment
        if (investmentDetailsChanged) {
            updateMenuItem('is_funding_options_page_valid', false, sessionId)
        }
    }, [isDirty, dirtyFields, sessionId, updateMenuItem])
    
    /**
     * 
     * Not being referenced, makred for deletion after regression testing
     */
    // const displayEntityNameLabel = (investmentType?:string) => {
    //     let entityNameLabel = 'Entity Name';
    //     if(investmentType === undefined){
    //         return entityNameLabel;
    //     }

    //     if (investmentType?.includes('Note')){
    //         entityNameLabel = 'Borrower Name';
    //     } else if (investmentType === 'Real Estate'){
    //         entityNameLabel = 'Property Address';
    //     } else if (investmentType === 'Futures/Forex'){
    //         entityNameLabel = 'Trading Company Name';
    //     } else if (investmentType === 'Precious Metals') {
    //         entityNameLabel = 'Dealer Name';
    //     }
    //     return entityNameLabel;
    // }

    const validateFields = (data: extendedForm) => {
        let initialInvestmentvalues:extendedForm = {
            ...data,
            hasReadMinCashBal: undefined
        } 

        if (data.investment_amount?.toString() === '') {
            initialInvestmentvalues.investment_amount=0
        }

        if(initialInvestmentvalues.has_initial_investment === false && formData?.data?.investment_amount){
            initialInvestmentvalues.investment_amount=0
        }

        if (formData?.data?.checkout_validation_bypass) {
            initialInvestmentvalues.checkout_validation_bypass = formData.data.checkout_validation_bypass;
        }

        saveInitialInvestmentPage(sessionId, initialInvestmentvalues, updateLastActivity)?.then(() => {
            updateMenuItem('is_investment_details_page_valid', true, sessionId);
            handleNavigation({status: 'Success'});
        })
    }
    
    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = '';
        if ((submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };
    
    const onInvalidForm = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        }
        else {
            handleNavigation({status: 'Error'});
        }
    }

    const disabledHasInitialInvestment = onePathParameters?.hasOnePathParams && !!onePathParameters?.initial_investment_type && onePathParameters?.initial_investment_type !== ''
    return (
        <form ref={formRef} onSubmit={handleSubmit(validateFields, onInvalidForm)} className='ion-padding'>
            <IonGrid>
                <IonRow className='well'>
                    <IonCol>
                        Already have an investment in mind for your new account? Tell us about your transaction. If you don’t know all of the details, no problem! Our client services team will reach out to you to further discuss this transaction before anything is processed.
                    </IonCol>
                </IonRow>
                <IonItemDivider>
                    <IonText color='primary'>
                        <b>
                            Investment Details    
                        </b>
                    </IonText>
                </IonItemDivider>
                    <IonRow hidden={onePathParameters?.hasOnePathParams}>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Do you have an initial investment in mind? *
                            </IonLabel>
                            <IonItem className={showError('has_initial_investment')}>

                                <Controller name='has_initial_investment' control={control} render={ ({name, value, onBlur, onChange})=>
                                    <IonSelect name={name} data-testid='has-initial-investment'  value={value} 
                                        onIonBlur={onBlur} onIonChange={ev=>onChange(ev.detail.value)} 
                                        interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} disabled={disabledHasInitialInvestment}>
                                        <IonSelectOption value={true}>Yes</IonSelectOption>
                                        <IonSelectOption value={false}>No</IonSelectOption>
                                    </IonSelect>
                                } rules={{validate: (value)=>{return value !== null}}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                {has_initial_investment === false && (
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                What do you plan on doing with the account?
                            </IonLabel>
                            <IonItem className={showError('plan_for_account_without_investment')}>
                                <Controller name='plan_for_account_without_investment' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={255}/>
                                }/>
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel hidden={hideReferredBy}>
                                Who can we thank for your referral?
                            </IonLabel>
                            <IonItem hidden={hideReferredBy}>
                                <Controller control={control} name='referred_by' render={ ({name, value, onBlur, onChange}) =>
                                    <IonInput name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={100}/>
                                }/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                )}
                {(has_initial_investment === true || onePathParameters?.hasOnePathParams) && (
                <>
                   <IonRow>
                        {onePathParameters?.hasOnePathParams && onePathParameters?.call_based_asset &&
                            <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                <IonLabel>
                                    What is your initial call amount *
                                </IonLabel>
                                <IonItem className={showError('initial_subscription_amount')}>
                                    <Controller name='initial_subscription_amount' control={control} render={ ({name, value, onBlur, onChange}) =>
                                        <IonInput name={name} type='number' value={value} onIonBlur={onBlur} onIonChange={onChange} />
                                    } rules={{ 
                                        validate: {
                                            validNumber: val => { return (val === '' || val === null || val?.toString().match(validNumberRegex)!== null)},
                                            greaterThanZero: val => val === '' || val === null || val > 0
                                        },
                                        required: true}} />
                                </IonItem>
                            </IonCol>
                        }
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <InvestmentAmountLabel onePathParameters={onePathParameters}/> 
                            <IonItem className={showError('investment_amount')}>
                                <Controller name='investment_amount' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonInput name={name} type='number' data-testid='investment-amount' value={value} onIonBlur={onBlur} onIonChange={(ev)=>{
                                        onChange(ev.detail.value)
                                    }} />
                                } rules={{ 
                                    validate: {
                                        validNumber: val => { return (val === '' || val === null || val?.toString().match(validNumberRegex)!== null)},
                                        greaterThanZero: val => val === '' || val === null || val > 0
                                    },
                                    required: true}} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                What type of investment? *
                            </IonLabel>
                            <IonItem className={showError('investment_type')}>
                                <Controller name='investment_type' control={control} render={ ({name, value, onBlur, onChange})=>
                                    <IonSelect name={name} value={value} data-testid='investment-type' onIonBlur={onBlur} onIonChange={(ev)=>onChange(ev.detail.value)} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios', cssClass: 'long-select'}} disabled={onePathParameters?.hasOnePathParams && !!onePathParameters?.initial_investment_type && onePathParameters?.initial_investment_type !== ''}>
                                        {initialInvestmentTypes.map((investmentType, index) => (
                                            <IonSelectOption key={index} value={investmentType.value}>{investmentType.label}</IonSelectOption>
                                        ))}
                                    </IonSelect>
                                } rules={{required:true}}/>
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12" hidden={hideReferredBy}>
                            <IonLabel hidden={hideReferredBy}>
                                Who can we thank for your referral?
                            </IonLabel>
                            <IonItem hidden={hideReferredBy}>
                                <Controller control={control} name='referred_by' render={ ({name, value, onBlur, onChange}) =>
                                    <IonInput name={name} value={value} data-testid='referred-by' onIonBlur={onBlur} onIonChange={ev=>onChange(ev.detail.value)} maxlength={100}/>
                                }/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
                )}
                {showMinCashBalanceCheckbox && (
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <Controller name='hasReadMinCashBal' control={control} render={({name,  value, onChange }) =>
                                <IonCheckbox name={name} tabIndex={0} checked={value} onIonChange={ev=>onChange(ev.detail.checked)} className={showError('hasReadMinCashBal')} />
                            } rules={{
                                required: true, validate: (value) => {
                                    return value;
                                }
                            }} />
                            &nbsp;
                            <IonText className='ion-padding-left'>
                                Equity Trust has a minimum cash balance requirement of ${MINIMUM_CASH_BALANCE} which cannot be included in the investment amount. *
                            </IonText>
                        </IonCol>
                    </IonRow>
                )}
            </IonGrid>
        </form>
    )
}

const InvestmentAmountLabel: React.FC<{onePathParameters?: onePathParameters}> = ({onePathParameters}) => {
    const callBasedAsset = onePathParameters?.hasOnePathParams && onePathParameters?.call_based_asset
    if(callBasedAsset){
        return  <IonLabel>
            What is your total commitment amount? *
        </IonLabel>
    }
    return <IonLabel>
        How much are you investing? *
    </IonLabel>
}

const mapStateToProps = (state: StoreState) => {
    return {
        sharedParams: state.sharedParams,// shared params is not being used anywhere
        sessionId: state.session.sessionId,
        onePathParameters: state.onePathParams       
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        updateLastActivity: (lastActive: number) => dispatch(actions.updateLastActivity(lastActive)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload)),
        setInitialInvestmentType: (initialInvestmentType: string) => dispatch(actions.setInitialInvestmentType(initialInvestmentType)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialInvestment); 
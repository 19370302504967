import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {  IonPage, IonContent, IonRow, IonSpinner } from '@ionic/react';
import { startOnePathSession } from '../helpers/calloutHelpers'
import { connect } from 'react-redux';
import { getErrorPath } from '../pages/ErrorPage';
import * as actions from '../store/actions';

interface OnePathLoadingPageInterface {
    onePathToken:string, 
    setOnePathParameters: (onePathParameters: onePathParameters)=>void, 
    initSession: Function,
    initWelcomePageFields: (payload: Partial<welcomePageParameters>) => void,
}
  
const OnePathLoadingPage: React.FC<OnePathLoadingPageInterface> = ({onePathToken, setOnePathParameters, initSession, initWelcomePageFields })=>{
    const history = useHistory();
    const queryParams = React.useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);

    const updateWelcomePageOnepathFieldsWithResponse = useCallback((onePathParameters: Awaited<ReturnType<typeof startOnePathSession>>) => {
        
        if(onePathParameters.welcomePageParameters){
            initWelcomePageFields(onePathParameters.welcomePageParameters)
        } else {
            initWelcomePageFields({
                deal_opp: onePathParameters.deal_opp || '',
                sales_rep: onePathParameters.onePathData?.owner_id || '',
                promo_code: onePathParameters.onePathData?.promo_code || '',
            })
        }
    }, [initWelcomePageFields])

    const setupOnePath = useCallback(async () => {
        const appId = queryParams?.get('id')
        const loadSession = queryParams?.get('load_session')
        try{
            const onePathResponse = await startOnePathSession(onePathToken, appId, loadSession)
            initSession(onePathResponse.session_id);
            setOnePathParameters({
                hasOnePathParams: true,
                ...onePathResponse.onePathData,
                viewMode: onePathResponse.viewMode,
                deal_opp: onePathResponse.deal_opp
            });
            updateWelcomePageOnepathFieldsWithResponse(onePathResponse)
            history.push('/');
        }catch(err)
        {
            const resolveErrorPath = () => {
                if(appId){
                    return getErrorPath({error: 'one-path', extra: 'isp'})
                }
                if(!appId && loadSession){
                    return getErrorPath({error: 'one-path', extra: 'isp-invite'})
                }
                return getErrorPath({error: 'one-path'})
            }
            console.error(err)
            history.push(resolveErrorPath())
            return;
        }
    },[ updateWelcomePageOnepathFieldsWithResponse, initSession, setOnePathParameters, history, onePathToken, queryParams])

    useEffect(()=>{
        if(!onePathToken || onePathToken===''){
            history.push('/')
            return;
        } 
        setupOnePath()
    },[setupOnePath, history, onePathToken])

    return(
        <IonPage id='main' className='ion-padding-top'>
            <IonContent className='ion-padding'>
                <IonRow className='well' style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}>
                    <IonRow style={{padding: '20px'}}>
                        <strong style={{fontSize: '30px'}}>
                            Please wait while we load OnePath
                        </strong>
                    </IonRow>
                    <IonRow style={{padding: '20px'}}>
                        <IonSpinner name='circular'/>
                    </IonRow>
                </IonRow>
            </IonContent>
        </IonPage>

    )
}

const mapDispatchToProps = (dispatch: StoreDispatch) => {
    return {
        initSession: (sessionId: string) => dispatch(actions.initSession(sessionId)),
        setOnePathParameters: (onePathParameters: onePathParameters) => dispatch(actions.setOnepathParams(onePathParameters)),
        initWelcomePageFields: (payload: Partial<welcomePageParameters>) => dispatch(actions.initWelcomePageFields({...payload, initialized: 'ONEPATH'})),
    }
}

export default connect(null, mapDispatchToProps)(OnePathLoadingPage);
import { SF_SYSTEM_USER_ID } from '../../helpers/utils'
import { WelcomeActions } from '../actions/index';

export const initialState : welcomePageParameters = {
    account_type: '',
    promo_code: '',
    sales_rep: SF_SYSTEM_USER_ID,
    payment_form: false, // false means we're showing it initially
    has_read_diclosure: false,
    is_self_employed: false,
    has_plan_eligible_employees: false,
    is_employer_part_of_group: false,
    open_third_party_trading_account: false,
    understand_irs_rules: false,
    roth_acknowledgment_dyo: false,
    account_type_401k: '',
    deal_opp: '',
    initialized:undefined,
    first_name: '',
    last_name: '',
    email: ''
}

const welcomeReducer = (state = initialState, action: WelcomeActions): welcomePageParameters => {
    switch (action.type) {
        case 'SET_CONTACT_FIELDS':
            return {
                ...state,
                email: action.payload.email,
                first_name: action.payload.first_name,
                last_name: action.payload.last_name
            }
        case 'INIT_WELCOME_PAGE_FIELDS':
            if(state.initialized === 'ONEPATH'){
                return state;
            }
            return {
                ...state,
                ...action.payload
            }
        case 'SET_WELCOME_PAGE_FIELDS': 
            return {
                ...state,
                ...action.payload,
            };
        case 'SET_ONEPATH_PARAMS':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default welcomeReducer; 
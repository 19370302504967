import React from 'react';
import { useLocation } from 'react-router-dom';
import { IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar, IonImg, isPlatform, IonRow } from '@ionic/react';
import logo from '../images/logo.png';
import { isMobile } from '../helpers/utils';
import { connect } from 'react-redux';
import WizardButtonsSavePage from './WizardButtonsSavePage';
import { StoreState } from '../store/reducers/rootReducer';
import './Header.css';

export interface headerInterface {
  menuSections: MenuSection[]
  stepInDirection?: (direction: 'next' | 'back') => () => void
  saveFormProgress? : () => Promise<void>
  viewMode?: ViewMode
}

const Header: React.FC<headerInterface> = ({
  menuSections, stepInDirection, saveFormProgress, viewMode }) => {
  let appPages = menuSections.flatMap(e => {
    return e.pages
  });

  let location = useLocation();

  const displayTitle = () => {
    let pathName = location.pathname.toUpperCase();
    if (!isMobile(isPlatform)) {
      if (pathName.includes('DOCUSIGN')) {
        return 'Finishing Up';
      } else if (pathName.includes('RESUME')) {
        return 'Resume';
      } else if (pathName.includes('APPLICATIONERROR')) {
        return 'Error';
      }
      else {
        return !isMobile(isPlatform) && (appPages.filter(page => page.url === location.pathname))[0]?.title;
      }
    }
  }

  // If the header component is passed a stepInDirection function for the wizard buttons,
  // the wizard buttons are made visible and are passed stepInDirection.
  const displayRoutingButtons = (stepInDirection && !isMobile(isPlatform))

  // These are the positions and styles for the wizard buttons.
  let buttonPositions = ['end', 'end', 'end']
  let buttonStyles = ['', '', 'ion-margin-end force-margin-right']

  const displayMenuButton = () => {
    let pathName = location.pathname.toUpperCase();
    return (!pathName.includes('DOCUSIGN') && !pathName.includes('RESUME') && !pathName.includes('APPLICATIONERROR'));
  }

  return (
    <IonHeader>
      <IonToolbar>
        {displayMenuButton() && (
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        )}
        {displayRoutingButtons &&
          <WizardButtonsSavePage buttonPositions={buttonPositions} buttonStyles={buttonStyles} handleStep={stepInDirection}/>
        }
        <IonRow>
          <IonImg className={!isMobile(isPlatform) ? 'logo' : 'logo-mobile'} src={logo} alt='Equity Trust Logo' />
          <IonTitle size='small'>
            <h1 className='title'>
              {displayTitle()}
            </h1>
          </IonTitle>
        </IonRow>
      </IonToolbar>
    </IonHeader>
  )
}

const mapStateToProps = (state: StoreState) => {
  return {
    menuSections: state.menu.menuSections,
    viewMode: state.onePathParams.viewMode
  }
}

export default connect(mapStateToProps)(Header);
import { combineReducers, legacy_createStore, applyMiddleware, compose } from 'redux'
import { devToolsEnhancer } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import welcomeReducer from '../../store/reducers/welcomeReducer'
import menuReducer from '../../store/reducers/menuReducer'
import sessionReducer from '../../store/reducers/sessionReducer'
import toastReducer from '../../store/reducers/toastReducer'
import sharedParamsReducer from '../../store/reducers/sharedParamsReducer'
import onePathReducer from '../../store/reducers/onePathReducer'

const devEnv = process.env.NODE_ENV === 'development' 

export const rootReducerStores = {
    welcomePage: welcomeReducer, 
    menu: menuReducer,
    session: sessionReducer,
    toastControl: toastReducer,
    sharedParams: sharedParamsReducer,
    onePathParams: onePathReducer
}

export const rootReducer = combineReducers(rootReducerStores)
export const composeEnhancers = devEnv ?  devToolsEnhancer({trace: true}) : compose(applyMiddleware(thunk));
export type StoreState = ReturnType<typeof store.getState>
export type StoreDispatch = typeof store.dispatch
export type ReduxStore = typeof store

export const store = legacy_createStore(rootReducer, composeEnhancers);
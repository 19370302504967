export type Investment = {
    type: string,
    value: number
}

export type Fee = {
    description: string,
    value: number
    tooltipMessage: string
}

const ANNUAL_ASSET_FEE = 350
const DEFAULT_TRANSACTION_FEE = 125
const REAL_ESTATE_TRANSACTION_FEE = 200
const METALS_AND_TRADING_TRANSACTION_FEE = 50

const ANNUAL_VALUE_FEE_RANGES = [
    { lowerBound: 750000, fee: 1850 },
    { lowerBound: 500000, fee: 1650 },
    { lowerBound: 250000, fee: 1250 },
    { lowerBound: 125000, fee: 950 },
    { lowerBound: 90000, fee: 700 },
    { lowerBound: 60000, fee: 500 },
    { lowerBound: 45000, fee: 425 },
    { lowerBound: 30000, fee: 350 },
    { lowerBound: 15000, fee: 300 },
    { lowerBound: 0, fee: 225 },
]

export const getEstimatedFees = (paymentMethod: string, feeSchedule: string, initialInvestment: Investment): Fee[] => {
    const estimatedFees: Fee[] = []
    let annualAssetFeeTooltipMessage = 'The annual account fee based on your selected fee option. Your annual fee is charged every year on the anniversary of the account being opened.'

    if (paymentMethod === 'Account') {
        if (feeSchedule === `Asset Based ($${ANNUAL_ASSET_FEE})`) {
            const assetBasedFee: Fee = {description: 'Annual Fee', value: ANNUAL_ASSET_FEE, tooltipMessage: annualAssetFeeTooltipMessage}
            estimatedFees.push(assetBasedFee)
        }
        else if (feeSchedule === 'Value Based') {
            const filteredValueFeeRanges = ANNUAL_VALUE_FEE_RANGES.filter(range => initialInvestment.value >= range.lowerBound)
            if (filteredValueFeeRanges.length > 0) {
                const valueBasedFee: Fee = { description: 'Annual Fee', value: filteredValueFeeRanges[0].fee, tooltipMessage: annualAssetFeeTooltipMessage}
                estimatedFees.push(valueBasedFee)
            }
        }

        const transactionFee: Fee = { description: 'Transaction Fee', value: DEFAULT_TRANSACTION_FEE, tooltipMessage: 'The processing fee for the initial asset purchase transaction'}
        if (initialInvestment.type === 'Real Estate') {
            transactionFee.value = REAL_ESTATE_TRANSACTION_FEE
        }
        else if (initialInvestment.type === 'Precious Metals' || initialInvestment.type === 'Futures/Forex') {
            transactionFee.value = METALS_AND_TRADING_TRANSACTION_FEE
        }
        estimatedFees.push(transactionFee)

        const wireFee: Fee = { description: 'Wire Fee', value: 30, tooltipMessage: 'The processing fee for wire transfer of your asset purchase' }
        estimatedFees.push(wireFee)
    }

    return estimatedFees
}
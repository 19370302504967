import React, { useState } from 'react';
import {useForm, Controller, useWatch} from 'react-hook-form';
import { states, isMobile } from '../../helpers/utils';
import { EMAIL_REGEX } from '../../helpers/sharedUtils'
import { IonItem, IonGrid, IonRow, IonCol, IonItemDivider, IonText, IonLabel, IonSelect, IonSelectOption, IonInput, isPlatform, IonButton } from '@ionic/react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputMask from 'react-input-mask';
import { saveAccountNotificationsPage} from '../../helpers/calloutHelpers';
import { SessionApp } from '../../helpers/sessionInterfaces'
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

const paperStatementOptions = [
    {
        value: 'e-Statement',
        label: 'No Paper Statements (Free Online)'
    }, 
    {
        value: 'Mailed Monthly', 
        label: 'Mailed Monthly ($60/Annually)'
    }, 
    {
        value: 'Mailed Quarterly', 
        label: 'Mailed Quarterly ($20/Annually)'
    }, 
    {
        value: 'Mailed Annually',
        label: 'Mailed Annually ($5/Annually)'
    }
]

export interface AccountNotificationsFc extends SessionApp{
    formData: { data?: {accountNotificationForm: Forms.accountNotificationsForm, beneficiaries?: beneficiariesForAccountNotifications[]} } | undefined,
    applicant_email: string,
    updateLastActivity: Function
}

const AccountNotifications: React.FC<AccountNotificationsFc> = ({sessionId, updateMenuItem, formRef, updateLastActivity, applicant_email, formData, handleNavigation, setErrorMessage, setShowErrorToast }) => { 
    const [ selectedBeneficiary, setSelectedBeneficiary ] = useState<{first_name?: string, last_name?: string, relationship?: string}>();
    const beneficiaries: beneficiariesForAccountNotifications[] | undefined = formData?.data?.beneficiaries;

    const defaultValues:Forms.accountNotificationsForm = { 
        statement_option: '', 
        include_interested_party: null,
        first_name: '', 
        last_name: '', 
        email: '', 
        phone:'', 
        mailing_street: '', 
        mailing_city: '',
        mailing_state: '', 
        mailing_zip: '', 
        company_name: '',
        title: '', 
        online_access: null,
        ira_statement_option: '',
        ...formData?.data?.accountNotificationForm
    }
 
    const {control, handleSubmit, errors, setValue, formState} = useForm<Forms.accountNotificationsForm>({
        mode: 'onChange',
        defaultValues
    });

    const {isDirty, submitCount} = formState;

    const include_interested_party = useWatch({
        name:'include_interested_party',
        control:control
    })

    const ipd_email = useWatch({
        name: 'email',
        control: control
    })

    const handleBeneficiaryChange = (beneficiary: {first_name?: string, last_name?: string, relationship?: string}) => {
        setSelectedBeneficiary(beneficiary);
        
        if (beneficiaries && beneficiary && beneficiary.first_name && beneficiary.last_name && beneficiary.relationship) {
            const beneficiaryInfo = beneficiaries.find(bene => bene.first_name === beneficiary.first_name && bene.last_name === beneficiary.last_name && bene.relationship === beneficiary.relationship);
            setValue('first_name', beneficiaryInfo?.first_name);
            setValue('last_name', beneficiaryInfo?.last_name);
            setValue('email', beneficiaryInfo?.email);
            setValue('phone', beneficiaryInfo?.phone);
            setValue('mailing_street', beneficiaryInfo?.mailing_street);
            setValue('mailing_city', beneficiaryInfo?.mailing_city);
            setValue('mailing_state', beneficiaryInfo?.mailing_state);
            setValue('mailing_zip', beneficiaryInfo?.mailing_zip);
        } else {
            setValue('first_name', '');
            setValue('last_name', '');
            setValue('email', '');
            setValue('phone', '');
            setValue('mailing_street','');
            setValue('mailing_city', '');
            setValue('mailing_state', '');
            setValue('mailing_zip', '');
        }
    }

    const validateFields = (data: Forms.accountNotificationsForm, e: any) => {
        saveAccountNotificationsPage(sessionId, data, updateLastActivity)?.then(() => {
            updateMenuItem('is_account_notifications_page_valid', true, sessionId);
            handleNavigation({status: 'Success'});
        });
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = '';
        if ((submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };

    const onInvalidForm = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        } 
        else {
            handleNavigation({status: 'Error'});
        }
    }
  

    return(
            <form ref={formRef} onSubmit={handleSubmit(validateFields, onInvalidForm)} className='ion-padding'>
                <IonGrid>
                    <IonRow>
                        <IonCol className='well'>
                        <p><b>MIDLAND360 is the Equity Trust Premier Online Access System:</b> Equity Trust provides every account holder with 24/7 secure, online access to your account(s). You will find this system provides a truly 360 degree view of your account including account balances, pending/posted transactions, account statements, ability to submit bill pay and other transactions, and much more! Within 1 business day of receiving this application, you will receive an email with instructions on how to access your account online.</p>
                        </IonCol>
                    </IonRow>
                    <IonItemDivider>
                        <strong>
                            <IonText color='primary'>
                                Account Statements
                            </IonText>
                        </strong>
                    </IonItemDivider>
                    <IonRow>
                        <IonCol>
                        <em>Electronic statements are always provided in your online access. If you would like to receive paper statements in the mail, please select one of the following options. There is a $5 fee per statement mailed.<br/></em>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Paper Statement Options *
                            </IonLabel>
                            <IonItem className={showError('ira_statement_option')}>
                                <Controller name='ira_statement_option' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonSelect name={name} data-testid='paper-statement-option'  value={value} onIonBlur={onBlur} onIonChange={onChange} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}}>
                                        {paperStatementOptions.map((statementOption, index) => (
                                            <IonSelectOption key={index} value={statementOption.value}>
                                                {statementOption.label}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                } rules={{required:true}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonItemDivider>
                        <strong>
                            <IonText color='primary'>
                                Third-Party Representative
                            </IonText>
                        </strong>
                    </IonItemDivider>
                    <IonRow>
                        <IonCol>
                            <em> You can provide another party such as your spouse, financial advisor, or investment manager with access to your account. </em>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                            Do you want to provide someone else access to your account? *
                            </IonLabel>
                            <IonItem className={showError('include_interested_party')}>
                                <Controller name='include_interested_party' control={control} render={ ({name, value, onBlur, onChange}) =>
                                    <IonSelect name={name} data-testid='include-interested-party-select'   value={value} onIonBlur={onBlur} onIonChange={onChange} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}}>
                                        <IonSelectOption value={false}>No</IonSelectOption>
                                        <IonSelectOption value={true}>Yes</IonSelectOption>
                                    </IonSelect>
                                } rules={{validate: (value)=>{return value !== null}}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    {include_interested_party === true && 
                    (
                        <React.Fragment>
                            {beneficiaries && beneficiaries?.length > 0 &&
                                <IonRow>
                                    <IonCol size='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                                        <IonLabel>
                                            Would you like to give one of your Beneficiaries access to your account?
                                        </IonLabel>
                                        <IonItem>
                                                <IonSelect data-testid='beneficiary-select' class='gr-border'  value={selectedBeneficiary} selectedText={selectedBeneficiary && selectedBeneficiary.first_name && selectedBeneficiary.last_name && selectedBeneficiary.relationship ? `${selectedBeneficiary.first_name} ${selectedBeneficiary.last_name} - ${selectedBeneficiary.relationship}` : 'No'} onIonChange={event => handleBeneficiaryChange(event.detail.value)} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}}>
                                                    <IonSelectOption value={{}}>No</IonSelectOption>
                                                    {beneficiaries?.map((beneficiary, index) => 
                                                        <IonSelectOption key={index} value={{first_name: beneficiary.first_name, last_name: beneficiary.last_name, relationship: beneficiary.relationship}}>{`${beneficiary.first_name} ${beneficiary.last_name} - ${beneficiary.relationship}`}</IonSelectOption>
                                                    )}
                                                </IonSelect>
                                            </IonItem>
                                    </IonCol>
                                </IonRow>
                            }
                            <IonRow>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>
                                        First Name *
                                    </IonLabel>
                                    <IonItem className={showError('first_name')}>
                                        <Controller name='first_name' control={control} 
                                            render={ ({name, value, onBlur, onChange}) => <IonInput name={name} data-testid='first-name' value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='First Name'/>} 
                                            rules={{required:true, maxLength: 100}} 
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>
                                        Last Name * 
                                    </IonLabel>
                                    <IonItem className={showError('last_name')}>
                                        <Controller name='last_name' control={control} 
                                            render={ ({name, value, onBlur, onChange}) => <IonInput name={name} data-testid='last-name' value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='Last Name'/>} 
                                            rules={{required:true, maxLength: 100}} 
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>
                                        Email *
                                    </IonLabel>
                                    <IonItem className={showError('email')}>
                                        <Controller name='email' control={control} 
                                            render={ ({name, value, onBlur, onChange}) => <IonInput name={name} data-testid='email' value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='Email'/>}
                                        rules={{
                                            required: true,
                                            pattern: new RegExp(EMAIL_REGEX),
                                            validate: val => val !== applicant_email
                                        }}
                                        />
                                    </IonItem>
                                    {errors.email && ipd_email === applicant_email ? <IonText color='danger'>Email address cannot match applicant email address.</IonText> : errors.email ? <IonText color='danger'>Please enter a valid email address.</IonText> : ''}
                                </IonCol>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>Phone *</IonLabel>
                                    <IonItem className={showError('phone')}>
                                        <Controller name='phone' control={control} as={
                                            <InputMask data-testid='phone' mask="(999)999-9999" className='input-mask' placeholder='Phone'  type='tel' name='phone'/>} rules={{
                                            required: true,
                                            pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                            validate: (val) => (val?.charAt(1) !== '0' && val?.charAt(1) !== '1')
                                        }}/>
                                    </IonItem>
                                    {errors.phone && <IonText color='danger'>The phone number entered does not appear valid</IonText>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>Street</IonLabel>
                                    <IonItem className={showError('mailing_street')}>
                                        <Controller name='mailing_street' control={control} 
                                            render={ ({name, value, onBlur, onChange}) => <IonInput name={name} data-testid='mailing-street' value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='Street'/>} 
                                            rules={{maxLength: 255}}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>
                                        City
                                    </IonLabel>
                                    <IonItem className={showError('mailing_city')}>
                                        <Controller name='mailing_city' control={control} render={ ({name, value, onBlur, onChange}) =>
                                            <IonInput name={name} data-testid='mailing-city' value={value} onIonBlur={onBlur} onIonInput={onChange} placeholder='City' />} 
                                            rules={{maxLength:100}}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>
                                        State
                                    </IonLabel>
                                    <IonItem className={showError('mailing_state')}>
                                        {isMobile(isPlatform) ? 
                                        <Controller name='mailing_state' control={control} render={ ({name, value, onBlur, onChange}) =>
                                            <IonSelect name={name} data-testid='mailing-state-mobile'   value={value} onIonBlur={onBlur} onIonChange={onChange} placeholder='State' interface='action-sheet'interfaceOptions={{cssClass: 'long-select', animated: false, mode: 'ios'}}>
                                                {states.map((state, index) => (<IonSelectOption value={state} key={index}>{state}</IonSelectOption>))}
                                            </IonSelect>} 
                                        /> : 
                                        <Controller name='mailing_state' control={control} render={({value, name})=>
                                            <Autocomplete data-testid='mailing-state' value={value} id={name} 
                                                onChange={(event, value)=>{
                                                    setValue('mailing_state', value)
                                                }}
                                                freeSolo={true} autoSelect={true} options={states}  getOptionLabel={option => option} renderOption={option =>(
                                            <span> {option}</span>
                                            )} renderInput={params => {
                                                let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'State'};
                                                let innerInputProps = {...params.inputProps, maxLength: "2"}
                                                let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps};
                                                return (
                                                <TextField type='text' {...newParams}/>
                                            )}} />} rules={{
                                                validate: (val) => (val === null || val === '' ||  /[A-Za-z]{2}/.test(val) && (states.findIndex((state) => state === val?.toUpperCase()) !== -1))
                                            }}/>
                                        }
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel> Zip</IonLabel>
                                    <IonItem className={showError('mailing_zip')}>
                                        <Controller name='mailing_zip' control={control} as={
                                            <InputMask data-testid='mailing-zip' maskChar='' mask='99999' placeholder='Zip' className='input-mask'/>
                                        } rules={{validate: (val) => (val === null || val === '' || val?.match(/^[0-9]{5}(?:-[0-9]{4})?/) !== null )}} />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>
                                        Company Name
                                    </IonLabel>
                                    <IonItem className={showError('company_name')}>
                                        <Controller name='company_name' control={control} render={ ({name, value, onBlur, onChange}) =>
                                            <IonInput name={name} data-testid='company-name' value={value} onIonBlur={onBlur} onIonChange={onChange}/>
                                        } rules={{maxLength:255}}/>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>
                                        Title
                                    </IonLabel>
                                    <IonItem className={showError('title')}>
                                        <Controller name='title' control={control} render={ ({name, value, onBlur, onChange}) =>
                                            <IonInput name={name} data-testid='title' value={value} onIonBlur={onBlur} onIonChange={onChange}/>
                                        } rules={{maxLength: 80}} />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>Online Access *</IonLabel>
                                    <IonItem className={showError('online_access')}>
                                        <Controller name='online_access' control={control} render={ ({name, value, onBlur, onChange}) =>
                                             <IonSelect name={name} data-testid='online-access'   value={value} onIonBlur={onBlur} onIonChange={onChange} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}}>
                                             <IonSelectOption value={true}>Yes</IonSelectOption>
                                             <IonSelectOption  value={false}>No</IonSelectOption>
                                         </IonSelect>
                                        } rules={{validate: (value)=>(value !== null)}}/>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                                    <IonLabel>
                                        Paper Statement Options *
                                    </IonLabel>
                                    <IonItem className={showError('statement_option')}>
                                        <Controller name='statement_option' control={control} render={ ({name, value, onBlur, onChange}) =>
                                            <IonSelect name={name} data-testid='statement-option'  value={value} onIonBlur={onBlur} onIonChange={onChange} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}}>
                                                {paperStatementOptions.map((statementOption, index) => (
                                                    <IonSelectOption value={statementOption.value} key={index}>{statementOption.label}</IonSelectOption>
                                                ))}
                                            </IonSelect>
                                        } rules={{required:true}}/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </React.Fragment>
                    )}
                </IonGrid>
            </form>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        sessionId: state.session.sessionId,
        applicant_email: state.welcomePage.email
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        updateLastActivity: (lastActive: number) => dispatch(actions.updateLastActivity(lastActive)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        setShowSuccessToast: (payload: any) => dispatch(actions.setShowSuccessToast(payload)),
        setSuccessMessage: (payload: any) => dispatch(actions.setSuccessMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountNotifications); 

import React, {useEffect} from 'react'; 
import { useFormContext, Controller, useWatch, ArrayField } from 'react-hook-form';
import { IonItem, IonRow, IonCol, IonButton, IonItemDivider, IonLabel, IonInput, IonSelect, IonSelectOption, IonText, isPlatform } from '@ionic/react';
import { convertStringDateToDate, EMAIL_REGEX } from '../helpers/sharedUtils'
import InputMask from 'react-input-mask'
import {KeyboardDatePicker} from '@material-ui/pickers'
import TextField from "@material-ui/core/TextField";
import { errorsExistForFieldOnForm, validateDate, formatDateToString, today, states, isMobile } from '../helpers/utils'
import Autocomplete from "@material-ui/lab/Autocomplete";

type BeneficiaryForm = {item:Partial<ArrayField<SubForms.beneficiary, "id">>, beneficiaryNumber:number, removeBeneficiary():void, updateTotalShare:Function, totalSharePercentage: {Primary:number|null,Contingent:number|null}, applicantData: applicantDataForBeneficiaries}

const BeneficiaryForm: React.FC<BeneficiaryForm> = ({item, beneficiaryNumber, removeBeneficiary, updateTotalShare, totalSharePercentage, applicantData}) => {
    const {errors, control, setValue} = useFormContext<{bene: Array<SubForms.beneficiary>}>()
    const showError = (fieldName: string) => {
        let beneErrors = (errors === undefined || errors.bene === undefined || errors.bene[beneficiaryNumber] === undefined) ? {} : errors.bene[beneficiaryNumber]
        let errorsArr = (Object.keys(beneErrors as any));
        let className = '';
        if (errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };
    
    const sharePercentage: number|undefined = useWatch({
        control,
        name: `bene[${beneficiaryNumber}].share_percentage`
    })
    
    const beneType: 'Primary'|'Contingent'|null|undefined = useWatch({
        control,
        name: `bene[${beneficiaryNumber}].type`
    })

    const beneRelationship: 'Spouse'|'Child'|'Parent'|'Sibling'|'Other Family'|'Other'|null|undefined = useWatch({
        control,
        name: `bene[${beneficiaryNumber}].relationship`,
        defaultValue: item.relationship
    })

    const beneEntityType: 'Individual'|'Trust'|'Nonprofit/Charity'|null|undefined = useWatch({
        control,
        name: `bene[${beneficiaryNumber}].entity_type`,
        defaultValue: item.entity_type
    })

    const beneTaxIdType: 'Tax Identification Number'|'Social Security Number'|null|undefined = useWatch({
        control,
        name: `bene[${beneficiaryNumber}].tax_id_type`
    })
    
    useEffect(()=>{
        updateTotalShare();
    },[sharePercentage, beneType])

    useEffect(() => {
        if (beneEntityType === 'Individual') {
            setValue(`bene[${beneficiaryNumber}].tax_id_type`, 'Social Security Number');
        }
        //Excluding SSN in condition here to ensure resume works with SSN loaded in on resume
        else if ((beneEntityType === 'Trust' || beneEntityType === 'Nonprofit/Charity') && beneTaxIdType != 'Social Security Number') {
            setValue(`bene[${beneficiaryNumber}].tax_id_type`, 'Tax Identification Number');
        }
    },[beneEntityType])

    const copyApplicantAddress = () => {
        if (applicantData.legal_street && applicantData.legal_city && applicantData.legal_state && applicantData.legal_zip) {
            setValue(`bene[${beneficiaryNumber}].mailing_street`, applicantData.legal_street);
            setValue(`bene[${beneficiaryNumber}].mailing_city`, applicantData.legal_city);
            setValue(`bene[${beneficiaryNumber}].mailing_state`, applicantData.legal_state);
            setValue(`bene[${beneficiaryNumber}].mailing_zip`, applicantData.legal_zip);
        }
    }

    return (
        <div >
            <IonItemDivider>
                <p>
                <strong>
                    <IonText color='primary'>
                        Beneficiary {beneficiaryNumber+1}
                    </IonText>
                </strong>
                </p>
            <IonButton slot='end' onClick={e=> {
                removeBeneficiary();
            }} >Remove Beneficiary</IonButton>
            </IonItemDivider>
            <IonRow>
                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                    <IonLabel>
                        Type *
                    </IonLabel>
                    <IonItem className={showError(`entity_type`)}>
                        <Controller name={`bene[${beneficiaryNumber}].entity_type`} control={control} defaultValue={item.entity_type} render={({ name, onBlur, value }) => (
                            <IonSelect name={name} onIonChange={(event) => {
                                let entityType = event.detail.value;
                                if (entityType === 'Individual') {
                                    setValue(`bene[${beneficiaryNumber}].tax_id_type`, 'Social Security Number');
                                }
                                else if (entityType !== 'Individual') {
                                    setValue(`bene[${beneficiaryNumber}].tax_id_type`, 'Tax Identification Number');
                                }
                                setValue(name, entityType);
                            }} onIonBlur={onBlur} value={value} interface='action-sheet' interfaceOptions={{animated:false, mode: 'ios'}}>
                                <IonSelectOption value='Individual'>Individual</IonSelectOption>
                                <IonSelectOption value='Trust'>Trust</IonSelectOption>
                                <IonSelectOption value='Nonprofit/Charity'>Nonprofit/Charity</IonSelectOption>
                            </IonSelect>
                        )}
                        rules={{required:true}}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>{(beneEntityType === 'Individual') ? 'First Name *'
                            : (beneEntityType === 'Trust') ? 'Name of Trust *'
                                : 'Name *'}</IonLabel>
                                
                        <IonItem className={showError(`first_name`)}>
                        <Controller name={`bene[${beneficiaryNumber}].first_name`} control={control} defaultValue={item.first_name} render={({ onChange, onBlur, value, name }) => (
                            <IonInput name={name} value={value} onIonInput={onChange} onIonBlur={onBlur} maxlength={(beneEntityType === 'Individual') ? 50 : 100}
                                placeholder={
                                    (beneEntityType === 'Individual') ? 'First Name'
                                        : (beneEntityType === 'Trust') ? 'Name of Trust'
                                            : 'Name'
                                }
                            />)} rules={{
                                required: true,
                                validate: val => (beneEntityType === 'Individual') ? /^.{1,50}$/.test(val) : /^.{1,100}$/.test(val)
                            }}/>
                        </IonItem>
                        {errorsExistForFieldOnForm(errors?.bene, beneficiaryNumber, 'first_name') && <IonText color='danger'>{
                        (beneEntityType === 'Individual') ? 'Please enter first name (50 chars max)' : (beneEntityType === 'Trust') ? 'Please enter name of trust (100 chars max)' : 'Please enter name (100 chars max)' 
                    }</IonText>}
                    </IonCol>
                    {beneEntityType === 'Individual' && (      
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>Last Name *</IonLabel>
                            <IonItem className={showError(`last_name`)}>
                                <Controller name={`bene[${beneficiaryNumber}].last_name`} control={control} defaultValue={item.last_name} render={({ onChange, onBlur, value, name }) => (
                                    <IonInput name={name} value={value} onIonInput={onChange} onIonBlur={onBlur} placeholder='Last Name' maxlength={50}
                                />)} rules={{
                                    required: true
                                }}/>
                            </IonItem>
                            {errorsExistForFieldOnForm(errors?.bene, beneficiaryNumber, 'last_name') && <IonText color='danger'>Please enter last name (50 chars max)</IonText>}
                        </IonCol>
                    )}
                </IonRow>
            <IonRow>
                {(beneEntityType === 'Trust' || beneEntityType === 'Nonprofit/Charity') && (
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Tax Identifier/SSN *</IonLabel>
                        <IonItem className={showError(`tax_id_type`)}>
                        <Controller name={`bene[${beneficiaryNumber}].tax_id_type`} control={control} defaultValue={item.tax_id_type} render={({ onChange, onBlur, value, name }) => (
                            <IonSelect name={name}  onIonChange={(event)=>{onChange(event)}} onIonBlur={onBlur} value={value} interface='action-sheet' interfaceOptions={{animated:false, mode: 'ios'}}>
                                <IonSelectOption value='Tax Identification Number'>Tax Identification Number</IonSelectOption>
                                <IonSelectOption value='Social Security Number'>Social Security Number</IonSelectOption>
                            </IonSelect>
                        )}
                        rules={{required:true}}
                        />
                    </IonItem>
                    </IonCol>
                )}
                {beneEntityType === 'Individual' && (
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Date of Birth *</IonLabel>
                        <IonItem className={showError(`dob`)}>
                        <Controller name={`bene[${beneficiaryNumber}].dob`} control={control} defaultValue={item.dob} render={({ name, value }) => (
                            <KeyboardDatePicker className="date-width" disableFuture={true} margin='normal' value={convertStringDateToDate(value)} placeholder="mm/dd/yyyy" format="MM/dd/yyyy" KeyboardButtonProps={{'aria-label': 'change-date'}} 
                            onChange={(date)=>{
                                if(date){
                                    setValue(name, formatDateToString(date))
                                }
                            }}/>
                        )}
                        rules={{
                            required:true,
                            validate: (val:any) => {
                                if (typeof val === 'string') {
                                    val = new Date(val);
                                }
                                const minDate = new Date('01/01/1900');
                                const maxDate = new Date(today);
                                return validateDate(val, minDate, maxDate);}
                        }}
                        />
                        </IonItem>
                    </IonCol>
                )}
                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                    {beneTaxIdType !== 'Tax Identification Number' && (      
                        <IonLabel>Social Security Number</IonLabel>
                    )}
                    {beneTaxIdType === 'Tax Identification Number' && (
                        <IonLabel>Tax Identification Number</IonLabel>
                    )}
                    <IonItem className={showError(`ssn`)}>
                        {(beneTaxIdType !== 'Tax Identification Number') && (  
                            <Controller name={`bene[${beneficiaryNumber}].ssn`} control={control} defaultValue={item.ssn} render={({ name, value }) => (
                                <InputMask name={name}  value={value} onChange={(selected)=>{
                                    let ssn = selected.target.value
                                    setValue(name ,ssn?.replace('-','')?.replace('-',''))
                                }} className='input-mask' mask='xxx-xx-9999' placeholder='SSN' formatChars={{'9':'[0-9]', 'x':'(\\*|\\d)'}}/>
                            )}/>
                        )}
                        {beneTaxIdType === 'Tax Identification Number' && (      
                            <Controller name={`bene[${beneficiaryNumber}].ssn`} control={control} defaultValue={item.ssn} render={({ name, value }) => (
                                <InputMask value={value} onChange={(selected)=>{
                                    let ssn = selected.target.value
                                    setValue(name ,ssn?.replace('-','')?.replace('-',''))
                                }} className='input-mask' mask='xx-xxx9999' placeholder='TIN' formatChars={{'9':'[0-9]', 'x':'(\\*|\\d)'}}/>
                            )}/>
                        )}
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                    <IonLabel> Primary or Contingent *</IonLabel>
                    <IonItem className={showError(`type`)}>
                        <Controller name={`bene[${beneficiaryNumber}].type`} control={control} defaultValue={item.type} render={({ onChange, value, name}) => (
                            <IonSelect name={name}  onIonChange={(event)=>{onChange(event)}} value={value} interface='action-sheet' interfaceOptions={{animated:false, mode: 'ios'}}>
                                <IonSelectOption value='Primary'>Primary</IonSelectOption>
                                <IonSelectOption value='Contingent'>Contingent</IonSelectOption>
                            </IonSelect>
                        )}
                        rules={{required:true}}
                        />
                    </IonItem>
                </IonCol>
                {beneEntityType === 'Individual' && (
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>
                            Relationship *
                        </IonLabel>
                        <IonItem className={showError(`relationship`)}>
                            <Controller name={`bene[${beneficiaryNumber}].relationship`} control={control} defaultValue={item.relationship} render={({ onChange, onBlur, value, name }) => (
                                <IonSelect name={name}  onIonChange={onChange} onIonBlur={onBlur} value={value} interface='action-sheet' interfaceOptions={{animated:false, mode: 'ios'}}>
                                    <IonSelectOption value='Spouse'>Spouse</IonSelectOption>
                                    <IonSelectOption value='Child'>Child</IonSelectOption>
                                    <IonSelectOption value='Sibling'>Sibling</IonSelectOption>
                                    <IonSelectOption value='Parent'>Parent</IonSelectOption>
                                    <IonSelectOption value='Other Family'>Other Family</IonSelectOption>
                                    <IonSelectOption value='Other'>Other</IonSelectOption>
                                </IonSelect>
                            )}
                            rules={{required:true}}
                            />
                        </IonItem>
                    </IonCol>
                )}
            </IonRow>
            <IonRow>
                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                    <IonLabel>Share Percentage *</IonLabel>
                    <IonItem className={showError(`share_percentage`)}>
                        <Controller name={`bene[${beneficiaryNumber}].share_percentage`} control={control} defaultValue={item.share_percentage} render={({ onChange, onBlur, value, name  }) => (
                            <IonInput name={name} onIonChange={(event)=>{onChange(event)}} onIonBlur={onBlur} value={value} type='number'/>                
                        )}
                        rules={{required:true, min:1, max:100}}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className='well' size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                    Calculated Share Percentage 
                    {
                        //MOVING THIS LOGIC TO PARENT
                    
                        (!!beneType && !!totalSharePercentage[beneType]) ? (
                            <p>
                                    <strong>
                                    {beneType} Share Percentage : {totalSharePercentage[beneType]} %
                                    </strong>
                            </p>
                        ) : ''
                    }
                </IonCol>
            </IonRow>
            {(beneRelationship === 'Spouse' || beneRelationship === 'Child') && (
                <IonRow>
                    <IonCol>
                        <IonButton onClick={e=> {copyApplicantAddress();}} >Use My Address</IonButton>
                    </IonCol>
                </IonRow>
            )}
            {(beneEntityType !== 'Trust') && <>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Beneficiary Street</IonLabel>
                        <IonItem className={showError(`mailing_street`)}>
                            <Controller name={`bene[${beneficiaryNumber}].mailing_street`} control={control} defaultValue={item.mailing_street} render={({ onChange, onBlur, value, name  }) => (
                                <IonInput name={name} onIonInput={onChange} onIonBlur={onBlur} value={value} placeholder='Street' />
                            )}
                                rules={{ maxLength: 255 }}
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Beneficiary City</IonLabel>
                        <IonItem className={showError(`mailing_city`)}>
                            <Controller name={`bene[${beneficiaryNumber}].mailing_city`} control={control} defaultValue={item.mailing_city} render={({ onChange, onBlur, value, name  }) => (
                                <IonInput name={name} onIonInput={onChange} onIonBlur={onBlur} value={value} placeholder='City' />
                            )}
                                rules={{ maxLength: 255 }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Beneficiary State</IonLabel>
                        <IonItem className={showError(`mailing_state`)}>
                            {isMobile(isPlatform) ?
                                <Controller name={`bene[${beneficiaryNumber}].mailing_state`} control={control} defaultValue={item.mailing_state} render={({ onChange, onBlur, value, name  }) => (
                                    <IonSelect name={name}  onIonChange={onChange} onIonBlur={onBlur} value={value} interface='action-sheet' interfaceOptions={{ cssClass: 'long-select', animated: false, mode: 'ios' }} >
                                        {states.map((state, index) => (<IonSelectOption key={index} value={state}>{state}</IonSelectOption>))}
                                    </IonSelect>)} />
                                : <Controller name={`bene[${beneficiaryNumber}].mailing_state`} control={control} defaultValue={item.mailing_state} render={({ name, value }) => (
                                    <Autocomplete id={name} componentName={name}
                                        onChange={(event, value) => {
                                        setValue(name, value)
                                    }} value={value} freeSolo={true} autoSelect={true} options={states} getOptionLabel={option => option} renderOption={option => (
                                        <span> {option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = { ...params.InputProps, disableUnderline: true, placeholder: 'State' };
                                        let innerInputProps = { ...params.inputProps, maxLength: "2" }
                                        let newParams = { ...params, InputProps: newInputProps, inputProps: innerInputProps };
                                        return (
                                            <TextField type='text' {...newParams} />
                                        )
                                    }} />)}
                                    rules={{ validate: (val: any) => (val === undefined || val === null || val === '' || (/[A-Za-z]{2}/.test(val) && states.findIndex((state) => state === val?.toUpperCase()) !== -1)) }}
                                />
                            }
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Beneficiary Zip</IonLabel>
                        <IonItem className={showError(`mailing_zip`)}>
                            <Controller name={`bene[${beneficiaryNumber}].mailing_zip`} control={control} defaultValue={item.mailing_zip} render={({ onChange, onBlur, value, name  }) => (
                                <InputMask name={name} onChange={onChange} onBlur={onBlur} value={value} maskChar='' mask='99999' placeholder='Zip' className='input-mask' />
                            )}
                                rules={{ validate: (val: any) => (val === undefined || val === null || val === '' || val?.match(/^[0-9]{5}(?:-[0-9]{4})?/) !== null) }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
            </>}
            <IonRow>
                {beneEntityType === 'Trust' && (      
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Trustee Name *</IonLabel>
                        <IonItem className={showError(`trustee_name`)}>
                            <Controller name={`bene[${beneficiaryNumber}].trustee_name`} control={control} defaultValue={item.trustee_name} render={({ onChange, onBlur, value, name  }) => (
                                <IonInput name={name}  value={value} onIonInput={onChange} onIonBlur={onBlur} placeholder='Trustee Name' maxlength={100}
                            />)} rules={{
                                required: true
                            }}/>
                        </IonItem>
                        {errorsExistForFieldOnForm(errors?.bene, beneficiaryNumber, 'trustee_name') && <IonText color='danger'>Please enter Trustee name (100 chars max)</IonText>}
                    </IonCol>
                )}
                <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                    <IonLabel>
                        {(beneEntityType !== 'Trust') ? 'Phone' : 'Trustee Phone Number *'}
                    </IonLabel>
                    <IonItem className={showError(`phone`)}>
                        <Controller name={`bene[${beneficiaryNumber}].phone`} control={control} defaultValue={item.phone} render={({ onChange, onBlur, value, name  }) => (
                            <InputMask name={name}  onChange={onChange} onBlur={onBlur} value={value} className='input-mask' type='tel' mask="(999)999-9999" placeholder={(beneEntityType !== 'Trust') ? 'Phone' : 'Trustee\'s Phone Number'}/>
                        )}
                        rules={{
                            required: beneEntityType === 'Trust' ? true : false,
                            pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/,
                            validate: (val) => (val?.charAt(1) !== '0' && val?.charAt(1) !== '1')
                        }}/>
                    </IonItem>
                    {errorsExistForFieldOnForm(errors?.bene, beneficiaryNumber, 'phone') && <IonText color='danger'>Please enter a valid phone number</IonText>}
                </IonCol>
                {(beneEntityType !== 'Trust') && <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                    <IonLabel>
                        Email
                    </IonLabel>
                    <IonItem className={showError(`email`)}>
                        <Controller name={`bene[${beneficiaryNumber}].email`} control={control} defaultValue={item.email} render={({ onChange, onBlur, value, name  }) => (
                            <IonInput name={name} onIonInput={onChange} onBlur={onBlur} value={value} type='email' placeholder='Email' />
                        )}
                            rules={{
                                validate: (val: any) => (val === undefined || val === null || val === '' || (new RegExp(EMAIL_REGEX)).test(val))
                            }}
                        />
                    </IonItem>
                </IonCol>}
            </IonRow>
            <br />
        </div>
    )
}

export default BeneficiaryForm